import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { gstMasterFormFields as initialFields } from "../data"; // Ensure correct path
import { useLocation } from "react-router-dom";
import { BASE_URL } from "../../Common/apiURL";
import axios from "axios";
import {
  getCurrentPageData,
  user,
  selectedBranch,
  SerialKey,
  config,
} from "../../../utils/capitalize";

const GSTMasterForm = ({ onSubmit, formData, setActiveTab }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue, // To programmatically set form values
    formState: { errors },
  } = useForm();
  const [formFields, setFormFields] = useState(initialFields);
  const [formValues, setFormValues] = useState(formData);
  const [executionCount, setExecutionCount] = useState(0);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [sgstOptions, setSGSTOptions] = useState([]);
  const [igstOptions, setIGSTOptions] = useState([]);
  const [cessOptions, setCESSOptions] = useState([]);
  const [isEditing, setIsEditing] = useState(false); // Added isEditing state

  const current = useLocation();
  const currentPage = current.pathname.split("/")[1];
  const convertToCustomTitleCase = (str) => {
    const formattedString = str
      .replace(/-/g, " ") // Replace hyphens with spaces
      .toLowerCase(); // Convert the entire string to lowercase first

    // Capitalize the first three letters and "Master" properly
    return formattedString
      .split(" ")
      .map((word, index) => {
        if (index === 0) {
          return word.slice(0, 3).toUpperCase() + word.slice(3); // First three letters in uppercase
        }
        return word.charAt(0).toUpperCase() + word.slice(1); // Capitalize first letter of subsequent words
      })
      .join(" ");
  };
  const pageTitle = convertToCustomTitleCase(currentPage);
  const targetPage = getCurrentPageData(pageTitle);
  const formKey = targetPage?.FormKey;
  const branchCode = selectedBranch?.BranchCode;
  const UserID = user[0]?.UserID;

  useEffect(() => {
    setFormValues(formData);
    // Set initial values for editing (if formData exists)
    if (formData && Object.keys(formData).length > 0) {
      setIsEditing(true); // Set form as editing mode
      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]); // Pre-fill form fields
      });
    } else {
      setIsEditing(false); // If no formData, it's in add mode
    }
  }, [formData, setValue]);

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const KeyCode = String(executionCount + 1); // Increment KeyCode with each execution
        const response = await axios.post(
          `${BASE_URL}/dashboard/master-data-list`,
          {
            FormKey: String(formKey),
            BranchCode: branchCode,
            UserID,
            SerialKey,
            KeyCode,
          },
          config
        );

        const data = response.data.data;

        // Update dropdown options based on the KeyCode
        if (KeyCode === "1") {
          const categories = data.map((item) => item.Category);
          setCategoryOptions(categories);
        } else if (KeyCode === "2") {
          const categories = data.map((item) => item.TaxName);
          setGroupOptions(categories);
        } else if (KeyCode === "3") {
          const categories = data.map((item) => item.TaxName);
          setSGSTOptions(categories);
        } else if (KeyCode === "4") {
          const categories = data.map((item) => item.TaxName);
          setIGSTOptions(categories);
        } else if (KeyCode === "5") {
          const categories = data.map((item) => item.TaxName);
          setCESSOptions(categories);
        } else {
          console.log(`Additional KeyCode: ${KeyCode}`);
        }
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      }
    };

    // Trigger the fetch function up to 5 times
    if (executionCount < 5) {
      fetchDropdownOptions();
      setExecutionCount((prev) => prev + 1);
    }
  }, [executionCount, formKey, branchCode, UserID, SerialKey]);

  useEffect(() => {
    const updatedFields = formFields.map((field) => {
      if (field.type === "dropdown") {
        if (field.name === "under") {
          return { ...field, options: categoryOptions };
        }
        if (field.name === "cgst") {
          return { ...field, options: groupOptions };
        }
        if (field.name === "sgst") {
          return { ...field, options: sgstOptions };
        }
        if (field.name === "igst") {
          return { ...field, options: igstOptions };
        }
        if (field.name === "cess") {
          return { ...field, options: cessOptions };
        }
      }
      return field;
    });
    setFormFields(updatedFields);
  }, [groupOptions, categoryOptions, sgstOptions, igstOptions, cessOptions]);

  // Render input fields dynamically
  const renderField = (field) => {
    if (!field) return null;

    const { dtName, name, label, type, placeholder, options, validation } =
      field;
    const adjustedValidation = isEditing ? {} : validation;

    if (type === "text" || type === "email" || type === "number") {
      return (
        <div key={name} className='flex flex-col'>
          <label className='block font-medium text-md'>{label}</label>
          <input
            type={type}
            placeholder={placeholder}
            {...register(name, adjustedValidation)}
            className='w-full p-1 text-sm border rounded'
            defaultValue={formValues[dtName]}
          />
          {errors[name] && (
            <span className='text-red-500 text-sm'>{errors[name].message}</span>
          )}
        </div>
      );
    } else if (type === "dropdown") {
      const selectedValue =
        typeof formValues[dtName] === "number"
          ? options[formValues[dtName]] // Map index to the corresponding option
          : formValues[dtName]; // Use the string value directly
      return (
        <div key={name} className='flex flex-col'>
          <label className='block font-medium text-md'>{label}</label>
          <select
            {...register(name, adjustedValidation)}
            className='w-full p-1 border rounded text-sm'
            value={selectedValue || ""} // Use the selected value
            onChange={(e) =>
              setFormValues((prev) => ({
                ...prev,
                [dtName]: e.target.value,
              }))
            }
          >
            <option value=''>Select {label}</option>
            {options?.map((option, idx) => (
              <option key={idx} value={option}>
                {option}
              </option>
            ))}
          </select>
          {errors[name] && (
            <span className='text-red-500 text-xs'>{errors[name].message}</span>
          )}
        </div>
      );
    }
    return null;
  };

  // Handle form submission
  const handleFormSubmit = async (data) => {
    console.log(data);
    console.log(formData);
    const defaultValues = {
      SerialKey: SerialKey || "",
      BranchCode: branchCode || "",
      UserID: UserID || "",
      GSTCode: data.GSTCode || formData.GSTCode, // Optional parameter
      GSTType:
        categoryOptions?.indexOf(data.under) !== -1
          ? categoryOptions?.indexOf(data.under)
          : -1, // Ensure GSTType is valid
      GSTName: data.gst || "",
      CGST: data.cgst || formData.CGST,
      SGST: data.sgst || formData.SGST,
      IGST: data.igst || formData.IGST,
      CESS: data.cess || formData.CESS,
      TaxRate: parseFloat(data.rateOfGST) || 0.0,
      RefCnt: !isEditing ? "1" : "2",
    };

    try {
      if (onSubmit) {
        const response = await axios.post(
          `${BASE_URL}/dashboard/gst-master-form-data`,
          defaultValues,
          config
        );
        alert(response.data.message);
        setActiveTab("Lookup");
      }
      reset();
    } catch (error) {
      console.error("Error while submitting the form:", error);
      alert("An error occurred: ", error);
    }
  };

  return (
    <div className='bg-gray-200 p-3 rounded-xl shadow-lg'>
      <h2 className='text-lg text-center font-semibold mb-4 uppercase'>
        {targetPage?.FormName}
      </h2>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4'
      >
        {formFields.map((field) => renderField(field))}
        <div className='col-span-full'>
          <button
            type='submit'
            className='px-4 py-2 text-sm bg-black text-white rounded w-full'
          >
            {isEditing ? "Update" : "Submit"}{" "}
            {/* Button text changes based on formData */}
          </button>
        </div>
      </form>
    </div>
  );
};

export default GSTMasterForm;
