import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DynamicTable from "../../Common/NewDataTable";
import Loader from "../../Common/Loader"; // Ensure you have a Loader component
import axios from "axios";
import { BASE_URL } from "../../Common/apiURL";
import { useNavigate } from "react-router-dom";
import Table from "../../Common/SearchFilterTable";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { dateFormatter } from "../../../utils/capitalize";

const groupDataByDocID = (data) => {
  let groupedData = [];
  let currentDocID = null;
  let serialNumber = 0; // Initialize a serial number

  data.forEach((item) => {
    if (item.DocID !== currentDocID) {
      // Reset the serial number for each new DocID
      serialNumber = 1; // Start numbering from 1 for new group
      groupedData.push({
        isGroupHeader: true,
        DocID: item.DocID, // Optionally store the DocID for grouping purposes
      });
      currentDocID = item.DocID;
    } else {
      // Increment the serial number for same DocID
      serialNumber++;
    }

    // Add the actual data row with the serial number
    groupedData.push({ ...item, serialNumber });
  });

  return groupedData;
};

const DayBook = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showData, setShowData] = useState(false); // To control table display
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const [showLoader, setShowLoader] = useState(false); // To control loader display
  const [showPopup, setShowPopup] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState({
    "Vou. No": true,
    Date: true,
    Particulars: true,
    Type: true,
    Depot: true,
    Username: true,
    "Amount (Dr)": true,
    "Amount (Cr)": true,
    Ledger: true,
    Remarks: true,
    "Transaction Date": true,
  });

  let visibleColumns = [];
  useEffect(() => {
    visibleColumns = Object.keys(selectedColumns).filter(
      (key) => selectedColumns[key]
    );
    console.log("Visible Columns: ", visibleColumns);
  }, [selectedColumns]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSelectedColumns((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handlePopupOpen = (event) => {
    setShowPopup(true); // Show the popup
    setSelectedColumns({
      "Vou. No": true,
      Date: true,
      Particulars: true,
      Type: true,
      Depot: true,
      Username: true,
      "Amount (Dr)": true,
      "Amount (Cr)": true,
      Ledger: true,
      Remarks: true,
      "Transaction Date": true,
    });
  };

  const handleClosePopup = () => {
    setShowPopup(false); // Close the popup
  };

  const handleConfirmDownload = () => {
    handleDownloadAsPDF(selectedColumns);
    setShowPopup(false); // Close the popup
  };

  const handleDownloadAsPDF = async (selectedColumns) => {
    // const doc = new jsPDF();
    const doc = new jsPDF({
      orientation: "landscape", // 'portrait' or 'landscape'
      unit: "mm",
      format: "a4",
    });

    const logoUrl =
      "https://img.freepik.com/free-photo/colorful-design-with-spiral-design_188544-9588.jpg"; // Your logo URL

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();
    const formattedTime = currentDate.toLocaleTimeString();

    // Convert Image URL to Base64
    const convertToBase64 = async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    };

    try {
      // Convert logo to Base64
      const logoBase64 = await convertToBase64(logoUrl);
      const logoX = 15;
      const logoY = 10;
      const logoWidth = 25;
      const logoHeight = 25;
      doc.addImage(logoBase64, "JPEG", logoX, logoY, logoWidth, logoHeight);
    } catch (error) {
      console.error("Error loading logo:", error);
      doc.text("Logo Error", 10, 30);
    }
    const center = doc.internal.pageSize.width / 2;
    const rightPosition = doc.internal.pageSize.width - 15; // Adjust as needed

    // Add Company Details and Report Name
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);
    doc.text("SRS Softwares", center, 15, { align: "center" });
    doc.setFont("helvetica", "semibold");
    doc.setFontSize(10);
    doc.text("Address Line 1", center, 20, { align: "center" });
    doc.text("Address Line 2", center, 25, { align: "center" });
    doc.text("GSTIN No: XXXXXXXXXXX", center, 29, { align: "center" });
    doc.setFontSize(12);
    doc.text("DayBook", center, 34, { align: "center" });
    // Add Date on the Top Right
    doc.setFontSize(10);
    doc.text(`Date: ${dateFormatter(currentDate)}`, rightPosition, 20, {
      align: "right",
    });
    doc.setFont("helvetica", "bold");
    doc.setFontSize(10);
    doc.text(
      `${dateFormatter(startDate)} to ${dateFormatter(endDate)}`,
      center,
      39,
      {
        align: "center",
      }
    );
    // Add Date on the Top Right
    doc.setFontSize(10);
    // doc.text(`Date: ${dateFormatter(startDate)}`, 200, 20, { align: "right" });

    // Define table columns based on selectedColumns
    const tableColumns = Object.keys(selectedColumns).filter(
      (column) => selectedColumns[column]
    );

    // Filter rows based on selected columns
    const tableRows = rows.map((row, index) => {
      return tableColumns.map((column) => {
        if (column === "Vou. No") return row.VouNo;
        if (column === "Date") return dateFormatter(row.VDate);
        if (column === "Particulars") return row.LName;
        if (column === "Type") return row.VType;
        if (column === "Depot") return row.BranchName;
        if (column === "Username") return row.Username;
        if (column === "Amount (Dr)") return row.DrAmt?.toFixed(2);
        if (column === "Amount (Cr)") return row.CrAmt?.toFixed(2);
        if (column === "Ledger") return row.DLName;
        if (column === "Remarks") return row.Remark;
        if (column === "Transaction Date") return dateFormatter(row.TranDate);
        return "";
      });
    });

    // Add table to PDF
    doc.autoTable({
      head: [tableColumns],
      body: tableRows,
      startY: 45,
      margin: { left: 10, right: 10 },
      theme: "plain",
      styles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.5,
      },
      headStyles: {
        fillColor: [211, 211, 211],
        textColor: [0, 0, 0],
        fontStyle: "bold",
        lineColor: [0, 0, 0],
        lineWidth: 0.5,
        halign: "center",
      },
      bodyStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
      },
      columnStyles: {
        0: { cellWidth: 15, halign: "center" },
        4: { halign: "right" },
        5: { halign: "right" },
      },
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.5,
    });

    // Add Footer with Time and Date of Printing
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;
    doc.text(
      `Printed On: ${formattedDate}, ${formattedTime}`,
      pageWidth - 10,
      pageHeight - 10,
      { align: "right" }
    );

    // Save the PDF
    doc.save("Day-Book.pdf");
  };

  // Basic Information For API's
  let targetRoute = "Day Book";
  let menuValue = JSON.parse(localStorage.getItem("Menu"));
  let Depot = JSON.parse(localStorage.getItem("branches"));
  let token = localStorage.getItem("token");
  let selectedLocation = localStorage.getItem("selectedLocation");
  const navigate = useNavigate();

  const targetPage = menuValue.find((item) => item.FormName === targetRoute);
  var UserName;
  var DPCode;
  var BCode;
  Depot.forEach((depot) => {
    if (depot.BranchName === selectedLocation) {
      BCode = depot.BranchCode;
    }
  });
  const itemFromLocalStorage = JSON.parse(localStorage.getItem("userInfo"));
  UserName = itemFromLocalStorage[0]?.UserID;
  console.log(itemFromLocalStorage);
  DPCode = itemFromLocalStorage[0]?.DpCode;
  const config = {
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };

  const [rows, setRows] = useState([]);

  // Handle the show button click
  const handleShowData = async (e) => {
    e.preventDefault();
    const formattedFromDate = startDate
      .toISOString()
      .slice(0, 10)
      .replace(/-/g, "");
    const formattedToDate = endDate
      .toISOString()
      .slice(0, 10)
      .replace(/-/g, "");

    const requestBody = {
      Depot: DPCode,
      BranchCode: BCode,
      Username: UserName,
      FormKey: targetPage.FormKey,
      PartyName: "", // Assuming party name is not needed as we are filtering by agent
      AgentName: "",
      ItemGroup: "",
      ItemName: "",
      Date1: formattedFromDate,
      Date2: formattedToDate,
      RptCode: 0,
    };
    // console.log(requestBody);

    try {
      const response = await axios.post(
        `${BASE_URL}/dashboard/web-reports`,
        requestBody,
        config
      );
      console.log(response.data.data);
      const groupedData = groupDataByDocID(response.data.data); // Group data by DocID
      setRows(groupedData);
    } catch (error) {
      alert("Something went wrong!!!", error);
    } finally {
      setShowLoader(false);
    }
  };

  const columns = [
    {
      Header: "Vou. No",
      accessor: "VouNo",
      Cell: ({ value }) => (
        <div style={{ textAlign: "center", width: "70px" }}>{value}</div>
      ),
      isInitiallyVisible: true,
    },
    {
      Header: "Date",
      accessor: "VDate",
      Cell: ({ value }) => (
        <div>{value ? new Date(value).toLocaleDateString("en-GB") : ""}</div>
      ),
      isInitiallyVisible: true,
    },

    {
      Header: "Particulars",
      accessor: "LName",
      Cell: ({ value }) => (
        <div style={{ textAlign: "left", width: "200px" }}>{value}</div>
      ),
      headerStyle: { textAlign: "center" }, // Center-align header
      isInitiallyVisible: true,
    },
    {
      Header: "Type",
      accessor: "VType",
      Cell: ({ value }) => (
        <div style={{ textAlign: "center", width: "50px" }}>{value}</div>
      ),
      isInitiallyVisible: true,
    },
    {
      Header: "Depot",
      accessor: "BranchName",
      isInitiallyVisible: false,
      Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
    },
    {
      Header: "Username",
      accessor: "UserName",
      Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
      isInitiallyVisible: false,
    },
    {
      Header: "Amount (Dr)",
      accessor: "DrAmt",
      Cell: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          {typeof value === "number" ? value.toFixed(2) : value}
        </div>
      ),
      isInitiallyVisible: true,
    },
    {
      Header: "Amount (Cr)",
      accessor: "CrAmt",
      Cell: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          {typeof value === "number" ? value.toFixed(2) : value}
        </div>
      ),
      isInitiallyVisible: true,
    },
    {
      Header: "Ledger",
      accessor: "DLName",
      Cell: ({ value }) => (
        <div style={{ textAlign: "left", width: "200px" }}>{value}</div>
      ),
      isInitiallyVisible: false,
    },

    {
      Header: "Remarks",
      accessor: "Remark", // Keep an accessor if you want sorting based on Remark
      Cell: ({ row }) => {
        const { Remark, Remark1, Remark2 } = row.original;
        // Combine the remarks, conditionally showing only those with values
        return (
          <div style={{ textAlign: "left", width: "200px" }}>
            {[Remark, Remark1, Remark2].filter(Boolean).join(" ")}
          </div>
        );
      },
      isInitiallyVisible: true,
    },
    {
      Header: "Transaction Date",
      accessor: "TranDate",
      Cell: ({ value }) => {
        if (!value) return ""; // Handle null or undefined

        // Create a new Date object from the UTC date string
        const utcDate = new Date(value);

        // Convert UTC to IST (UTC +5:30)
        const istOffset = 5 * 60 + 30; // IST offset in minutes
        const istDate = new Date(utcDate.getTime() + istOffset * 60 * 1000); // Add offset in milliseconds

        // Increase the hour by 1
        istDate.setHours(istDate.getHours() + 1);

        // Format options for displaying date and time
        const options = {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false, // Use 24-hour format
        };

        return (
          <div style={{ width: "150px" }}>
            {istDate.toLocaleString("en-GB", options)}
          </div>
        );
      },
      isInitiallyVisible: true,
    },
  ];

  return (
    <div className='p-5'>
      {isLoading && <Loader />} {/* Show loader when loading */}
      <h2 className='text-sm font-semibold mb-4'>Day Book</h2>
      <form className='grid grid-cols-3 gap-2'>
        {/* Datepicker */}
        <div className='flex flex-col'>
          <label className='block font-medium text-sm'>From</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            className='w-full p-1 border rounded text-xs'
            maxDate={new Date()}
            dateFormat='dd/MM/yyyy'
          />
        </div>

        <div className='flex flex-col'>
          <label className='block font-medium text-sm'>To</label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            className='w-full p-1 border rounded text-xs'
            dateFormat='dd/MM/yyyy'
            minDate={startDate}
            maxDate={new Date()}
          />
        </div>

        {/* Show Button */}
        <div className='w-full'>
          <button
            type='button'
            onClick={handleShowData}
            className='px-4 py-1 bg-green-600 text-white rounded mt-4'
          >
            Show
          </button>
        </div>
      </form>
      {/* Conditionally render the Stock Summary Table */}
      <div className='mt-5'>
        <Table columns={columns} data={rows} />
      </div>
      {rows.length > 0 && (
        <div className='mt-4'>
          <button
            onClick={handlePopupOpen}
            className='px-4 py-1 text-sm bg-green-600 text-white rounded w-full'
          >
            Download PDF
          </button>
        </div>
      )}
      {showPopup && (
        <div className='fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50'>
          <div className='bg-white p-3 rounded shadow-lg w-80'>
            <h2 className='text-xl font-semibold mb-2 text-center'>
              Download PDF
            </h2>
            <div className='flex flex-col mb-4'>
              <label className='block text-sm mb-3 font-semibold'>
                Select Columns
              </label>
              <div className='flex flex-col'>
                {columns.map((column) => (
                  <label
                    key={column.Header}
                    className='flex items-center text-sm'
                  >
                    <input
                      type='checkbox'
                      name={column.Header}
                      checked={selectedColumns[column.Header]}
                      onChange={handleCheckboxChange}
                      className='mr-2'
                    />
                    {column.Header}
                  </label>
                ))}
              </div>
            </div>
            <div className='flex justify-end mt-4 space-x-4'>
              <button
                onClick={handleClosePopup}
                className='px-4 py-2 bg-red-500 text-white rounded'
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDownload}
                className='px-4 py-2 bg-blue-500 text-white rounded'
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DayBook;
