export const agentMasterFormFields = [
  {
    name: "group",
    dtName: "MGName",
    label: "Group",
    type: "dropdown",
    options: [],
    validation: { required: "Group is required" },
  },
  {
    name: "abbr",
    dtName: "AgentAbbr",
    label: "Abbr",
    type: "text",
    placeholder: "Enter abbreviation",
    validation: { required: "Abbreviation is required" },
  },
  {
    name: "agentName",
    dtName: "AName",
    label: "Agent Name",
    type: "text",
    placeholder: "Enter agent name",
    validation: { required: "Agent Name is required" },
  },
  {
    name: "regType",
    dtName: "RegType",
    label: "Reg Type",
    type: "text",
    placeholder: "Enter registration type",
    validation: { required: "Reg Type is required" },
  },
  {
    name: "address1",
    dtName: "Address1",
    label: "Address 1",
    type: "text",
    placeholder: "Enter first address",
    validation: { required: "Address 1 is required" },
  },
  {
    name: "address2",
    dtName: "Address2",
    label: "Address 2",
    type: "text",
    placeholder: "Enter second address",
  },
  {
    name: "city",
    dtName: "City",
    label: "City",
    type: "text",
    placeholder: "Enter city",
    validation: { required: "City is required" },
  },
  {
    name: "state",
    dtName: "State",
    label: "State",
    type: "dropdown",
    options: [],
    validation: { required: "State is required" },
  },
  {
    name: "gstin",
    dtName: "GSTIN",
    label: "GSTIN",
    type: "text",
    placeholder: "Enter GSTIN",
    validation: {
      required: "GSTIN is required",
    },
  },
  {
    name: "mobile",
    dtName: "MobileNo",
    label: "Mobile Number",
    type: "text",
    placeholder: "Enter mobile number",
    validation: {
      required: "Mobile Number is required",
      pattern: {
        value: /^[0-9]{10}$/,
        message: "Mobile Number must be 10 digits",
      },
    },
  },
  {
    name: "email",
    dtName: "EMailID",
    label: "Email",
    type: "email",
    placeholder: "Enter email address",
    validation: {
      required: "Email is required",
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        message: "Invalid email address",
      },
    },
  },
  {
    name: "whatsapp",
    dtName: "SMS_Whatsapp_No",
    label: "Whatsapp No",
    type: "text",
    placeholder: "Enter Whatsapp number",
    validation: {
      pattern: {
        value: /^[0-9]{10}$/,
        message: "Whatsapp number must be 10 digits",
      },
    },
  },
  {
    name: "commission",
    dtName: "RoComm",
    label: "Commission",
    type: "text",
    placeholder: "Enter commission rate",
    validation: {
      required: "Commission rate is required",
      pattern: {
        value: /^[0-9]+(\.[0-9]{1,2})?$/,
        message: "Invalid commission format",
      },
    },
  },
  {
    name: "pan",
    dtName: "PAN",
    label: "PAN",
    type: "text",
    placeholder: "Enter PAN number",
    validation: {
      pattern: {
        message: "Invalid PAN format",
      },
    },
  },
];

export const partyMasterFormFields = [
  {
    name: "group",
    dtName: "MGName",
    label: "Group",
    type: "dropdown",
    options: [], // Adjust as needed
    validation: { required: "Group is required" },
  },
  {
    name: "agent",
    dtName: "AName",
    label: "Agent",
    type: "dropdown",
    options: [], // Option for adding new agent
    // Add custom logic for handling 'Add new agent' navigation
  },
  {
    name: "transport",
    dtName: "TName",
    label: "Transport",
    type: "dropdown",
    options: [], // Option for adding new transport
    // Add custom logic for handling 'Add new transport' navigation
  },
  {
    name: "partyName",
    dtName: "PName",
    label: "Party Name",
    type: "text",
    placeholder: "Enter party name",
    validation: { required: "Party Name is required" },
  },
  {
    name: "address1",
    dtName: "Address1",
    label: "Address 1",
    type: "text",
    placeholder: "Enter address",
  },
  {
    name: "address2",
    dtName: "Address2",
    label: "Address 2",
    type: "text",
    placeholder: "Enter address",
  },
  {
    name: "address3",
    dtName: "Address3",
    label: "Address 3",
    type: "text",
    placeholder: "Enter address",
  },
  {
    name: "address4",
    dtName: "Address4",
    label: "Address 4",
    type: "text",
    placeholder: "Enter address",
  },
  {
    name: "city",
    dtName: "City",
    label: "City",
    type: "text",
    placeholder: "Enter city",
    validation: { required: "City is required" },
  },
  {
    name: "pincode",
    dtName: "Pincode",
    label: "PIN Code",
    type: "text",
    placeholder: "Enter PIN code",
    validation: { required: "PIN Code is required" },
  },
  {
    name: "district",
    dtName: "District",
    label: "District",
    type: "text",
    placeholder: "Enter district",
  },
  {
    name: "state",
    dtName: "State",
    label: "State",
    type: "dropdown",
    options: [], // Adjust as needed
    validation: { required: "State is required" },
  },
  {
    name: "country",
    dtName: "Country",
    label: "Country",
    type: "text",
    placeholder: "Enter country",
  },
  {
    name: "gstin",
    dtName: "GSTIN",
    label: "GSTIN",
    type: "text",
    placeholder: "Enter GSTIN",
    validation: { required: "GSTIN is required" },
  },
  {
    name: "bookingDestination",
    dtName: "Destination",
    label: "Booking Destination",
    type: "text",
    placeholder: "Enter booking destination",
  },
  {
    name: "contactPerson",
    dtName: "CPName",
    label: "Contact Person",
    type: "text",
    placeholder: "Enter contact person",
  },
  {
    name: "mobileNo",
    dtName: "MobileNo",
    label: "Mobile No",
    type: "text",
    placeholder: "Enter mobile number",
    validation: {
      required: "Mobile No is required",
      pattern: {
        value: /^[0-9]{10}$/,
        message: "Mobile Number must be 10 digits",
      },
    },
  },
  {
    name: "telephoneNo",
    dtName: "TelOffice",
    label: "Telephone No",
    type: "text",
    placeholder: "Enter telephone number",
  },
  {
    name: "email",
    dtName: "EMailID",
    label: "Email",
    type: "email",
    placeholder: "Enter email address",
    validation: {
      required: "Email is required",
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        message: "Invalid email address",
      },
    },
  },
  {
    name: "registrationType",
    dtName: "RegType",
    label: "Registration Type",
    type: "dropdown",
    options: [
      "UnRegistered",
      "Registered",
      "Consumer",
      "International",
      "Composition",
    ], // Adjust as needed
    placeholder: "Enter Registration Type",
    validation: { required: "Registration Type is required" },
  },
  {
    name: "pan",
    dtName: "PAN",
    label: "PAN",
    type: "text",
    placeholder: "Enter PAN number",
    validation: {
      pattern: {
        message: "Invalid PAN format",
      },
    },
  },
  {
    name: "whatsappNo",
    dtName: "SMS_Whatsapp_No",
    label: "Whatsapp No",
    type: "text",
    placeholder: "Enter Whatsapp number",
    validation: {
      pattern: {
        value: /^[0-9]{10}$/,
        message: "Whatsapp number must be 10 digits",
      },
    },
  },
  {
    name: "partyAbbreviation",
    dtName: "PAbbr",
    label: "Party Abbreviation",
    type: "text",
    placeholder: "Enter party abbreviation",
  },
  {
    name: "partyCategory",
    dtName: "PartyCategory",
    label: "Party Category",
    type: "text",
    placeholder: "Enter party category",
  },
  {
    name: "registered",
    dtName: "Registered",
    label: "Registered",
    type: "dropdown",
    options: ["Unregistered", "Registered"], // Adjust as needed
    placeholder: "Enter registration status",
  },
];

export const transportMasterFormFields = [
  {
    name: "group",
    dtName: "MGName",
    label: "Group",
    type: "dropdown",
    options: [], // Adjust as needed
    validation: { required: "Group is required" },
  },
  {
    name: "transportName",
    dtName: "TName",
    label: "Transport Name",
    type: "text",
    placeholder: "Enter transport Name",
    validation: { required: "Transport Name is required" },
    // Add logic for handling 'Add new transport' navigation if necessary
  },
  {
    name: "regType",
    dtName: "RegType",
    label: "Registration Type",
    type: "text",
    placeholder: "Enter registration type",
    validation: { required: "Registration Type is required" },
  },
  {
    name: "address1",
    label: "Address 1",
    dtName: "Address1",
    type: "text",
    placeholder: "Enter address",
    validation: { required: "Address is required" },
  },
  {
    name: "address2",
    label: "Address 2",
    dtName: "Address2",
    type: "text",
    placeholder: "Enter address",
    validation: { required: "Address is required" },
  },
  {
    name: "city",
    label: "City",
    dtName: "City",
    type: "text",
    placeholder: "Enter city",
    validation: { required: "City is required" },
  },
  {
    name: "state",
    label: "State",
    dtName: "State",
    type: "dropdown",
    options: [], // Adjust as needed
    validation: { required: "State is required" },
  },
  {
    name: "contactPerson",
    label: "Contact Person",
    type: "text",
    placeholder: "Enter contact person",
    validation: { required: "Contact Person is required" },
  },
  {
    name: "mobileNo",
    label: "Mobile No",
    dtName: "MobileNo",
    type: "text",
    placeholder: "Enter mobile number",
    validation: {
      required: "Mobile No is required",
      pattern: {
        value: /^[0-9]{10}$/,
        message: "Mobile Number must be 10 digits",
      },
    },
  },
  {
    name: "email",
    label: "Email",
    dtName: "EMailID",
    type: "email",
    placeholder: "Enter email address",
    validation: {
      required: "Email is required",
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        message: "Invalid email address",
      },
    },
  },
  {
    name: "pan",
    label: "PAN",
    dtName: "PAN",
    type: "text",
    placeholder: "Enter PAN number",
    validation: {
      required: "PAN is required",
    },
  },
  {
    name: "gstin",
    label: "GSTIN",
    dtName: "GSTIN",
    type: "text",
    placeholder: "Enter GSTIN",
    validation: { required: "GSTIN is required" },
  },

  {
    name: "whatsappNo",
    dtName: "SMS_Whatsapp_No",
    label: "Whatsapp No",
    type: "text",
    placeholder: "Enter Whatsapp number",
    validation: {
      pattern: {
        value: /^[0-9]{10}$/,
        message: "Whatsapp number must be 10 digits",
      },
    },
  },
];

export const brandMasterFormFields = [
  // {
  //   name: "code",
  //   label: "Code",
  //   type: "text",
  //   placeholder: "Enter code",
  //   validation: { required: "Code is required" },
  // },
  {
    name: "brandName",
    dtName: "BName",
    label: "Brand Name",
    type: "text",
    placeholder: "Enter brand Name",
    validation: { required: "Brand Name is required" },
  },
  {
    name: "brandAbbr",
    dtName: "BAbbr",
    label: "Brand Abbr",
    type: "text",
    placeholder: "Enter brand Abbr",
    validation: { required: false },
  },
];

export const unitMasterFormFields = [
  // {
  //   name: "code",
  //   label: "Code",
  //   type: "text",
  //   placeholder: "Enter code",
  //   validation: { required: "Code is required" },
  // },
  {
    name: "UQC",
    dtName: "UQC",
    label: "UQC",
    type: "dropdown",
    options: [], // Adjust as needed
    validation: { required: "UQC is required" },
  },
  {
    name: "unit",
    label: "Unit Name",
    dtName: "UName",
    type: "text",
    placeholder: "Enter unit",
    validation: { required: "Unit is required" },
  },
  // {
  //   name: "unitqty",
  //   dtName: "UnitQty",
  //   label: "Unit Qty",
  //   type: "number",
  //   readOnly: true,
  // },
  {
    name: "weightUnit",
    label: "Weight Unit",
    dtName: "WU",
    type: "checkbox",
    placeholder: "Enter weight unit quantity",
    validation: { required: false },
  },
];

export const finishMasterFormFields = [
  // {
  //   name: "code",
  //   label: "Code",
  //   type: "text",
  //   placeholder: "Enter code",
  //   validation: { required: "Code is required" },
  // },
  {
    name: "type",
    label: "Type",
    type: "dropdown",
    options: ["Finish A", "Finish B", "Finish C"], // Adjust as needed
    validation: { required: "Finish is required" },
  },
  {
    name: "finishName",
    label: "Finish Name",
    type: "text",
    placeholder: "Enter finish Name",
    validation: { required: "Finish Name is required" },
  },
  {
    name: "finishAbbr",
    label: "Finish Abbr",
    type: "text",
    placeholder: "Enter finish Abbr",
    validation: { required: "Finish Abbr is required" },
  },
];

export const subGroupMasterFormFields = [
  // {
  //   name: "code",
  //   label: "Code",
  //   type: "text",
  //   placeholder: "Enter code",
  //   validation: { required: "Code is required" },
  // },
  {
    name: "itemGroup",
    label: "Item Group",
    type: "dropdown",
    options: ["Item Group A", "Item Group B", "Item Group C"], // Adjust as needed
    validation: { required: "Item Group is required" },
  },
  {
    name: "subGroupName",
    label: "Sub Group Name",
    type: "text",
    placeholder: "Enter Sub Group Name",
    validation: { required: "Sub Group Name is required" },
  },
];

export const itemGroupMasterFormFields = [
  // {
  //   name: "code",
  //   label: "Code",
  //   type: "text",
  //   placeholder: "Enter code",
  //   validation: { required: "Code is required" },
  // },
  {
    name: "groupType",
    label: "Group Type",
    type: "dropdown",
    options: ["Group Type A", "Group Type B", "Group Type C"], // Adjust as needed
    validation: { required: "Group Type is required" },
  },
  {
    name: "itemGroupName",
    label: "Item Group Name",
    type: "text",
    placeholder: "Enter Item Group Name",
    validation: { required: "Item Group Name is required" },
  },
  {
    name: "groupAbbr",
    label: "Group Abbreviation",
    type: "text",
    placeholder: "Enter Group Abbreviation",
    validation: { required: "Group Abbreviation is required" },
  },
  {
    name: "invoiceCaption",
    label: "Invoice Caption",
    type: "text",
    placeholder: "Enter Invoice Caption",
    validation: { required: "Invoice Caption is required" },
  },
  {
    name: "labelCaption",
    label: "Label Caption",
    type: "text",
    placeholder: "Enter Label Caption",
    validation: { required: "Label Caption is required" },
  },
  {
    name: "exportDescription",
    label: "Export Description",
    type: "text",
    placeholder: "Enter Export Description",
    validation: { required: "Export Description is required" },
  },
  {
    name: "hsnSacCode",
    label: "HSN/SAC Code",
    type: "dropdown",
    options: ["HSN 1", "HSN 2"], // Goods or Services options
    validation: { required: "HSN/SAC Code is required" },
  },
  {
    name: "goodsOrServices",
    label: "Goods / Services",
    type: "dropdown",
    options: ["Goods", "Services"], // Goods or Services options
    validation: { required: "Goods or Services selection is required" },
  },
  {
    name: "rateOfGST",
    label: "Rate of GST",
    type: "dropdown",
    options: ["GST 1", "GST 2"], // Goods or Services options
    validation: { required: "Rate of GST is required" },
  },
];

export const itemMasterFormFields = [
  // {
  //   name: "code",
  //   label: "Code",
  //   type: "text",
  //   placeholder: "Enter code",
  //   validation: { required: "Code is required" },
  // },
  {
    name: "status",
    label: "Status",
    type: "dropdown",
    options: ["Active", "Inactive"], // Adjust as needed
    validation: { required: "Status is required" },
  },
  {
    name: "group",
    label: "Group",
    type: "dropdown",
    options: ["Group A", "Group B", "Group C"], // Adjust as needed
    validation: { required: "Group is required" },
  },
  {
    name: "brand",
    label: "Brand",
    type: "dropdown",
    options: ["Brand A", "Brand B", "Brand C"], // Adjust as needed
    validation: { required: "Brand is required" },
  },
  {
    name: "subGroup",
    label: "Subgroup",
    type: "dropdown",
    options: ["Subgroup A", "Subgroup B", "Subgroup C"], // Adjust as needed
    validation: { required: "Subgroup is required" },
  },
  {
    name: "itemCode",
    label: "Item Code",
    type: "text",
    placeholder: "Enter Item Code",
    validation: { required: "Item Code is required" },
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
    placeholder: "Enter Item Name",
    validation: { required: "Item Name is required" },
  },
  {
    name: "goodsOrServices",
    label: "Goods / Services",
    type: "dropdown",
    options: ["Goods", "Services"], // Adjust as needed
    validation: { required: "Goods/Services selection is required" },
  },
  {
    name: "itemType",
    label: "Item Type",
    type: "dropdown",
    options: ["Type A", "Type B", "Type C"], // Adjust as needed
    validation: { required: "Item Type is required" },
  },
  {
    name: "hsnSacCode",
    label: "HSN/SAC Code",
    type: "dropdown",
    options: ["HSN 1", "HSN 2"], // Adjust as needed
    validation: { required: "HSN/SAC Code is required" },
  },
  {
    name: "minQty",
    label: "Min Qty",
    type: "text",
    placeholder: "Enter Minimum Quantity",
    validation: { required: "Min Qty is required" },
  },
  {
    name: "maxQty",
    label: "Max Qty",
    type: "text",
    placeholder: "Enter Maximum Quantity",
    validation: { required: "Max Qty is required" },
  },
  {
    name: "rateOfGST",
    label: "Rate of GST",
    type: "dropdown",
    options: ["GST 1", "GST 2"], // Adjust as needed
    validation: { required: "Rate of GST is required" },
  },
  {
    name: "purchasePrice",
    label: "Purchase Price",
    type: "text",
    placeholder: "Enter Purchase Price",
    validation: { required: "Purchase Price is required" },
  },
  {
    name: "salePrice",
    label: "Sale Price",
    type: "text",
    placeholder: "Enter Sale Price",
    validation: { required: "Sale Price is required" },
  },
  {
    name: "deadItem",
    label: "Dead Item",
    type: "checkbox",
    validation: {},
  },
  {
    name: "packing",
    label: "Packing",
    type: "text",
    placeholder: "Enter Packing",
    validation: {},
  },
  {
    name: "description",
    label: "Description",
    type: "text",
    placeholder: "Enter Description",
    validation: {},
  },
  {
    name: "unit",
    label: "Unit",
    type: "dropdown",
    options: ["Unit A", "Unit B", "Unit C"], // Adjust as needed
    validation: { required: "Unit is required" },
  },
  {
    name: "itemNetWeight",
    label: "Item Net Weight",
    type: "text",
    placeholder: "Enter Item Net Weight",
    validation: {},
  },
  {
    name: "itemGrossWeight",
    label: "Item Gross Weight",
    type: "text",
    placeholder: "Enter Item Gross Weight",
    validation: {},
  },
];

export const taxMasterFormFields = [
  {
    name: "group",
    dtName: "MGName",
    label: "Group",
    type: "dropdown",
    options: [], // Adjust as needed
    validation: { required: true },
  },
  {
    name: "taxDescription",
    dtName: "TaxName",
    label: "Tax Description",
    type: "text",
    placeholder: "Enter tax description",
    validation: { required: "Tax Description is required" },
  },
  {
    name: "tax",
    label: "Tax",
    dtName: "Tax",
    type: "number",
    placeholder: "Enter tax",
    validation: { required: "Tax is required" },
  },
  {
    name: "taxRemark",
    label: "Tax Remark",
    dtName: "TaxRemark",
    type: "text",
    placeholder: "Enter tax remark",
    validation: { required: "Tax Remark is required" },
  },
  {
    name: "remark",
    label: "Remark",
    dtName: "Remark",
    type: "text",
    placeholder: "Enter remark",
    validation: {},
  },
  {
    name: "openingBalance",
    label: "Opening Balance",
    dtName: "OpBal",
    type: "number",
    placeholder: "Enter opening balance",
    validation: { required: false },
  },
  {
    name: "crDr",
    label: "CR/DR",
    dtName: "DrCr",
    type: "dropdown",
    options: ["CR", "DR"], // Credit/ Debit options
    validation: { required: false },
  },
  {
    name: "category",
    label: "Category",
    dtName: "TType",
    type: "dropdown",
    options: [], // Adjust as needed
    validation: { required: false },
  },
];

export const gstMasterFormFields = [
  {
    name: "under",
    label: "Under",
    type: "dropdown",
    dtName: "GSTType",
    options: [], // Adjust as needed
    validation: { required: true },
  },
  {
    name: "gst",
    label: "GST",
    dtName: "GSTName",
    type: "text",
    placeholder: "Enter GST Name with %",
    validation: { required: "GST is required" },
  },
  {
    name: "cgst",
    label: "CGST",
    type: "dropdown",
    dtName: "CGST",
    options: [], // Adjust rates as needed
    validation: { required: false },
  },
  {
    name: "sgst",
    label: "SGST",
    dtName: "SGST",
    type: "dropdown",
    options: [], // Adjust rates as needed
    validation: { required: false },
  },
  {
    name: "igst",
    label: "IGST",
    dtName: "IGST",
    type: "dropdown",
    options: [], // Adjust rates as needed
    validation: { required: false },
  },
  {
    name: "cess",
    label: "CESS",
    dtName: "CESS",
    type: "dropdown",
    options: [], // Adjust rates as needed
    validation: { required: false },
  },
  {
    name: "rateOfGST",
    label: "Rate of GST",
    dtName: "ROGST",
    type: "text",
    validation: { required: "Rate of GST is required" },
  },
];

export const hsnMasterFormFields = [
  {
    name: "rate",
    dtName: "GSTName",
    label: "Rate of GST",
    type: "dropdown",
    options: [], // Adjust as needed
    validation: { required: "Rate of GST is required" },
  },
  {
    name: "hsnCode",
    dtName: "HSNCode",

    label: "HSN Code",
    type: "text",
    placeholder: "Enter HSN Name",
    validation: { required: "HSN Code is required" },
  },
  {
    name: "description",
    dtName: "HSNName",

    label: "Description",
    type: "text",
    placeholder: "Enter description",
    validation: { required: false },
  },
  {
    name: "category",
    dtName: "GSType",

    label: "Goods / Services",
    type: "dropdown",
    options: [], // Options for Goods or Services
    validation: { required: "Selection is required" },
  },
  {
    name: "unit",
    label: "Unit",
    dtName: "UName",

    type: "dropdown",
    options: [], // Adjust units as needed
    validation: { required: "Unit is required" },
  },
  {
    name: "uqc",
    label: "UQC",
    dtName: "UQC",

    type: "dropdown",
    options: [], // Adjust UQC options as needed
    validation: { required: false },
  },
];

export const priceListMasterFormFields = [
  // {
  //   name: "code",
  //   label: "Code",
  //   type: "text",
  //   placeholder: "Enter code",
  //   validation: { required: "Code is required" },
  // },
  {
    name: "category",
    label: "Category",
    type: "dropdown",
    options: ["Category A", "Category B", "Category C"], // Adjust as needed
    validation: { required: "Category is required" },
  },
  {
    name: "type",
    label: "Type",
    type: "dropdown",
    options: ["Type A", "Type B", "Type C"], // Adjust options as needed
    validation: { required: "Type is required" },
  },
  {
    name: "priceList",
    label: "Price List",
    type: "text",
    placeholder: "Enter price list",
    validation: { required: "Price List is required" },
  },
  {
    name: "inactive",
    label: "Inactive",
    type: "checkbox",
    validation: {},
  },
  {
    name: "show",
    label: "Show",
    type: "checkbox",
    validation: {},
  },
];

export const companyPriceListMasterFormFields = [
  {
    name: "priceList",
    label: "Price List",
    type: "dropdown",
    options: ["Price List A", "Price List B", "Price List C"], // Adjust as needed
    validation: { required: "Price List is required" },
  },
];

export const initialData = [
  {
    serialNo: 1,
    id: 1,
    code: "A001",
    group: "Group A",
    abbr: "AGT",
    agentName: "John Doe",
    regType: "Regular",
    address1: "123 Elm St",
    address2: "Suite 101",
    city: "Metropolis",
    state: "State 1",
    gstin: "GST1234567890",
    mobile: "1234567890",
    email: "john.doe@example.com",
    whatsapp: "1234567890",
    balance: "1000.00",
    balanceType: "Cr",
    commission: "5.00",
    pan: "ABCDE1234F",
  },
  {
    serialNo: 2,
    id: 2,
    code: "A002",
    group: "Group B",
    abbr: "AG2",
    agentName: "Jane Smith",
    regType: "Consumer",
    address1: "456 Oak St",
    address2: "",
    city: "Gotham",
    state: "State 2",
    gstin: "GST0987654321",
    mobile: "0987654321",
    email: "jane.smith@example.com",
    whatsapp: "0987654321",
    balance: "2000.00",
    balanceType: "Dr",
    commission: "7.50",
    pan: "XYZAB5678C",
  },
  {
    serialNo: 3,
    id: 3,
    code: "A003",
    group: "Group C",
    abbr: "AG3",
    agentName: "Emily Johnson",
    regType: "Unregistered",
    address1: "789 Pine St",
    address2: "",
    city: "Star City",
    state: "State 3",
    gstin: "GST1122334455",
    mobile: "2345678901",
    email: "emily.johnson@example.com",
    whatsapp: "2345678901",
    balance: "1500.00",
    balanceType: "Cr",
    commission: "6.00",
    pan: "LMNOP6789D",
  },
  {
    serialNo: 4,
    id: 4,
    code: "A004",
    group: "Group A",
    abbr: "AG4",
    agentName: "Michael Brown",
    regType: "International",
    address1: "101 Maple St",
    address2: "Apt 202",
    city: "Central City",
    state: "State 1",
    gstin: "GST5566778899",
    mobile: "3456789012",
    email: "michael.brown@example.com",
    whatsapp: "3456789012",
    balance: "2500.00",
    balanceType: "Dr",
    commission: "8.00",
    pan: "QRSTU3456E",
  },
  {
    serialNo: 5,
    id: 5,
    code: "A005",
    group: "Group B",
    abbr: "AG5",
    agentName: "Sophia Davis",
    regType: "UIN Holders",
    address1: "202 Birch St",
    address2: "",
    city: "East City",
    state: "State 2",
    gstin: "GST2233445566",
    mobile: "4567890123",
    email: "sophia.davis@example.com",
    whatsapp: "4567890123",
    balance: "3000.00",
    balanceType: "Cr",
    commission: "9.00",
    pan: "UVWXY6789F",
  },
  {
    serialNo: 6,
    id: 6,
    code: "A006",
    group: "Group C",
    abbr: "AG6",
    agentName: "Daniel Wilson",
    regType: "Regular",
    address1: "303 Cedar St",
    address2: "Office 303",
    city: "North City",
    state: "State 3",
    gstin: "GST6677889900",
    mobile: "5678901234",
    email: "daniel.wilson@example.com",
    whatsapp: "5678901234",
    balance: "1800.00",
    balanceType: "Dr",
    commission: "10.00",
    pan: "XYZAB3456G",
  },
  {
    serialNo: 7,
    id: 7,
    code: "A007",
    group: "Group A",
    abbr: "AG7",
    agentName: "Olivia Martinez",
    regType: "Consumer",
    address1: "404 Spruce St",
    address2: "",
    city: "West City",
    state: "State 1",
    gstin: "GST7788990011",
    mobile: "6789012345",
    email: "olivia.martinez@example.com",
    whatsapp: "6789012345",
    balance: "2200.00",
    balanceType: "Cr",
    commission: "6.50",
    pan: "ABCD1234H",
  },
  {
    serialNo: 8,
    id: 8,
    code: "A008",
    group: "Group B",
    abbr: "AG8",
    agentName: "James Taylor",
    regType: "International",
    address1: "505 Willow St",
    address2: "",
    city: "South City",
    state: "State 2",
    gstin: "GST8899001122",
    mobile: "7890123456",
    email: "james.taylor@example.com",
    whatsapp: "7890123456",
    balance: "2700.00",
    balanceType: "Dr",
    commission: "7.00",
    pan: "EFGHI5678J",
  },
  {
    serialNo: 9,
    id: 9,
    code: "A009",
    group: "Group C",
    abbr: "AG9",
    agentName: "Isabella Anderson",
    regType: "UIN Holders",
    address1: "606 Fir St",
    address2: "Unit 6B",
    city: "Central City",
    state: "State 3",
    gstin: "GST9900112233",
    mobile: "8901234567",
    email: "isabella.anderson@example.com",
    whatsapp: "8901234567",
    balance: "1600.00",
    balanceType: "Cr",
    commission: "8.50",
    pan: "IJKL1234K",
  },
  {
    serialNo: 10,
    id: 10,
    code: "A010",
    group: "Group A",
    abbr: "AG10",
    agentName: "Liam Robinson",
    regType: "Regular",
    address1: "707 Pine St",
    address2: "",
    city: "East City",
    state: "State 1",
    gstin: "GST1122334455",
    mobile: "9012345678",
    email: "liam.robinson@example.com",
    whatsapp: "9012345678",
    balance: "1900.00",
    balanceType: "Dr",
    commission: "7.50",
    pan: "LMNOP6789M",
  },
  {
    serialNo: 11,
    id: 11,
    code: "A011",
    group: "Group B",
    abbr: "AG11",
    agentName: "Mia Walker",
    regType: "Consumer",
    address1: "808 Maple St",
    address2: "",
    city: "West City",
    state: "State 2",
    gstin: "GST2233445566",
    mobile: "0123456789",
    email: "mia.walker@example.com",
    whatsapp: "0123456789",
    balance: "2100.00",
    balanceType: "Cr",
    commission: "6.75",
    pan: "PQRST9876N",
  },
  {
    serialNo: 12,
    id: 12,
    code: "A012",
    group: "Group C",
    abbr: "AG12",
    agentName: "Ethan Harris",
    regType: "Unregistered",
    address1: "909 Cedar St",
    address2: "Suite 4A",
    city: "North City",
    state: "State 3",
    gstin: "GST3344556677",
    mobile: "1234567891",
    email: "ethan.harris@example.com",
    whatsapp: "1234567891",
    balance: "2500.00",
    balanceType: "Dr",
    commission: "9.50",
    pan: "UVWXY6789O",
  },
  {
    serialNo: 13,
    id: 13,
    code: "A013",
    group: "Group A",
    abbr: "AG13",
    agentName: "Charlotte Lee",
    regType: "International",
    address1: "1010 Birch St",
    address2: "",
    city: "South City",
    state: "State 1",
    gstin: "GST4455667788",
    mobile: "2345678902",
    email: "charlotte.lee@example.com",
    whatsapp: "2345678902",
    balance: "3200.00",
    balanceType: "Cr",
    commission: "8.50",
    pan: "XYZAB7890P",
  },
  {
    serialNo: 14,
    id: 14,
    code: "A014",
    group: "Group B",
    abbr: "AG14",
    agentName: "Aiden Clark",
    regType: "UIN Holders",
    address1: "1111 Willow St",
    address2: "Office 5B",
    city: "Central City",
    state: "State 2",
    gstin: "GST5566778899",
    mobile: "3456789013",
    email: "aiden.clark@example.com",
    whatsapp: "3456789013",
    balance: "3000.00",
    balanceType: "Dr",
    commission: "8.00",
    pan: "EFGHI6789P",
  },
  {
    serialNo: 15,
    id: 15,
    code: "A015",
    group: "Group C",
    abbr: "AG15",
    agentName: "Amelia Lewis",
    regType: "Regular",
    address1: "1212 Fir St",
    address2: "Apt 2C",
    city: "Gotham",
    state: "State 3",
    gstin: "GST6677889900",
    mobile: "4567890124",
    email: "amelia.lewis@example.com",
    whatsapp: "4567890124",
    balance: "2200.00",
    balanceType: "Cr",
    commission: "6.25",
    pan: "IJKL5678Q",
  },
  {
    serialNo: 16,
    id: 16,
    code: "A016",
    group: "Group A",
    abbr: "AG16",
    agentName: "Lily Walker",
    regType: "Consumer",
    address1: "1313 Oak St",
    address2: "",
    city: "Star City",
    state: "State 1",
    gstin: "GST7788990011",
    mobile: "5678901235",
    email: "lily.walker@example.com",
    whatsapp: "5678901235",
    balance: "1800.00",
    balanceType: "Dr",
    commission: "5.75",
    pan: "ABCD6789R",
  },
  {
    serialNo: 17,
    id: 17,
    code: "A017",
    group: "Group B",
    abbr: "AG17",
    agentName: "Noah King",
    regType: "International",
    address1: "1414 Pine St",
    address2: "Suite 7D",
    city: "Metropolis",
    state: "State 2",
    gstin: "GST8899001122",
    mobile: "6789012346",
    email: "noah.king@example.com",
    whatsapp: "6789012346",
    balance: "1900.00",
    balanceType: "Cr",
    commission: "7.75",
    pan: "EFGHI6789S",
  },
  {
    serialNo: 18,
    id: 18,
    code: "A018",
    group: "Group C",
    abbr: "AG18",
    agentName: "Mia Young",
    regType: "UIN Holders",
    address1: "1515 Maple St",
    address2: "",
    city: "Gotham",
    state: "State 3",
    gstin: "GST9900112233",
    mobile: "7890123457",
    email: "mia.young@example.com",
    whatsapp: "7890123457",
    balance: "2100.00",
    balanceType: "Dr",
    commission: "8.25",
    pan: "IJKL6789T",
  },
  {
    serialNo: 19,
    id: 19,
    code: "A019",
    group: "Group A",
    abbr: "AG19",
    agentName: "Evelyn Scott",
    regType: "Regular",
    address1: "1616 Birch St",
    address2: "",
    city: "Central City",
    state: "State 1",
    gstin: "GST2233445566",
    mobile: "8901234568",
    email: "evelyn.scott@example.com",
    whatsapp: "8901234568",
    balance: "2000.00",
    balanceType: "Cr",
    commission: "7.00",
    pan: "LMNOP6789U",
  },
  {
    serialNo: 20,
    id: 20,
    code: "A020",
    group: "Group B",
    abbr: "AG20",
    agentName: "Oliver Adams",
    regType: "Consumer",
    address1: "1717 Cedar St",
    address2: "Apt 8B",
    city: "North City",
    state: "State 2",
    gstin: "GST3344556677",
    mobile: "9012345679",
    email: "oliver.adams@example.com",
    whatsapp: "9012345679",
    balance: "2300.00",
    balanceType: "Dr",
    commission: "6.00",
    pan: "UVWXY6789V",
  },
];

export const purchaseVoucherFormFields = [
  // {
  //   name: "code",
  //   label: "No",
  //   type: "text",
  //   placeholder: "Enter No",
  //   validation: { required: "Code is required" },
  // },
  {
    name: "entryDate",
    label: "Entry Date",
    type: "date",
  },
  {
    name: "invoiceNo",
    label: "Invoice No",
    placeholder: "Enter Invoice No",
    type: "text",
  },
  {
    name: "invoiceDate",
    label: "Invoice Date",
    type: "date",
  },
  {
    name: "supplierName",
    label: "Supplier Name",
    type: "dropdown",
    options: ["Supplier 1", "Supplier 2"], // Credit/ Debit options
    validation: { required: "Supplier is required" },
  },
];

export const itemDetailsFields = [
  {
    name: "qty",
    label: "Quantity",
    type: "text",
    placeholder: "Enter Quantity",
    validation: { required: "Quantity is required" },
  },
  {
    name: "grossWt",
    label: "Gross Weight",
    type: "text",
    placeholder: "Enter Gross Weight",
    readOnly: true,
  },
  {
    name: "bagWt",
    label: "Bag Weight",
    type: "text",
    placeholder: "Enter Bag Weight",
    readOnly: true,
  },
  {
    name: "netWt",
    label: "Net Weight",
    type: "text",
    placeholder: "Enter Net Weight",
    readOnly: true,
  },
  {
    name: "recdWt",
    label: "Received Weight",
    type: "text",
    placeholder: "Enter Received Weight",
    readOnly: true,
  },
  {
    name: "rate",
    label: "Rate",
    type: "text",
    placeholder: "Enter Rate",
    validation: { required: "Rate is required" },
  },
  {
    name: "unit",
    label: "Unit",
    type: "text",
    validation: { required: "Unit is required" },
    readOnly: true,
  },
  {
    name: "amount",
    label: "Amount",
    type: "text",
    placeholder: "Calculated Amount",
    readOnly: true, // This field will be calculated
  },
  {
    name: "discount",
    label: "Discount (%)",
    type: "text",
    placeholder: "Enter Discount",
    readOnly: true,
  },
  {
    name: "amountAfterDiscount",
    label: "Taxable Amount",
    type: "text",
    placeholder: "Calculated Amount After Discount",
    readOnly: true, // This field will be calculated
  },
  {
    name: "brand",
    label: "Brand",
    type: "dropdown",
    options: ["Brand 1", "Brand 2"], // Brand options
    readOnly: true,
  },
  {
    name: "remark",
    label: "Remark",
    type: "text",
    placeholder: "Enter Remark",
    readOnly: true,
  },
  {
    name: "gstRate",
    label: "GST Rate (%)",
    type: "text",
    placeholder: "Enter GST Rate",
    defaultValue: "18", // Default GST Rate
    readOnly: true, // This field is default
  },
  {
    name: "cgst",
    label: "CGST",
    type: "text",
    placeholder: "Calculated CGST",
    readOnly: true, // This field will be calculated
  },
  {
    name: "sgst",
    label: "SGST",
    type: "text",
    placeholder: "Calculated SGST",
    readOnly: true, // This field will be calculated
  },
  {
    name: "igst",
    label: "IGST",
    type: "text",
    placeholder: "Calculated IGST",
    readOnly: true, // This field will be calculated
  },
  {
    name: "cess",
    label: "CESS",
    type: "text",
    placeholder: "Enter CESS",
    readOnly: true,
  },
];
