import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../../Common/Loader"; // Ensure you have a Loader component
import { useLocation } from "react-router-dom";
import {
  branch,
  formatDate,
  getCurrentPageData,
  user,
  dateFormatter,
} from "../../../../utils/capitalize";
import axios from "axios";
import { BASE_URL } from "../../../Common/apiURL";
import Table from "../../../Common/SearchFilterTable";
import jsPDF from "jspdf";
import "jspdf-autotable";

const StockSummary = () => {
  const [itemGroup, setItemGroup] = useState("");
  const [itemName, setItemName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [showData, setShowData] = useState(false); // To control table display
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const [rows, setRows] = useState([]);
  const [resultWebPartyList, setResultWebPartyList] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Dropdown toggle state
  const [showPopup, setShowPopup] = useState(false);

  const [columnVisibility, setColumnVisibility] = useState({
    SNo: true, // Add visibility state for 'S No' column
    IName: true,
    BalQty: true,
    UName: true,
  });
  const [selectedColumns, setSelectedColumns] = useState({
    "S No": true,
    "Item Name": true,
    Quantity: true,
    Unit: true,
  });

  let visibleColumns = [];
  // Log the visible columns
  useEffect(() => {
    visibleColumns = Object.keys(columnVisibility).filter(
      (key) => columnVisibility[key]
    );
    console.log("Visible Columns: ", visibleColumns);
  }, [columnVisibility]);

  // Handle the checkbox change
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSelectedColumns((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };
  // Basic Information For API's
  const current = useLocation();
  const currentPage = current.pathname.split("/")[1];
  const targetPage = getCurrentPageData(currentPage);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const Username = user[0]?.UserID;
        const Depot = user[0]?.DpCode;
        const FormKey = targetPage?.FormKey;
        const BranchCode = branch[0]?.BranchCode;
        const requestBody = { Username, Depot, FormKey, BranchCode };

        const config = {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        };

        const response = await axios.post(
          `${BASE_URL}/dashboard/web-master`,
          requestBody,
          config
        );
        if (response.data.success) {
          const filterWithAll = [{ IGName: "All" }, ...response.data.data];
          setResultWebPartyList(filterWithAll); // Store the result
        } else {
          console.log("Failed to fetch Parties data");
        }
      } catch (err) {
        console.error("Error fetching Parties data:", err);
      }
    };
    fetchData();
  }, []);

  // Handle the show button click
  const handleShowData = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Show loader while fetching data
    setShowData(false); // Hide table initially during loading

    const Username = user[0]?.UserID;
    const Depot = user[0]?.DpCode;
    const FormKey = targetPage?.FormKey;
    const BranchCode = branch[0]?.BranchCode;

    const requestBody = {
      Depot,
      BranchCode,
      Username,
      FormKey,
      PartyName: "",
      AgentName: "",
      ItemGroup: itemGroup !== "All" ? itemGroup : "",
      ItemName: itemName ? itemName : "",
      Date1: formatDate(startDate),
      Date2: formatDate(startDate),
      RptCode: 0,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}/dashboard/web-reports`,
        requestBody,
        config
      );
      setRows(response.data.data); // Set rows with fetched data
      setShowData(true); // Display the table
    } catch (error) {
      alert("Something went wrong!!!", error);
    } finally {
      setIsLoading(false); // Hide loader after fetching
    }
  };

  const handlePopupOpen = (event) => {
    setShowPopup(true); // Show the popup
    setSelectedColumns({
      "S No": true,
      "Item Name": true,
      Quantity: true,
      Unit: true,
    });
  };

  // const handleDownloadPDF = async () => {
  //   const doc = new jsPDF();

  //   const logoUrl =
  //     "https://img.freepik.com/free-photo/colorful-design-with-spiral-design_188544-9588.jpg"; // Your logo URL

  //   const currentDate = new Date();
  //   const formattedDate = currentDate.toLocaleDateString();
  //   const formattedTime = currentDate.toLocaleTimeString();

  //   // Convert Image URL to Base64
  //   const convertToBase64 = async (url) => {
  //     const response = await fetch(url);
  //     const blob = await response.blob();
  //     return new Promise((resolve) => {
  //       const reader = new FileReader();
  //       reader.onloadend = () => resolve(reader.result);
  //       reader.readAsDataURL(blob);
  //     });
  //   };

  //   try {
  //     // Convert logo to Base64
  //     const logoBase64 = await convertToBase64(logoUrl);
  //     // Add circular overlay
  //     const logoX = 15; // X position of the logo
  //     const logoY = 10; // Y position of the logo
  //     const logoWidth = 25; // Adjust size as needed (50% reduced)
  //     const logoHeight = 25; // Ensure aspect ratio remains consistent

  //     // Add Logo
  //     doc.addImage(logoBase64, "JPEG", logoX, logoY, logoWidth, logoHeight);
  //   } catch (error) {
  //     console.error("Error loading logo:", error);
  //     doc.text("Logo could not be loaded", 10, 30);
  //   }

  //   // Add Company Details and Report Name
  //   doc.setFont("helvetica", "bold"); // Set font to Helvetica and style to bold
  //   doc.setFontSize(18);
  //   doc.text("SRS Softwares", 105, 15, { align: "center" });
  //   doc.setFont("helvetica", "semibold"); // Set font to Helvetica and style to bold
  //   doc.setFontSize(10);
  //   doc.text("Address Line 1", 105, 20, { align: "center" });
  //   doc.text("Address Line 2", 105, 25, { align: "center" });
  //   doc.text("GSTIN No: XXXXXXXXXXX", 105, 29, { align: "center" });
  //   doc.setFontSize(14);
  //   doc.text("Stock Summary", 105, 36, { align: "center" });

  //   // Add Date on the Top Right
  //   doc.setFontSize(10);
  //   doc.text(`Date: ${dateFormatter(startDate)}`, 200, 20, { align: "right" });

  //   // Define table columns
  //   const tableColumns = ["S No", "Item Name", "Quantity", "Unit"];
  //   const tableRows = rows.map((row, index) => [
  //     index + 1,
  //     row.IName,
  //     typeof row.BalQty !== "number" ? row.BalQty.toFixed(2) : row.BalQty,
  //     row.UName,
  //   ]);
  //   console.log(visibleColumns);
  //   // Add table to PDF
  //   doc.autoTable({
  //     head: [tableColumns],
  //     body: tableRows,
  //     startY: 40, // Start below the header
  //     margin: { left: 10, right: 10 },
  //     theme: "plain", // Use plain to allow custom styles
  //     styles: {
  //       fillColor: [255, 255, 255], // White row background
  //       textColor: [0, 0, 0], // Black text
  //       lineColor: [0, 0, 0], // Black borders
  //       lineWidth: 0.5, // Border width
  //     },
  //     headStyles: {
  //       fillColor: [211, 211, 211], // Gray header background
  //       textColor: [0, 0, 0], // Black header text
  //       fontStyle: "bold",
  //       lineColor: [0, 0, 0], // Black border for header
  //       lineWidth: 0.5, // Border width
  //       halign: "center", // Center align all headers
  //     },
  //     bodyStyles: {
  //       fillColor: [255, 255, 255], // White background for rows
  //       textColor: [0, 0, 0], // Black text
  //     },
  //     columnStyles: {
  //       0: { cellWidth: 15, halign: "center" }, // "S No" column: Reduced width and center alignment
  //       2: { halign: "right" }, // "Quantity" column: Right alignment
  //     },
  //     tableLineColor: [0, 0, 0], // Black border for table
  //     tableLineWidth: 0.5, // Border width for table
  //   });

  //   // Add Footer with Time and Date of Printing
  //   const pageHeight = doc.internal.pageSize.height; // Get page height
  //   const pageWidth = doc.internal.pageSize.width; // Get page width
  //   doc.text(
  //     `Printed On: ${formattedDate}, ${formattedTime}`,
  //     pageWidth - 10, // Align to the right margin
  //     pageHeight - 10,
  //     { align: "right" }
  //   );

  //   // Save the PDF
  //   doc.save("Stock-Summary Report.pdf");
  // };

  const handleDownloadPDF = async (selectedColumns) => {
    const doc = new jsPDF();

    const logoUrl =
      "https://img.freepik.com/free-photo/colorful-design-with-spiral-design_188544-9588.jpg"; // Your logo URL

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();
    const formattedTime = currentDate.toLocaleTimeString();

    // Convert Image URL to Base64
    const convertToBase64 = async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    };

    try {
      // Convert logo to Base64
      const logoBase64 = await convertToBase64(logoUrl);
      const logoX = 15;
      const logoY = 10;
      const logoWidth = 25;
      const logoHeight = 25;
      doc.addImage(logoBase64, "JPEG", logoX, logoY, logoWidth, logoHeight);
    } catch (error) {
      console.error("Error loading logo:", error);
      doc.text("Logo Error", 10, 30);
    }

    // Add Company Details and Report Name
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);
    doc.text("SRS Softwares", 105, 15, { align: "center" });
    doc.setFont("helvetica", "semibold");
    doc.setFontSize(10);
    doc.text("Address Line 1", 105, 20, { align: "center" });
    doc.text("Address Line 2", 105, 25, { align: "center" });
    doc.text("GSTIN No: XXXXXXXXXXX", 105, 29, { align: "center" });
    doc.setFontSize(14);
    doc.text("Stock Summary", 105, 36, { align: "center" });

    // Add Date on the Top Right
    doc.setFontSize(10);
    doc.text(`Date: ${dateFormatter(startDate)}`, 200, 20, { align: "right" });

    // Define table columns based on selectedColumns
    const tableColumns = Object.keys(selectedColumns).filter(
      (column) => selectedColumns[column]
    );

    // Filter rows based on selected columns
    const tableRows = rows.map((row, index) => {
      return tableColumns.map((column) => {
        if (column === "S No") return index + 1;
        if (column === "Item Name") return row.IName;
        if (column === "Quantity")
          return typeof row.BalQty !== "number"
            ? row.BalQty.toFixed(2)
            : row.BalQty;
        if (column === "Unit") return row.UName;
        return "";
      });
    });

    // Add table to PDF
    doc.autoTable({
      head: [tableColumns],
      body: tableRows,
      startY: 40,
      margin: { left: 10, right: 10 },
      theme: "plain",
      styles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.5,
      },
      headStyles: {
        fillColor: [211, 211, 211],
        textColor: [0, 0, 0],
        fontStyle: "bold",
        lineColor: [0, 0, 0],
        lineWidth: 0.5,
        halign: "center",
      },
      bodyStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
      },
      columnStyles: {
        0: { cellWidth: 15, halign: "center" },
        2: { halign: "right" },
      },
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.5,
    });

    // Add Footer with Time and Date of Printing
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;
    doc.text(
      `Printed On: ${formattedDate}, ${formattedTime}`,
      pageWidth - 10,
      pageHeight - 10,
      { align: "right" }
    );

    // Save the PDF
    doc.save("Stock-Summary Report.pdf");
  };

  // Handle filter change
  const handleFilterChange = (event) => {
    setItemGroup(event.target.value);
  };

  const columns = [
    {
      Header: "S No",
      Cell: ({ row }) => (
        <div style={{ textAlign: "center" }}>
          {row.original.isTotalRow ? "" : row.index + 1}
        </div>
      ),
      accessor: "SNo",
      isInitiallyVisible: true,
    },
    {
      Header: "Item Name",
      accessor: "IName",
      isInitiallyVisible: true,
    },
    {
      accessor: "BalQty",
      Header: "Quantity",
      Cell: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          {typeof value !== "number" ? value.toFixed(2) : value}
        </div>
      ),
      isInitiallyVisible: true,
    },
    {
      accessor: "UName",
      Header: "Unit",
      Cell: ({ value }) => (
        <div style={{ textAlign: "left" }}>
          {typeof value === "number" ? value.toFixed(2) : value}
        </div>
      ),
      isInitiallyVisible: true,
    },
  ];

  const handleItemNameChange = (e) => {
    const inputValue = e.target.value;
    setItemName(inputValue);
  };

  const handleClosePopup = () => {
    setShowPopup(false); // Close the popup
  };

  const handleConfirmDownload = () => {
    handleDownloadPDF(selectedColumns);
    setShowPopup(false); // Close the popup
  };

  return (
    <div className='p-5'>
      {isLoading && <Loader />} {/* Show loader when loading */}
      <h1 className='text-sm font-semibold mb-4'>{targetPage.FormName}</h1>
      <form className='grid grid-cols-2 md:grid-cols-4 gap-2'>
        {/* Filter Dropdown */}
        <div className='flex flex-col'>
          <label className='block font-medium text-sm'>Item Group </label>
          <select
            value={itemGroup}
            onChange={handleFilterChange}
            className='w-full p-1 text-xs border rounded'
          >
            {resultWebPartyList.map((category, index) => (
              <option key={index} value={category.IGName}>
                {category.IGName}
              </option>
            ))}
          </select>
        </div>

        <div className='flex flex-col'>
          <label className='text-sm font-medium '>Item Name</label>
          <input
            type='text'
            placeholder='Enter Item Name'
            value={itemName} // Controlled input value
            onChange={handleItemNameChange} // Handler for input changes
            className='p-1 border border-gray-300 font-semibold bg-gray-300 text-xs rounded-md focus:outline-none focus:ring-2 focus:ring-black'
          />
        </div>

        {/* Datepicker */}
        <div className='flex flex-col'>
          <label className='block font-medium text-sm'>Date</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat='dd/MM/yyyy'
            maxDate={new Date()}
            className='w-full p-1 border rounded text-xs'
          />
        </div>

        {/* Show Button */}
        <div className='w-full'>
          <button
            type='button'
            onClick={handleShowData}
            className='px-4 py-2 text-xs bg-blue-600 text-white rounded w-full mt-4'
          >
            Show
          </button>
        </div>
      </form>
      {showPopup && (
        <div className='fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50'>
          <div className='bg-white p-3 rounded shadow-lg w-80'>
            <h2 className='text-xl font-semibold mb-2 text-center'>
              Download PDF
            </h2>
            <div className='flex flex-col mb-4'>
              <label className='block text-sm mb-3 font-semibold'>
                Select Columns
              </label>
              <div className='flex flex-col'>
                {columns.map((column) => (
                  <label
                    key={column.Header}
                    className='flex items-center text-sm'
                  >
                    <input
                      type='checkbox'
                      name={column.Header}
                      checked={selectedColumns[column.Header]}
                      onChange={handleCheckboxChange}
                      className='mr-2'
                    />
                    {column.Header}
                  </label>
                ))}
              </div>
            </div>
            <div className='flex justify-end mt-4 space-x-4'>
              <button
                onClick={handleClosePopup}
                className='px-4 py-2 bg-red-500 text-white rounded'
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDownload}
                className='px-4 py-2 bg-blue-500 text-white rounded'
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      <div className='mt-5'>
        <Table
          // columns={columns.filter(
          //   (column) => columnVisibility[column.accessor]
          // )}
          columns={columns}
          data={rows}
        />
      </div>
      {rows.length > 0 && (
        <div className='mt-4'>
          <button
            onClick={handlePopupOpen}
            className='px-4 py-1 text-sm bg-green-600 text-white rounded w-full'
          >
            Download PDF
          </button>
        </div>
      )}
    </div>
  );
};

export default StockSummary;
