import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../Common/apiURL";
import {
  getCurrentPageData,
  user,
  selectedBranch,
  SerialKey,
  config,
} from "../../../utils/capitalize";
import { unitMasterFormFields } from "../data"; // Ensure correct path

const UnitForm = ({ onSubmit, setActiveTab, formData }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [formFields, setFormFields] = useState(unitMasterFormFields);
  const [formValues, setFormValues] = useState(formData);
  const [executionCount, setExecutionCount] = useState(0);
  const [uqcOptions, setUqcOptions] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const current = useLocation();
  const currentPage = current.pathname.split("/")[1];
  const targetPage = getCurrentPageData(currentPage);
  const formKey = targetPage?.FormKey;
  const branchCode = selectedBranch?.BranchCode;
  const UserID = user[0]?.UserID;

  useEffect(() => {
    setFormValues(formData);
    if (formData && Object.keys(formData).length > 0) {
      setIsEditing(true);
      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]);
      });
    } else {
      setIsEditing(false);
    }
    setValue("UnitQty", 1); // Set default value for UnitQty
  }, [formData, setValue]);

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const KeyCode = executionCount === 0 ? "1" : "2";
        const response = await axios.post(
          `${BASE_URL}/dashboard/master-data-list`,
          {
            FormKey: String(formKey),
            BranchCode: branchCode,
            UserID,
            SerialKey,
            KeyCode,
          },
          config
        );
        const data = response.data.data;
        const uqc = data.map((item) => item.UQC);
        setUqcOptions(uqc);
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      }
    };

    if (executionCount < 1) {
      fetchDropdownOptions();
    }
  }, [executionCount, formKey, branchCode, UserID, SerialKey]);

  const renderField = (field) => {
    if (!field) return null;

    const { dtName, name, label, type, placeholder, options, validation } =
      field;

    const adjustedValidation = isEditing ? {} : validation;

    if (type === "text" || type === "number" || type === "email") {
      return (
        <div key={name} className='flex flex-col'>
          <label className='block font-medium'>{label}</label>
          <input
            type={type}
            placeholder={placeholder}
            {...register(name, adjustedValidation)}
            className='w-full p-1 border rounded'
            defaultValue={formValues[dtName]}
          />
          {errors[name] && (
            <span className='text-red-500 text-sm'>{errors[name].message}</span>
          )}
        </div>
      );
    } else if (type === "dropdown") {
      if (name === "UQC") {
        return (
          <div key={name} className='flex flex-col'>
            <label className='block font-medium'>{label}</label>
            <select
              {...register(name, adjustedValidation)}
              className='w-full p-1 border rounded'
              value={formValues[dtName]}
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  [dtName]: e.target.value,
                }))
              }
            >
              <option value=''>Select {label}</option>
              {uqcOptions.map((option, index) => (
                <option key={`${option}-${index}`} value={option.UQC}>
                  {option}
                </option>
              ))}
            </select>
            {errors[name] && (
              <span className='text-red-500 text-sm'>
                {errors[name].message}
              </span>
            )}
          </div>
        );
      } else {
        return (
          <div key={name} className='flex flex-col'>
            <label className='block font-medium'>{label}</label>
            <select
              {...register(name, adjustedValidation)}
              className='w-full p-1 border rounded'
              value={formValues[dtName]}
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  [dtName]: e.target.value,
                }))
              }
            >
              <option value=''>Select {label}</option>
              {options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {errors[name] && (
              <span className='text-red-500 text-sm'>
                {errors[name].message}
              </span>
            )}
          </div>
        );
      }
    } else if (type === "checkbox") {
      return (
        <div key={name} className='flex items-center'>
          <input
            type='checkbox'
            {...register(name, validation)}
            className='mr-2'
            defaultValue={formValues[dtName]}
          />
          <label className='block font-medium'>{label}</label>
        </div>
      );
    }
    return null;
  };

  const handleFormSubmit = async (data) => {
    const defaultValues = {
      SerialKey: SerialKey || "",
      BranchCode: branchCode || "",
      UserID: UserID || "",
      UnitCode: data.UCode || "",
      UnitName: data.unit || "",
      UQC: data.UQC,
      UnitQty: "1",
      WU: data.weightUnit === false ? "0" : "1",
      RefCnt: !isEditing ? "1" : "2",
    };
    try {
      if (onSubmit) {
        const response = await axios.post(
          `${BASE_URL}/dashboard/unit-master-form-data`,
          defaultValues,
          config
        );
        alert(response.data.message);
        setActiveTab("Lookup");
      }
      reset();
    } catch (error) {
      console.error("Error while submitting the form:", error);
      alert("An error occurred: ", error);
    }
    // You can replace this with your API call or any other logic for handling the submission
  };

  return (
    <div className='bg-gray-200 p-3 rounded-xl shadow-lg'>
      <h2 className='text-lg text-center font-semibold mb-4 uppercase'>
        {targetPage?.FormName}
      </h2>
      <form
        onSubmit={handleSubmit(handleFormSubmit)} // This will trigger handleFormSubmit
        className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4'
      >
        {formFields.map((field) => renderField(field))}
        <div className='col-span-full'>
          <button
            type='submit'
            className='px-4 py-2 text-sm bg-black text-white rounded w-full'
          >
            {isEditing ? "Update" : "Submit"}{" "}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UnitForm;
