import React, { useState, useEffect } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import {
  getCurrentPageData,
  user,
  selectedBranch,
  SerialKey,
  config,
} from "../../../utils/capitalize";
import { BASE_URL } from "../../Common/apiURL";
import axios from "axios";
import DataTableComponent from "../../../Components/DataTable";

const GSTMasterLookUp = ({ setActiveTab, setFormData }) => {
  const [data, setData] = useState([]);

  const current = useLocation();
  const currentPage = current.pathname.split("/")[1];
  const convertToCustomTitleCase = (str) => {
    const formattedString = str
      .replace(/-/g, " ") // Replace hyphens with spaces
      .toLowerCase(); // Convert the entire string to lowercase first

    // Capitalize the first three letters and "Master" properly
    return formattedString
      .split(" ")
      .map((word, index) => {
        if (index === 0) {
          return word.slice(0, 3).toUpperCase() + word.slice(3); // First three letters in uppercase
        }
        return word.charAt(0).toUpperCase() + word.slice(1); // Capitalize first letter of subsequent words
      })
      .join(" ");
  };
  const pageTitle = convertToCustomTitleCase(currentPage);
  const targetPage = getCurrentPageData(pageTitle);
  const formKey = targetPage?.FormKey;
  const branchCode = selectedBranch?.BranchCode;
  const UserID = user[0]?.UserID;

  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/dashboard/master-lookup-data`,
        {
          FormKey: String(formKey),
          BranchCode: branchCode,
          UserID,
          SerialKey,
        },
        config
      );

      const data = response.data.data;
      console.log(data);
      setData(data);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Edit handler
  const handleEdit = (row) => {
    // setEditId(row.TaxCode); // Set the ID of the row being edited
    setFormData({ ...row }); // Set current row data in form
    setActiveTab("Create"); // Switch to the "Create" tab
  };

  // Delete handler
  const handleDelete = async (row) => {
    // Show confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this record?"
    );

    if (!isConfirmed) {
      return;
    }

    const defaultValues = {
      SerialKey: SerialKey || "",
      BranchCode: branchCode || "",
      UserID: UserID || "",
      GSTCode: row.GSTCode || "", // Optional parameter
      GSTType: row.GSTType,
      GSTName: row.GSTName || "",
      CGST: row.cgst || "",
      SGST: row.sgst || "",
      IGST: row.igst || "",
      CESS: row.cess || "",
      TaxRate: parseFloat(row.rateOfGST) || 0.0,
      RefCnt: "3",
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/dashboard/gst-master-form-data`,
        defaultValues,
        config
      );
      if (response.data.success) {
        const updatedData = data.filter((item) => item.GSTCode !== row.GSTCode); // Match TaxCode for deletion
        setData(updatedData);
        alert("Record deleted successfully");
      } else {
        alert("Failed to delete the record");
      }
    } catch (error) {
      console.error("Error while submitting the form:", error);
      alert("An error occurred: ", error);
    }
  };

  // Dynamically create columns based on form fields and visibility
  const columns = [
    {
      name: "S No",
      selector: (row, index) => index + 1, // Generate serial number dynamically
      sortable: true,
      width: "80px",
      cell: (row, index) => <div className='text-center py-2'>{index + 1}</div>,
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <FaEdit
            className='text-blue-500 mx-2'
            onClick={() => handleEdit(row)}
          />
          <FaTrash className='text-red-500' onClick={() => handleDelete(row)} />
        </>
      ),
      ignoreRowClick: true,
    },
    {
      name: "GSTName",
      selector: (row) => row.GSTName,
      sortable: true,
    },
    {
      name: "CGST",
      selector: (row) => row.CGST,
      sortable: true,
    },
    {
      name: "SGST",
      selector: (row) => row.SGST,
      sortable: true,
    },
    {
      name: "IGST",
      selector: (row) => row.IGST,
      sortable: true,
    },
    {
      name: "CESS",
      selector: (row) => row.CESS,
      sortable: true,
    },
    {
      name: "Rate ",
      selector: (row) => row.ROGST,
      sortable: true,
    },
  ];

  return (
    <DataTableComponent
      title={targetPage?.FormName}
      data={data}
      columns={columns}
      onEdit={handleEdit}
      onDelete={handleDelete}
      fetchData={fetchData}
    />
  );
};

export default GSTMasterLookUp;
