import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { FaEdit, FaTrash, FaCaretDown } from "react-icons/fa";
import {
  getCurrentPageData,
  user,
  selectedBranch,
  SerialKey,
  config,
} from "../../../utils/capitalize";
import { BASE_URL } from "../../Common/apiURL";
import axios from "axios";
import { useLocation } from "react-router-dom";
import DataTableComponent from "../../../Components/DataTable";

const UnitLookUp = ({ setFormData, setActiveTab }) => {
  const [data, setData] = useState([]);

  const current = useLocation();
  const currentPage = current.pathname.split("/")[1];
  const targetPage = getCurrentPageData(currentPage);
  const formKey = targetPage?.FormKey;
  const branchCode = selectedBranch?.BranchCode;
  const UserID = user[0]?.UserID;

  // Fetch Data
  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/dashboard/master-lookup-data`,
        {
          FormKey: String(formKey),
          BranchCode: branchCode,
          UserID,
          SerialKey,
        },
        config
      );

      const data = response.data.data;
      console.log(data);
      setData(data);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // Edit handler
  const handleEdit = (row) => {
    setFormData(row);
    setActiveTab("Create");
  };

  // Delete handler
  const handleDelete = async (row) => {
    console.log("Deleting record:", row);
    // Show confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this record?"
    );

    if (!isConfirmed) {
      return;
    }

    try {
      const defaultValues = {
        SerialKey: SerialKey || "",
        BranchCode: branchCode || "",
        UserID: UserID || "",
        UnitCode: row.UCode || "",
        UnitName: row.UName || "",
        UQC: row.UQC || "",
        UnitQty: "",
        WU: "",
        RefCnt: "3",
      };
      // Call the API to delete the record from the server
      const response = await axios.post(
        `${BASE_URL}/dashboard/unit-master-form-data`,
        defaultValues,
        config
      );

      if (response.data.success) {
        // If delete is successful, update the local data state
        const updatedData = data.filter((item) => item.UCode !== row.UCode); // Correct filter logic
        setData(updatedData);
        alert("Record deleted successfully");
      } else {
        alert("Failed to delete the record");
      }
    } catch (error) {
      console.error("Error deleting record:", error);
      alert("An error occurred while deleting the record");
    }
  };

  // Dynamically create columns based on form fields and visibility
  const columns = [
    {
      name: "S No",
      selector: (row, index) => index + 1, // Generate serial number dynamically
      sortable: true,
      width: "80px",
      cell: (row, index) => <div className='text-center py-2'>{index + 1}</div>,
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <FaEdit
            className='text-blue-500 mx-2'
            onClick={() => handleEdit(row)}
          />
          <FaTrash className='text-red-500' onClick={() => handleDelete(row)} />
        </>
      ),
      ignoreRowClick: true,
    },
    {
      name: "UnitName",
      selector: (row) => row.UName,
      sortable: true,
    },
    {
      name: "Qty",
      selector: (row) => row.UnitQty,
      sortable: true,
    },
    {
      name: "UQC",
      selector: (row) => row.UQC,
      sortable: true,
    },
    {
      name: "Weight Unit",
      selector: (row) => (row.WU === 0 ? "No" : "Yes"),
      sortable: true,
    },
  ];

  return (
    <DataTableComponent
      title={targetPage?.FormName}
      data={data}
      columns={columns}
      onEdit={handleEdit}
      onDelete={handleDelete}
      fetchData={fetchData}
    />
  );
};

export default UnitLookUp;
