// import React, { useState, useEffect } from "react";
// import DataTable from "react-data-table-component";
// import { FaCaretDown, FaSync } from "react-icons/fa";

// const DataTableComponent = ({
//   title,
//   data = [],
//   columns = [],
//   fetchData = () => {}, // Function to fetch data
//   onEdit = () => {},
//   onDelete = () => {},
//   customStyles = {},
// }) => {
//   const [visibleColumns, setVisibleColumns] = useState({});
//   const [dropdownOpen, setDropdownOpen] = useState(false);
//   const [selectedRow, setSelectedRow] = useState(null); // Row selected for modal
//   const [isModalOpen, setIsModalOpen] = useState(false); // Track modal visibility
//   const [loading, setLoading] = useState(false); // Loader state
//   const [tableData, setTableData] = useState(data); // Local state for table data

//   useEffect(() => {
//     // Initialize all columns as visible by default
//     const initialVisibleColumns = {};
//     columns.forEach((col) => {
//       initialVisibleColumns[col.name] = true;
//     });
//     setVisibleColumns(initialVisibleColumns);
//   }, [columns]);

//   // Handle column visibility change
//   const handleColumnVisibilityChange = (e) => {
//     const { name, checked } = e.target;
//     setVisibleColumns((prev) => ({ ...prev, [name]: checked }));
//   };

//   // Dynamically filter columns based on visibility
//   const filteredColumns = columns.map((col) => ({
//     ...col,
//     omit: !visibleColumns[col.name],
//   }));

//   // Open modal with selected row data
//   const handleRowClick = (row) => {
//     setSelectedRow(row);
//     setIsModalOpen(true);
//   };

//   // Close modal
//   const closeModal = () => {
//     setIsModalOpen(false);
//     setSelectedRow(null);
//   };

//   // Refresh data handler
//   const handleRefresh = async () => {
//     setLoading(true); // Show loader
//     try {
//       const updatedData = await fetchData(); // Fetch updated data
//       setTableData(updatedData); // Update local table data
//     } catch (error) {
//       console.error("Error refreshing data:", error);
//     }
//     setLoading(false); // Hide loader
//   };

//   return (
//     <div className='p-4 rounded-xl shadow-2xl  lg:max-w-[93vw] xl:max-w-[94vw]'>
//       <div className='flex justify-between items-center mb-4'>
//         {title && <h2 className='text-md font-semibold'>{title}</h2>}
//         <div className='relative flex items-center space-x-2'>
//           <button
//             onClick={handleRefresh}
//             disabled={loading}
//             className={`bg-gray-500 text-white px-2 py-1 text-sm rounded flex items-center ${
//               loading ? "opacity-50 cursor-not-allowed" : ""
//             }`}
//           >
//             <FaSync className={`mr-2 ${loading ? "animate-spin" : ""}`} />
//             Refresh
//           </button>
//           <div className='relative'>
//             <button
//               onClick={() => setDropdownOpen(!dropdownOpen)}
//               className='bg-gray-500 text-white px-1 py-1 text-sm rounded flex items-center'
//             >
//               Columns
//               <FaCaretDown className='ml-2' />
//             </button>
//             {dropdownOpen && (
//               <div
//                 className='absolute right-0 mt-1 bg-white border rounded shadow-lg p-2 max-h-32 overflow-y-auto z-50'
//                 style={{ top: "100%" }} // Position dropdown below button
//               >
//                 {columns.map((field) => (
//                   <div key={field.name} className='flex items-center mb-1'>
//                     <input
//                       type='checkbox'
//                       name={field.name}
//                       checked={visibleColumns[field.name]}
//                       onChange={handleColumnVisibilityChange}
//                       className='mr-2'
//                     />
//                     <label className='text-sm'>{field.name}</label>
//                   </div>
//                 ))}
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//       <DataTable
//         columns={filteredColumns}
//         data={data}
//         pagination
//         paginationPerPage={10}
//         highlightOnHover
//         striped
//         onRowClicked={handleRowClick}
//         responsive
//         customStyles={{
//           rows: {
//             style: {
//               minHeight: "20px",
//               fontSize: "13px",
//               "&:nth-child(odd)": { backgroundColor: "#f9f9f9" },
//             },
//           },
//           headCells: {
//             style: {
//               display: "flex", // Use flexbox
//               justifyContent: "center", // Horizontally center
//               alignItems: "center", // Vertically center
//               textAlign: "center", // Additional alignment
//               padding: "8px", // Adjust padding as needed
//               fontSize: "13px",
//               backgroundColor: "black", // Background color for header
//               color: "white", // Text color
//               borderBottom: "2px solid #ddd",
//               borderRight: "2px solid #ddd",
//             },
//           },

//           cells: {
//             style: {
//               paddingLeft: "8px",
//               textAlign: "left", // Default alignment is left
//               "&:has(span.digit-cell)": {
//                 textAlign: "right", // Right-align rows starting with digits
//               },
//             },
//           },
//           table: {
//             style: {
//               borderRadius: "8px",
//               overflow: "auto",
//               border: "1px solid #ddd",
//               height: "45vh",
//             },
//           },
//           ...customStyles, // Merge custom styles
//         }}
//         conditionalRowStyles={[
//           {
//             when: (row) => Object.values(row).some((val) => /^\d/.test(val)),
//             style: { textAlign: "right" }, // Right-align rows where data starts with a digit
//           },
//         ]}
//       />
//       {isModalOpen && selectedRow && (
//         <div className='absolute inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50'>
//           <div className='bg-white mt-10 p-4 rounded h-[90vh] shadow-lg w-full lg:max-w-4xl m-2 overflow-y-auto'>
//             <h3 className='text-xl font-bold mb-4'>{title}</h3>
//             {Object.entries(selectedRow).map(([key, value]) => (
//               <div key={key} className='flex justify-between mb-2 border-b'>
//                 <span className='font-semibold uppercase'>{key}:</span>
//                 <span>{value ? value : "No Data"}</span>
//               </div>
//             ))}
//             <button
//               onClick={closeModal}
//               className='bg-red-500 text-white px-4 py-2 rounded mt-4 w-full'
//             >
//               Close
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default DataTableComponent;

import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { FaCaretDown, FaSync } from "react-icons/fa";

const DataTableComponent = ({
  title,
  data = [],
  columns = [],
  fetchData = () => {}, // Function to fetch data
  onEdit = () => {},
  onDelete = () => {},
  customStyles = {},
}) => {
  const [visibleColumns, setVisibleColumns] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null); // Row selected for modal
  const [isModalOpen, setIsModalOpen] = useState(false); // Track modal visibility
  const [loading, setLoading] = useState(false); // Loader state
  const [searchQuery, setSearchQuery] = useState(""); // Search query state
  const [tableData, setTableData] = useState(data); // Local state for table data

  useEffect(() => {
    // Initialize all columns as visible by default
    const initialVisibleColumns = {};
    columns.forEach((col) => {
      initialVisibleColumns[col.name] = true;
    });
    setVisibleColumns(initialVisibleColumns);
  }, [columns]);

  useEffect(() => {
    // Filter data based on the search query
    if (searchQuery.trim() === "") {
      setTableData(data);
    } else {
      const filteredData = data.filter((row) =>
        Object.values(row)
          .join(" ")
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
      setTableData(filteredData);
    }
  }, [searchQuery, data]);

  // Handle column visibility change
  const handleColumnVisibilityChange = (e) => {
    const { name, checked } = e.target;
    setVisibleColumns((prev) => ({ ...prev, [name]: checked }));
  };

  // Dynamically filter columns based on visibility
  const filteredColumns = columns.map((col) => ({
    ...col,
    omit: !visibleColumns[col.name],
  }));

  // Open modal with selected row data
  const handleRowClick = (row) => {
    setSelectedRow(row);
    setIsModalOpen(true);
  };

  // Close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedRow(null);
  };

  // Refresh data handler
  const handleRefresh = async () => {
    setLoading(true); // Show loader
    setSearchQuery("");
    try {
      const updatedData = await fetchData(); // Fetch updated data
      setTableData(updatedData); // Update local table data
    } catch (error) {
      console.error("Error refreshing data:", error);
    }
    setLoading(false); // Hide loader
  };

  return (
    <div className='p-4 rounded-xl shadow-2xl lg:max-w-[93vw] xl:max-w-[94vw]'>
      <div className='flex justify-between items-center mb-4'>
        {title && <h2 className='text-md font-semibold'>{title}</h2>}
        <div className='relative flex items-center space-x-2'>
          <input
            type='text'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder='Search...'
            className='border border-gray-300 rounded px-2 py-1 text-sm focus:outline-none'
          />
          <button
            onClick={handleRefresh}
            disabled={loading}
            className={`bg-gray-500 text-white px-2 py-1 text-sm rounded flex items-center ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            <FaSync className={`mr-2 ${loading ? "animate-spin" : ""}`} />
            Refresh
          </button>
          <div className='relative'>
            <button
              onClick={() => setDropdownOpen(!dropdownOpen)}
              className='bg-gray-500 text-white px-1 py-1 text-sm rounded flex items-center'
            >
              Columns
              <FaCaretDown className='ml-2' />
            </button>
            {dropdownOpen && (
              <div
                className='absolute right-0 mt-1 bg-white border rounded shadow-lg p-2 max-h-32 overflow-y-auto z-50'
                style={{ top: "100%" }} // Position dropdown below button
              >
                {columns.map((field) => (
                  <div key={field.name} className='flex items-center mb-1'>
                    <input
                      type='checkbox'
                      name={field.name}
                      checked={visibleColumns[field.name]}
                      onChange={handleColumnVisibilityChange}
                      className='mr-2'
                    />
                    <label className='text-sm'>{field.name}</label>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <DataTable
        columns={filteredColumns}
        data={tableData} // Use filtered data
        pagination
        paginationPerPage={10}
        highlightOnHover
        striped
        onRowClicked={handleRowClick}
        responsive
        customStyles={{
          rows: {
            style: {
              minHeight: "20px",
              fontSize: "13px",
              cursor: "pointer",
              // "&:nth-child(odd)": { backgroundColor: "#f9f9f9" },
            },
          },
          headCells: {
            style: {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: "8px",
              fontSize: "13px",
              backgroundColor: "black",
              color: "white",
              borderBottom: "2px solid #ddd",
              borderRight: "2px solid #ddd",
            },
          },
          cells: {
            style: {
              paddingLeft: "8px",
              width: "10vw",
              textAlign: "left",
              "nth-child(1)": {
                textAlign: "center", // Center align the first column (e.g., "S No")
              },
            },
          },
          table: {
            style: {
              borderRadius: "8px",
              overflow: "auto",
              border: "1px solid #ddd",
              height: "45vh",
            },
          },

          ...customStyles,
        }}
      />
      {isModalOpen && selectedRow && (
        <div className='absolute inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50'>
          <div className='bg-white mt-10 p-4 rounded h-[90vh] shadow-lg w-full lg:max-w-4xl m-2 overflow-y-auto'>
            <h3 className='text-xl font-bold mb-4'>{title}</h3>
            {Object.entries(selectedRow).map(([key, value]) => (
              <div key={key} className='flex justify-between mb-2 border-b'>
                <span className='font-semibold uppercase'>{key}:</span>
                <span>{value ? value : "No Data"}</span>
              </div>
            ))}
            <button
              onClick={closeModal}
              className='bg-red-500 text-white px-4 py-2 rounded mt-4 w-full'
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DataTableComponent;
