import React, { useState, useEffect } from "react";
import DataTableComponent from "../../../Components/DataTable";
import { FaEdit, FaTrash } from "react-icons/fa";
import axios from "axios";
import { BASE_URL } from "../../Common/apiURL";
import { useLocation } from "react-router-dom";
import {
  getCurrentPageData,
  user,
  selectedBranch,
  SerialKey,
  config,
} from "../../../utils/capitalize";
const BrandLookUp = ({ setFormData, setActiveTab }) => {
  const [data, setData] = useState([]);

  const current = useLocation();
  const currentPage = current.pathname.split("/")[1];
  const targetPage = getCurrentPageData(currentPage);
  const formKey = targetPage?.FormKey;
  const branchCode = selectedBranch?.BranchCode;
  const UserID = user[0]?.UserID;

  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/dashboard/master-lookup-data`,
        {
          FormKey: String(formKey),
          BranchCode: branchCode,
          UserID,
          SerialKey,
        },
        config
      );
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = (row) => {
    setFormData(row);
    setActiveTab("Create");
  };

  const handleDelete = async (row) => {
    // Show confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this record?"
    );

    if (!isConfirmed) {
      return;
    }

    try {
      const defaultValues = {
        SerialKey: SerialKey || "",
        BranchCode: branchCode || "",
        UserID: UserID || "",
        BCode: row.BCode || "",
        BName: row.BName || "",
        BAbbr: row.BAbbr || "",
        BrandCaption: "",
        RefCnt: "3",
      };
      // Call the API to delete the record from the server
      const response = await axios.post(
        `${BASE_URL}/dashboard/brand-master-form-data`,
        defaultValues,
        config
      );

      if (response.data.success) {
        // If delete is successful, update the local data state
        const updatedData = data.filter((item) => item.BCode !== row.BCode); // Correct filter logic
        setData(updatedData);
        alert("Record deleted successfully");
      } else {
        alert("Failed to delete the record");
      }
    } catch (error) {
      console.error("Error deleting record:", error);
      alert("An error occurred while deleting the record");
    }
  };

  const columns = [
    {
      name: "S No",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "80px",
      cell: (row, index) => <div className='text-center'>{index + 1}</div>,
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <FaEdit
            className='text-blue-500 mx-2'
            onClick={() => handleEdit(row)}
          />
          <FaTrash className='text-red-500' onClick={() => handleDelete(row)} />
        </>
      ),
      ignoreRowClick: true,
    },
    {
      name: "Brand",
      selector: (row) => row.BName,
      sortable: true,
    },
    {
      name: "Abbreviation",
      selector: (row) => row.BAbbr,
      sortable: true,
    },
  ];

  return (
    <DataTableComponent
      title={targetPage?.FormName}
      data={data}
      columns={columns}
      onEdit={handleEdit}
      onDelete={handleDelete}
      fetchData={fetchData}
    />
  );
};

export default BrandLookUp;
