import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { transportMasterFormFields } from "../data"; // Ensure correct path
import { useLocation } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../Common/apiURL";
import {
  getCurrentPageData,
  user,
  selectedBranch,
  SerialKey,
  config,
} from "../../../utils/capitalize";

const TransportForm = ({ onSubmit, setActiveTab, formData }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [formFields, setFormFields] = useState(transportMasterFormFields);
  const [formValues, setFormValues] = useState(formData);
  const [executionCount, setExecutionCount] = useState(0);
  const [rateOptions, setRateOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const current = useLocation();
  const currentPage = current.pathname.split("/")[1];
  const targetPage = getCurrentPageData(currentPage);
  const formKey = targetPage?.FormKey;
  const branchCode = selectedBranch?.BranchCode;
  const UserID = user[0]?.UserID;

  useEffect(() => {
    setFormValues(formData);
    if (formData && Object.keys(formData).length > 0) {
      setIsEditing(true);
      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]);
      });
    } else {
      setIsEditing(false);
    }
  }, [formData, setValue]);

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const KeyCode = String(executionCount + 1); // Increment KeyCode with each execution

        const response = await axios.post(
          `${BASE_URL}/dashboard/master-data-list`,
          {
            FormKey: String(formKey),
            BranchCode: branchCode,
            UserID,
            SerialKey,
            KeyCode,
          },
          config
        );
        const data = response.data.data;
        if (KeyCode === "1") {
          const rate = data.map((item) => item.MGName);
          setRateOptions(rate);
        } else if (KeyCode === "2") {
          const categories = data.map((item) => item.StName);
          setCategoryOptions(categories);
        } else {
          console.log(`Additional KeyCode: ${KeyCode}`);
        }
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      }
    };

    if (executionCount < 2) {
      fetchDropdownOptions();
      setExecutionCount((prev) => prev + 1);
    }
  }, [executionCount, formKey, branchCode, UserID, SerialKey]);

  useEffect(() => {
    console.log("Rate Options:", rateOptions);
    console.log("Category Options:", categoryOptions);
    const updatedFields = formFields.map((field) => {
      if (field.type === "dropdown") {
        if (field.name === "group") {
          return { ...field, options: rateOptions };
        }
        if (field.name === "state") {
          return { ...field, options: categoryOptions };
        }
      }
      return field;
    });
    setFormFields(updatedFields);
  }, [rateOptions, categoryOptions]);

  // Render input fields dynamically
  const renderField = (field) => {
    if (!field) return null; // Handle the case where field is undefined

    const { dtName, name, label, type, placeholder, options, validation } =
      field;

    if (type === "text" || type === "email") {
      return (
        <div key={name} className='flex flex-col'>
          <label className='block font-medium'>{label}</label>
          <input
            type={type}
            placeholder={placeholder}
            {...register(name, validation)}
            className='w-full p-1 border rounded'
            defaultValue={formValues[dtName]}
          />
          {errors[name] && (
            <span className='text-red-500 text-sm'>{errors[name].message}</span>
          )}
        </div>
      );
    } else if (type === "dropdown") {
      const selectedValue =
        typeof formValues[dtName] === "number"
          ? options[formValues[dtName]] // Map index to the corresponding option
          : formValues[dtName];

      // Check if the field is 'group' or 'state' and use the corresponding options
      if (name === "group") {
        return (
          <div key={name} className='flex flex-col'>
            <label className='block font-medium'>{label}</label>
            <select
              {...register(name, validation)}
              className='w-full p-1 border rounded'
              value={selectedValue || ""} // Use the selected value
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  [dtName]: e.target.value,
                }))
              }
            >
              <option value=''>Select {label}</option>
              {rateOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {errors[name] && (
              <span className='text-red-500 text-sm'>
                {errors[name].message}
              </span>
            )}
          </div>
        );
      } else if (name === "state") {
        return (
          <div key={name} className='flex flex-col'>
            <label className='block font-medium'>{label}</label>
            <select
              {...register(name, validation)}
              className='w-full p-1 border rounded'
              value={selectedValue || ""} // Use the selected value
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  [dtName]: e.target.value,
                }))
              }
            >
              <option value=''>Select {label}</option>
              {categoryOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {errors[name] && (
              <span className='text-red-500 text-sm'>
                {errors[name].message}
              </span>
            )}
          </div>
        );
      }
      return (
        <div key={name} className='flex flex-col'>
          <label className='block font-medium'>{label}</label>
          <select
            {...register(name, validation)}
            className='w-full p-1 border rounded'
          >
            <option value=''>Select {label}</option>
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          {errors[name] && (
            <span className='text-red-500 text-sm'>{errors[name].message}</span>
          )}
        </div>
      );
    }
    return null;
  };

  // Handle form submission
  const handleFormSubmit = async (data) => {
    console.log("Form Data:", data);

    // Default values structure according to the controller's requirements
    const defaultValues = {
      SerialKey: SerialKey || "",
      BranchCode: branchCode || "",
      UserID: UserID || "",
      TCode: data?.Tcode || "", // Optional: Transport Code
      TName: data?.transportName || "", // Mandatory: Transport Name
      GroupName: data?.group || "", // Optional: Group Name
      Address1: data?.address1 || "", // Mandatory: Address line 1
      Address2: data?.address2 || "", // Optional: Address line 2
      City: data?.city || "", // Mandatory: City
      Mobile: data?.mobileNo || "", // Mandatory: Mobile number
      PAN: data?.pan || "", // Optional: PAN
      GSTIN: data?.gstin || "", // Mandatory: GSTIN
      EmailID: data?.email || "", // Mandatory: Email ID
      State: data?.state || "", // Mandatory: State
      RegType: data?.regType || "", // Mandatory: Registration Type
      WhatsappNo: data?.whatsappNo || "", // Optional: Whatsapp Number
      Inactive: data?.inactive || 0, // Default to 0 (Active)
      TransportStr: data?.agentStr || "", // Optional: Transport String
      RefCnt: !isEditing ? "1" : "2", // Optional: Reference Count, "1" for new, "2" for updates
    };

    try {
      if (onSubmit) {
        const response = await axios.post(
          `${BASE_URL}/dashboard/transport-master-form-data`, // Update the API endpoint if necessary
          defaultValues,
          config
        );

        alert(response.data.message);
        setActiveTab("Lookup"); // Assuming this is part of your UI flow
      }
      reset(); // Reset the form after submission
    } catch (error) {
      console.error("Error while submitting the form:", error);
      alert("An error occurred: ", error);
    }
  };

  return (
    <div className='bg-gray-100 p-3 rounded-xl shadow-lg'>
      <h2 className='text-lg text-center font-semibold mb-4 uppercase'>
        {targetPage?.FormName}
      </h2>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className=' grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4'
      >
        {transportMasterFormFields.map((field) => renderField(field))}
        <div className='col-span-full'>
          <button
            type='submit'
            className='px-4 py-2 text-sm bg-black text-white rounded w-full'
          >
            {isEditing ? "Update" : "Submit"}{" "}
          </button>
        </div>
      </form>
    </div>
  );
};

export default TransportForm;
