// src/AgentMaster.js
import React, { useState } from "react";
import GSTMasterForm from "./GSTMasterForm";
import GSTMasterLookUp from "./GSTMasterLookUp";

const GSTMaster = () => {
  const [activeTab, setActiveTab] = useState("Create");
  const [formData, setFormData] = useState({});

  // Handle tab switching
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setFormData({}); // Clear form data when switching tabs
  };

  // Handle form submission
  const handleFormSubmit = (data) => {
    // Process form data here
    console.log("Submitted Data:", data);
  };

  return (
    <div className='p-3 bg-white '>
      <div className='mb-2 w-full flex gap-5'>
        <button
          className={`px-4 w-1/2 py-2 mr-2 ${
            activeTab === "Create"
              ? "bg-black text-white"
              : "bg-white shadow-xl border font-semibold"
          } rounded`}
          onClick={() => handleTabClick("Create")}
        >
          Create
        </button>
        <button
          className={`px-4 w-1/2 py-2 mr-2 ${
            activeTab === "Lookup"
              ? "bg-black text-white"
              : "bg-white shadow-xl border font-semibold"
          } rounded`}
          onClick={() => handleTabClick("Lookup")}
        >
          Lookup
        </button>
      </div>
      <div>
        {activeTab === "Create" ? (
          <GSTMasterForm
            onSubmit={handleFormSubmit}
            formData={formData}
            setActiveTab={setActiveTab}
          />
        ) : (
          <div className='overflow-auto' style={{ maxHeight: "600px" }}>
            <GSTMasterLookUp
              setFormData={setFormData}
              setActiveTab={setActiveTab}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default GSTMaster;
