import React, { useState, useEffect } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { partyMasterFormFields } from "../data"; // Ensure correct path
import {
  getCurrentPageData,
  user,
  selectedBranch,
  SerialKey,
  config,
} from "../../../utils/capitalize";
import { BASE_URL } from "../../Common/apiURL";
import axios from "axios";
import { useLocation } from "react-router-dom";
import DataTableComponent from "../../../Components/DataTable";
const PartyLookUp = ({ setActiveTab, setFormData }) => {
  const [data, setData] = useState([]);
  const current = useLocation();
  const currentPage = current.pathname.split("/")[1];
  const targetPage = getCurrentPageData(currentPage);
  const formKey = targetPage?.FormKey;
  const branchCode = selectedBranch?.BranchCode;
  const UserID = user[0]?.UserID;

  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/dashboard/master-lookup-data`,
        {
          FormKey: String(formKey),
          BranchCode: branchCode,
          UserID,
          SerialKey,
        },
        config
      );

      const data = response.data.data;
      setData(data);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Edit handler
  const handleEdit = (row) => {
    setFormData({ ...row }); // Set current row data in form
    setActiveTab("Create"); // Switch to the "Create" tab
  };

  // Delete handler
  const handleDelete = async (row) => {
    console.log(row);
    // Show confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this record?"
    );

    if (!isConfirmed) {
      return;
    }

    try {
      const defaultValues = partyMasterFormFields.reduce((acc, field) => {
        const fieldKey = field.dtName;
        acc[fieldKey] = data[field.name] || "";
        return acc;
      }, {});

      defaultValues.SerialKey = SerialKey || "";
      defaultValues.BranchCode = branchCode || "";
      defaultValues.UserID = UserID || "";
      defaultValues.Pcode = row.Pcode || "";
      defaultValues.PName = row.PName || "";
      defaultValues.RefCnt = "3";

      // Call the API to delete the record from the server
      const response = await axios.post(
        `${BASE_URL}/dashboard/party-master-form-data`,
        defaultValues,
        config
      );

      if (response.data.success) {
        // If delete is successful, update the local data state
        const updatedData = data.filter((item) => item.Pcode !== row.Pcode); // Correct filter logic
        setData(updatedData);
        alert("Record deleted successfully");
      } else {
        alert("Failed to delete the record");
      }
    } catch (error) {
      console.error("Error deleting record:", error);
      alert("An error occurred while deleting the record");
    }
  };

  // const columns = [
  //   {
  //     name: "S No",
  //     selector: (row, index) => index + 1, // Generate serial number dynamically
  //     sortable: true,
  //     width: "80px",
  //     cell: (row, index) => <div className='text-center py-2'>{index + 1}</div>,
  //   },
  //   {
  //     name: "Actions",
  //     cell: (row) => (
  //       <>
  //         <FaEdit
  //           className='text-blue-500 mx-2'
  //           onClick={() => handleEdit(row)}
  //         />
  //         <FaTrash className='text-red-500' onClick={() => handleDelete(row)} />
  //       </>
  //     ),
  //     ignoreRowClick: true,
  //   },
  //   {
  //     name: "Address 1",
  //     selector: (row) => row.Address1,
  //     sortable: true,
  //   },
  //   {
  //     name: "Address 2",
  //     selector: (row) => row.Address2,
  //     sortable: true,
  //   },
  //   {
  //     name: "City",
  //     selector: (row) => row.City,
  //     sortable: true,
  //   },
  //   {
  //     name: "District",
  //     selector: (row) => row.District,
  //     sortable: true,
  //   },
  //   {
  //     name: "Email ID",
  //     selector: (row) => row.EMailID,
  //     sortable: true,
  //   },
  //   {
  //     name: "GSTIN",
  //     selector: (row) => row.GSTIN,
  //     sortable: true,
  //   },
  //   {
  //     name: "MG Name",
  //     selector: (row) => row.MGName,
  //     sortable: true,
  //   },
  //   {
  //     name: "Mobile No",
  //     selector: (row) => row.MobileNo,
  //     sortable: true,
  //   },
  //   {
  //     name: "PAN",
  //     selector: (row) => row.PAN,
  //     sortable: true,
  //   },
  //   {
  //     name: "Pincode",
  //     selector: (row) => row.Pincode,
  //     sortable: true,
  //   },
  //   {
  //     name: "Registration Type",
  //     selector: (row) => row.RegType,
  //     sortable: true,
  //   },
  //   {
  //     name: "SMS/WhatsApp No",
  //     selector: (row) => row.SMS_Whatsapp_No,
  //     sortable: true,
  //   },
  //   {
  //     name: "State",
  //     selector: (row) => row.State,
  //     sortable: true,
  //   },
  //   {
  //     name: "Transport Name",
  //     selector: (row) => row.TName,
  //     sortable: true,
  //   },
  //   {
  //     name: "SD",
  //     selector: (row) => row.SD,
  //     sortable: true,
  //   },
  //   {
  //     name: "TD",
  //     selector: (row) => row.TD,
  //     sortable: true,
  //   },
  //   {
  //     name: "CD",
  //     selector: (row) => row.CD,
  //     sortable: true,
  //   },
  // ];

  const columns = [
    {
      name: "S No",
      selector: (row, index) => index + 1, // Generate serial number dynamically
      sortable: true,
      width: "80px",
      cell: (row, index) => <div className='text-center py-2'>{index + 1}</div>,
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <FaEdit
            className='text-blue-500 mx-2'
            onClick={() => handleEdit(row)}
          />
          <FaTrash className='text-red-500' onClick={() => handleDelete(row)} />
        </>
      ),
      ignoreRowClick: true,
    },
    ...partyMasterFormFields.map((field) => ({
      name: field.label,
      selector: (row) => row[field.dtName],
      sortable: true,
      cell: (row) => <div>{row[field.dtName]}</div>,
    })),
  ];

  return (
    <DataTableComponent
      title={targetPage?.FormName}
      data={data}
      columns={columns}
      onEdit={handleEdit}
      onDelete={handleDelete}
      fetchData={fetchData}
    />
  );
};

export default PartyLookUp;
