import React, { useState, useEffect } from "react";
// import Table from "../../components/Datatable/SearchFilterTable";
// import DynamicButton from "../../components/DynamicComponents/DynamicButton";
import InputDropdown from "../../Common/DynamicInputDropdown";
import axios from "axios";
import { BASE_URL } from "../../Common/apiURL";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../Common/Loader";
import { MdGetApp } from "react-icons/md";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DatePicker from "react-datepicker";
import Table from "../Common/SearchFilterTable";
import { dateFormatter } from "../../../utils/capitalize";
import {
  branch,
  formatDate,
  getCurrentPageData,
  user,
} from "../../../utils/capitalize";

const columns = [
  {
    Header: "S No",
    accessor: "SNo",
    Cell: ({ row }) => (
      <div style={{ textAlign: "center" }}>
        {row.original.id === "openingBalance" ||
        row.original.id === "lastBalance"
          ? ""
          : row.index}
      </div>
    ),
    isInitiallyVisible: true,
  },
  {
    Header: "No",
    accessor: "VouNo",
    Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
    isInitiallyVisible: false,
  },
  {
    Header: "Date",
    accessor: "VDate",
    Cell: ({ value }) => {
      const date = new Date(value);
      if (isNaN(date.getTime())) {
        return "";
      }
      const formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}/${date.getFullYear()}`;
      return formattedDate;
    },
    isInitiallyVisible: true,
  },
  {
    Header: "Particulars",
    accessor: "LName",
    Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    isInitiallyVisible: true,
  },
  {
    Header: "Depot",
    accessor: "DpAbbr",
    Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
    isInitiallyVisible: false,
  },
  {
    Header: "Debit",
    accessor: "DrAmt",
    Cell: ({ value }) => (
      <div style={{ textAlign: "right" }}>
        {!isNaN(parseFloat(value)) ? parseFloat(value).toFixed(2) : ""}
      </div>
    ),
    isInitiallyVisible: true,
  },
  {
    Header: "Credit",
    accessor: "CrAmt",
    Cell: ({ value }) => (
      <div style={{ textAlign: "right" }}>
        {!isNaN(parseFloat(value)) ? parseFloat(value).toFixed(2) : ""}
      </div>
    ),
    isInitiallyVisible: true,
  },
  {
    Header: "Balance",
    accessor: "Balance",
    Cell: ({ value }) => (
      <div style={{ textAlign: "right" }}>
        {Math.abs(parseFloat(value)).toFixed(2)}
      </div>
    ),
    isInitiallyVisible: true,
  },
  {
    Header: "",
    accessor: "extra",
    Cell: ({ row }) => {
      const { id, Balance } = row.original;

      // Do not display "Dr" or "Cr" for openingBalance or lastBalance rows
      if (id === "openingBalance" || id === "lastBalance") {
        return null; // or return an empty string "" if you prefer
      }

      const balance = parseFloat(Balance) || 0;
      return (
        <div style={{ textAlign: "center" }}>{balance >= 0 ? "Dr" : "Cr"}</div>
      );
    },
    isInitiallyVisible: true,
  },
  {
    Header: "Remark",
    accessor: "Remark1",
    Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    isInitiallyVisible: false,
  },
];

const Ledger = () => {
  const [filter, setFilter] = useState("");
  const [selectedDate, setSelectedDate] = useState([new Date(), new Date()]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [resultWebPartyList, setResultWebPartyList] = useState([]);
  const [LCode, setLCode] = useState("");
  const [balAmtResult, setBalAmtResult] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState({
    "S No": true,
    No: true,
    Date: true,
    Particulars: true,
    Depot: true,
    Debit: true,
    Credit: true,
    Balance: true,
    "": true,
    Remark: true,
  });

  let visibleColumns = [];
  useEffect(() => {
    visibleColumns = Object.keys(selectedColumns).filter(
      (key) => selectedColumns[key]
    );
    console.log("Visible Columns: ", visibleColumns);
  }, [selectedColumns]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSelectedColumns((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handlePopupOpen = (event) => {
    setShowPopup(true); // Show the popup
    setSelectedColumns({
      "S No": true,
      No: true,
      Date: true,
      Particulars: true,
      Depot: true,
      Debit: true,
      Credit: true,
      Balance: true,
      "": true,
      Remark: true,
    });
  };

  const handleClosePopup = () => {
    setShowPopup(false); // Close the popup
  };

  const handleConfirmDownload = () => {
    handleDownloadAsPDF(selectedColumns);
    setShowPopup(false); // Close the popup
  };

  // Basic Information For API's
  const current = useLocation();
  const currentPage = current.pathname.split("/")[1];
  const targetPage = getCurrentPageData(currentPage);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPartiesData = async () => {
      try {
        const Username = user[0]?.UserID;
        const Depot = user[0]?.DpCode;
        const FormKey = targetPage?.FormKey;
        const BranchCode = branch[0]?.BranchCode;
        const requestBody = { Username, Depot, FormKey, BranchCode };
        console.log(FormKey);

        const config = {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        };

        const response = await axios.post(
          `${BASE_URL}/dashboard/web-master`,
          requestBody,
          config
        );
        if (response.data.success) {
          const resultWebPartyList = response.data.data;
          setResultWebPartyList(resultWebPartyList); // Store the result
          const partyNamesData = resultWebPartyList.map((row) => row.Lname);
          const uniquePartyNames = Array.from(new Set(partyNamesData));
          setDropdownOptions(uniquePartyNames);
        } else {
          console.log("Failed to fetch Parties data");
        }
      } catch (err) {
        console.error("Error fetching Parties data:", err);
      }
    };
    fetchPartiesData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowLoader(true);

    if (!selectedDate || selectedDate.length < 2) {
      alert("Please select both start and end dates.");
      setShowLoader(false);
      return;
    }

    const [startDate, endDate] = selectedDate;

    // Validate Dates
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      alert("Invalid date selected.");
      setShowLoader(false);
      return;
    }

    try {
      const Username = user[0]?.UserID;
      const Depot = user[0]?.DpCode;
      const FormKey = targetPage?.FormKey;
      const BranchCode = branch[0]?.BranchCode;
      const PartyCode = LCode;
      const StartDate = formatDate(startDate);
      const EndDate = formatDate(endDate);
      console.log(LCode);
      const requestBody = {
        Username,
        Depot,
        FormKey,
        BranchCode,
        PartyCode,
        StartDate,
        EndDate,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      };

      const response = await axios.post(
        `${BASE_URL}/dashboard/ledger`,
        requestBody,
        config
      );

      const balAmtResult = response.data.balAmtResult;
      const ledgerDataResult = response.data.ledgerDataResult.map(
        (row, index) => ({
          ...row,
          id: index + 1,
        })
      );
      // Calculate balance dynamically
      let runningBalance = parseFloat(balAmtResult[0]?.BalAmt) || 0;
      let totalDebit = 0;
      let totalCredit = 0;
      const updatedLedgerData = ledgerDataResult.map((row) => {
        const debit = parseFloat(row.DrAmt) || 0;
        const credit = parseFloat(row.CrAmt) || 0;
        totalDebit += debit;
        totalCredit += credit;
        runningBalance += debit - credit;
        return {
          ...row,
          Balance: runningBalance.toFixed(2),
        };
      });
      // Add Opening Balance row
      const openingBalanceRow = {
        id: "openingBalance",
        VouNo: "",
        VDate: "",
        LName: "Opening Balance",
        DpAbbr: "",
        DrAmt: "",
        CrAmt: "",
        extra:
          balAmtResult[0]?.BalAmt > 0
            ? "Dr"
            : balAmtResult[0]?.BalAmt < 0
            ? "Cr"
            : "-", // Adjusting to show "Dr", "Cr", or empty
        Balance: balAmtResult?.[0]?.BalAmt
          ? balAmtResult[0]?.BalAmt.toFixed(2)
          : "0.00",
        Remark1: "",
      };

      // Add Last Balance row with totals
      const lastBalanceRow = {
        id: "lastBalance",
        VouNo: "",
        VDate: "",
        LName: "Last Balance",
        DpAbbr: "",
        DrAmt: totalDebit.toFixed(2),
        CrAmt: totalCredit.toFixed(2),
        Balance: runningBalance.toFixed(2),
        Remark1: "",
        extra: runningBalance >= 0 ? "Dr" : "Cr", // Fixed assignment for the Type
      };

      // Update table data with opening and last balance rows
      const combinedData = [
        openingBalanceRow,
        ...updatedLedgerData,
        lastBalanceRow,
      ];

      // Check if ledgerDataResult is empty
      if (ledgerDataResult.length === 0) {
        setTableData([openingBalanceRow]); // Display only opening balance row if no data available
      } else {
        setTableData(combinedData);
        setBalAmtResult(balAmtResult);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    document.title = "PTAC ERP || Ledger";

    let timer = setTimeout(() => {
      setShowLoader(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  const handleFilterChange = (value) => {
    if (!value) {
      console.error("Selected value is undefined");
      return;
    }

    setFilter(value);
    const selectedItem = resultWebPartyList.find(
      (item) => item.Lname === value
    );
    if (selectedItem) {
      setLCode(selectedItem.LCode);
    } else {
      console.log("Party not found in the list");
    }
    setTableData([]); // Clear table data
  };

  const handleDateChange = (dates) => {
    setSelectedDate(dates);
    setTableData([]); // Clear table data
  };

  const handleDownloadAsPDF = async (selectedColumns) => {
    console.log(selectedColumns);
    // Initialize jsPDF
    const doc = new jsPDF({
      orientation: "portrait",
    });
    const logoUrl =
      "https://img.freepik.com/free-photo/colorful-design-with-spiral-design_188544-9588.jpg"; // Your logo URL

    // Convert Image URL to Base64
    const convertToBase64 = async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    };

    try {
      // Convert logo to Base64
      const logoBase64 = await convertToBase64(logoUrl);
      const logoX = 15;
      const logoY = 10;
      const logoWidth = 25;
      const logoHeight = 25;
      doc.addImage(logoBase64, "JPEG", logoX, logoY, logoWidth, logoHeight);
    } catch (error) {
      console.error("Error loading logo:", error);
      doc.text("Logo Error", 10, 30);
    }

    // Define table headers
    const headers = columns
      .filter(
        (column) =>
          column.Header !== "S No" &&
          column.Header !== "Depot" &&
          column.Header !== "No"
      )
      .map((column) => column.Header);

    columns.forEach((column) => {
      column.align = "left";
    });

    const tableColumns = Object.keys(selectedColumns).filter(
      (column) => selectedColumns[column]
    );

    const tableRows = tableData.map((row, index) => {
      return tableColumns.map((header) => {
        switch (header) {
          case "S No":
            return row.id === "openingBalance" || row.id === "lastBalance"
              ? ""
              : index + 1;
          case "No":
            return row.No;
          case "Date":
            return dateFormatter(row.VDate);
          case "Particulars":
            return row.LName;
          case "Depot":
            return row.DpAbbr;
          case "Debit":
            return parseFloat(row.DrAmt)
              ? parseFloat(row.DrAmt).toFixed(2)
              : "0.00"; // Format Debit to 2 decimal places and convert to string
          case "Credit":
            return parseFloat(row.CrAmt)
              ? parseFloat(row.CrAmt).toFixed(2)
              : "0.00"; // Format Credit to 2 decimal places and convert to string
          case "Balance":
            return Math.abs(row.Balance).toFixed(2);
          case "":
            return row.Balance >= 0 ? "Dr" : "Cr";
          case "Remark":
            return row.Remark1;
          default:
            return row[header];
        }
      });
    });

    const title = "Ledger";
    const startDate = selectedDate[0];
    const endDate = selectedDate[1];
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Adding 1 because January is 0
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedStartDate = `${day}-${month}-${year}`;

    const center = doc.internal.pageSize.width / 2;
    const rightPosition = doc.internal.pageSize.width - 15; // Adjust as needed
    // Bold
    // Add Company Details and Report Name
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);
    doc.text("SRS Softwares", 105, 15, { align: "center" });
    doc.setFont("helvetica", "semibold");
    doc.setFontSize(10);
    doc.text("Address Line 1", 105, 20, { align: "center" });
    doc.text("Address Line 2", 105, 25, { align: "center" });
    doc.text("GSTIN No: XXXXXXXXXXX", 105, 29, { align: "center" });
    doc.setFontSize(12);
    doc.text(title, center, 34, { align: "center" });
    doc.setFont("helvetica", "bold");
    doc.setFontSize(11);
    doc.text(filter, center, 38, { align: "center" });
    doc.setFontSize(10);
    doc.text(
      `${dateFormatter(startDate)} to ${dateFormatter(endDate)}`,
      center,
      42,
      {
        align: "center",
      }
    );
    doc.setFontSize(10);
    doc.text(`Date : ${formattedStartDate}`, rightPosition, 25, {
      align: "right",
    });

    const quarterHeight = doc.internal.pageSize.height / 7;
    const formattedDate = currentDate.toLocaleDateString();
    const formattedTime = currentDate.toLocaleTimeString();
    // Add table to PDF
    doc.autoTable({
      startY: 45,
      margin: { left: 10, right: 10 },
      theme: "plain",
      head: [tableColumns],
      body: tableRows,
      styles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.5,
      },
      headStyles: {
        fillColor: [211, 211, 211],
        textColor: [0, 0, 0],
        fontStyle: "bold",
        lineColor: [0, 0, 0],
        lineWidth: 0.5,
        halign: "center",
      },
      columnStyles: {
        0: { cellWidth: 15, halign: "center" },
        2: { halign: "center" },
        5: { halign: "right" },
        6: { halign: "right" },
        7: { halign: "right" },
      },
      bodyStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
      },

      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.5,

      didParseCell: (data) => {
        const column = data.column.dataKey;
        const rowIndex = data.row.index;
        const totalRows = data.table.body.length;
        const cell = data.cell;
        // Set first and last row background color to gray-50 (#F9FAFB)
        // if (data.section === "body") {
        //   if (rowIndex === 0) {
        //     data.cell.styles.fillColor = [249, 250, 251]; // gray-50
        //     data.cell.styles.textColor = [0, 0, 0]; // gray-50
        //   } else if (rowIndex === totalRows - 1) {
        //     // Set all other rows background to white
        //     data.cell.styles.fillColor = [249, 250, 251]; // gray-50
        //     data.cell.styles.textColor = [0, 0, 0]; // gray-50
        //   } else {
        //     data.cell.styles.fillColor = [255, 255, 255]; // gray-50
        //   }
        // }
        console.log(cell.raw);
        if (
          cell.raw === "Debit" ||
          cell.raw === "Credit" ||
          cell.raw === "Balance"
        ) {
          data.cell.styles.halign = "right"; // Align Debit, Credit, and Balance columns to the right
        }
        if (rowIndex === 0 || rowIndex === totalRows - 1) {
          data.cell.styles.fontStyle = "bold"; // Set text to bold for the Balance column
        }
      },
      didDrawPage: (data) => {
        // Footer logic (unchanged)
        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");
        // Add Footer with Time and Date of Printing
        const pageHeight = doc.internal.pageSize.height;
        const pageWidth = doc.internal.pageSize.width;
        doc.text(
          `Printed On: ${formattedDate}, ${formattedTime}`,
          pageWidth - 10,
          pageHeight - 10,
          { align: "right" }
        );
      },
    });

    // Save PDF
    doc.save("Ledger.pdf");
  };

  return (
    <div className='p-3'>
      {showLoader && <Loader />}
      <div className='flex flex-col gap-1 mb-1'>
        <h1 className='text-sm font-semibold mb-4'>{targetPage.FormName}</h1>
        <form onSubmit={handleSubmit} className=''>
          <div className='grid grid-cols-2 lg:grid-cols-4 gap-2 items-end'>
            <InputDropdown
              options={dropdownOptions}
              value={filter}
              onChange={(value) => handleFilterChange(value)}
              label='Party Name'
              isDisabled={false}
            />
            <div>
              <label className='block text-sm font-medium'>Start Date</label>
              <DatePicker
                selected={selectedDate[0]}
                onChange={(date) => handleDateChange([date, selectedDate[1]])}
                dateFormat='dd/MM/yyyy'
                maxDate={new Date()}
                className='w-full p-1 border rounded text-xs'
              />
            </div>

            <div>
              <label className='block text-sm font-medium'>End Date</label>
              <DatePicker
                selected={selectedDate[1]}
                onChange={(date) => handleDateChange([selectedDate[0], date])}
                dateFormat='dd/MM/yyyy'
                minDate={selectedDate[0]}
                maxDate={new Date()}
                className='w-full p-1 border rounded text-xs'
              />
            </div>
            <div className='flex justify-end '>
              <div className='w-full flex justify-end'>
                <button
                  type='button'
                  onClick={handleSubmit}
                  className='px-4 py-2 text-xs w-full mt-2 bg-green-600 text-white rounded'
                >
                  Show
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Table columns={columns} data={tableData} />
      {showPopup && (
        <div className='fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50'>
          <div className='bg-white p-3 rounded shadow-lg w-80'>
            <h2 className='text-xl font-semibold mb-2 text-center'>
              Download PDF
            </h2>
            <div className='flex flex-col mb-4'>
              <label className='block text-sm mb-3 font-semibold'>
                Select Columns
              </label>
              <div className='flex flex-col'>
                {columns.map((column) => (
                  <label
                    key={column.Header}
                    className='flex items-center text-sm'
                  >
                    <input
                      type='checkbox'
                      name={column.Header}
                      checked={selectedColumns[column.Header]}
                      onChange={handleCheckboxChange}
                      className='mr-2'
                    />
                    {column.Header}
                  </label>
                ))}
              </div>
            </div>
            <div className='flex justify-end mt-4 space-x-4'>
              <button
                onClick={handleClosePopup}
                className='px-4 py-2 bg-red-500 text-white rounded'
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDownload}
                className='px-4 py-2 bg-blue-500 text-white rounded'
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      {tableData.length > 0 && (
        <div className='p-2 w-full flex gap-2'>
          <button
            onClick={handlePopupOpen}
            className='font-semibold text-white p-1 rounded-md bg-[#4FA9A7] w-full'
          >
            <MdGetApp
              size={20}
              className='m-auto text-red-600 hover:text-white-100'
            />
            Download Pdf
          </button>
        </div>
      )}
    </div>
  );
};

export default Ledger;
