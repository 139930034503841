import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { FaEdit, FaTrash, FaCaretDown } from "react-icons/fa";
import { agentMasterFormFields, initialData } from "../data"; // Ensure correct path
import {
  getCurrentPageData,
  user,
  selectedBranch,
  SerialKey,
  config,
} from "../../../utils/capitalize";
import { BASE_URL } from "../../Common/apiURL";
import axios from "axios";
import { useLocation } from "react-router-dom";
import DataTableComponent from "../../../Components/DataTable";

const AgentLookUp = ({ setFormData, setActiveTab }) => {
  const [data, setData] = useState(initialData);

  const current = useLocation();
  const currentPage = current.pathname.split("/")[1];
  const targetPage = getCurrentPageData(currentPage);
  const formKey = targetPage?.FormKey;
  const branchCode = selectedBranch?.BranchCode;
  const UserID = user[0]?.UserID;

  // Fetch Data
  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/dashboard/master-lookup-data`,
        {
          FormKey: String(formKey),
          BranchCode: branchCode,
          UserID,
          SerialKey,
        },
        config
      );

      const data = response.data.data;
      console.log(data);
      setData(data);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Edit handler
  const handleEdit = (row) => {
    setFormData(row);
    setActiveTab("Create");
  };

  // Delete handler
  const handleDelete = async (row) => {
    console.log(row);
    // Show confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this record?"
    );

    if (!isConfirmed) {
      return;
    }

    try {
      const defaultValues = {
        SerialKey: SerialKey || "", // Mandatory: Serial Key of the request
        BranchCode: branchCode || "", // Mandatory: Branch Code
        UserID: UserID || "", // Mandatory: User ID initiating the request
        ACode: row?.Acode || "", // Optional: Agent Code
        AName: row?.AName || "", // Mandatory: Agent Name
        GroupName: row?.group || "", // Optional: Group (Dropdown)
        Address1: row?.address1 || row?.Address1 || "", // Mandatory: Address line 1
        Address2: row?.address2 || row?.Address2 || "", // Optional: Address line 2
        City: row?.city || row?.City || "", // Mandatory: City
        Mobile: row?.mobile || row?.Mobile || "", // Mandatory: Mobile number
        PAN: row?.pan || row?.PAN || "", // Optional: PAN
        GSTIN: row?.gstin || row?.GSTIN || "", // Mandatory: GSTIN
        EmailID: row?.email || row?.EmailID || "", // Mandatory: Email
        State: row?.state || row?.State || "", // Mandatory: State
        RegType: row?.regType || row?.RegType || "", // Mandatory: Registration Type
        Abbr: row?.abbr || row?.Abbr || "", // Optional: Abbreviation
        WhatsappNo: row?.whatsapp || row?.WhatsappNo || "", // Optional: Whatsapp No
        Commission: row?.commission || row?.Commission || "", // Mandatory: Commission rate
        Inactive: row?.inactive || row?.Inactive || "", // Optional: Inactive status (can be added if needed)
        AgentStr: row?.agentStr || row?.AgentStr || "", // Optional: Agent String (can be added if needed)
        RefCnt: "3", // Optional: Reference Count, "1" for new, "2" for updates
      };
      // Call the API to delete the record from the server
      const response = await axios.post(
        `${BASE_URL}/dashboard/agent-master-form-data`,
        defaultValues,
        config
      );

      if (response.data.success) {
        // If delete is successful, update the local data state
        const updatedData = data.filter((item) => item.Acode !== row.Acode); // Correct filter logic
        setData(updatedData);
        alert("Record deleted successfully");
      } else {
        alert("Failed to delete the record");
      }
    } catch (error) {
      console.error("Error deleting record:", error);
      alert("An error occurred while deleting the record");
    }
  };

  // Dynamically create columns based on form fields and visibility
  const columns = [
    {
      name: "S No",
      selector: (row, index) => index + 1, // Generate serial number dynamically
      sortable: true,
      width: "80px",
      cell: (row, index) => <div className='text-center py-2'>{index + 1}</div>,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className='flex justify-center items-center justify-items-center'>
          <FaEdit
            className='text-blue-500 mx-2'
            onClick={() => handleEdit(row)}
          />
          <FaTrash className='text-red-500' onClick={() => handleDelete(row)} />
        </div>
      ),
      ignoreRowClick: true,
    },
    {
      name: "Agent Name",
      selector: (row) => row.AName,
      sortable: true,
    },

    {
      name: "Address 1",
      selector: (row) => row.Address1,
      sortable: true,
    },
    {
      name: "Address 2",
      selector: (row) => row.Address2,
      sortable: true,
    },
    {
      name: "Agent Abbreviation",
      selector: (row) => row.AgentAbbr,
      sortable: true,
    },
    {
      name: "City",
      selector: (row) => row.City,
      sortable: true,
    },
    {
      name: "Email ID",
      selector: (row) => row.EMailID,
      sortable: true,
    },
    {
      name: "GSTIN",
      selector: (row) => row.GSTIN,
      sortable: true,
    },
    {
      name: "MG Name",
      selector: (row) => row.MGName,
      sortable: true,
    },
    {
      name: "Mobile No",
      selector: (row) => row.MobileNo,
      sortable: true,
    },
    {
      name: "PAN",
      selector: (row) => row.PAN,
      sortable: true,
    },
    {
      name: "Registration Type",
      selector: (row) => row.RegType,
      sortable: true,
    },
    {
      name: "RO Commission",
      selector: (row) => row.RoComm,
      sortable: true,
    },
    {
      name: "SMS/WhatsApp No",
      selector: (row) => row.SMS_Whatsapp_No,
      sortable: true,
    },
    {
      name: "State",
      selector: (row) => row.State,
      sortable: true,
    },
  ];

  return (
    <DataTableComponent
      title={targetPage?.FormName}
      data={data}
      columns={columns}
      onEdit={handleEdit}
      onDelete={handleDelete}
      fetchData={fetchData}
    />
  );
};

export default AgentLookUp;
