import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../Common/Loader"; // Ensure you have a Loader component
import axios from "axios";
import { BASE_URL } from "../../Common/apiURL";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "../../Common/SearchFilterTable";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { dateFormatter } from "../../../utils/capitalize";
import {
  branch,
  formatDate,
  getCurrentPageData,
  user,
} from "../../../utils/capitalize";

const groupDataByDocID = (data) => {
  let groupedData = [];
  let currentDocID = null;
  let serialNumber = 0; // Initialize a serial number

  data.forEach((item) => {
    if (item.DocID !== currentDocID) {
      // Reset the serial number for each new DocID
      serialNumber = 1; // Start numbering from 1 for new group
      groupedData.push({
        isGroupHeader: true,
        DocID: item.DocID, // Optionally store the DocID for grouping purposes
      });
      currentDocID = item.DocID;
    } else {
      // Increment the serial number for same DocID
      serialNumber++;
    }

    // Add the actual data row with the serial number
    groupedData.push({ ...item, serialNumber });
  });

  return groupedData;
};

const DebtorsList = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showData, setShowData] = useState(false); // To control table display
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const [showLoader, setShowLoader] = useState(false); // To control loader display
  const [selectedFilter, setSelectedFilter] = useState(1); // Track selected filter type, initial value "0" for All
  const [showPopup, setShowPopup] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState({
    "S No": true,
    "Particular/Name": true,
    Amount: true,
    "": true,
  });
  let visibleColumns = [];
  useEffect(() => {
    visibleColumns = Object.keys(selectedColumns).filter(
      (key) => selectedColumns[key]
    );
    console.log("Visible Columns: ", visibleColumns);
  }, [selectedColumns]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSelectedColumns((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handlePopupOpen = (event) => {
    setShowPopup(true); // Show the popup
    setSelectedColumns({
      "S No": true,
      "Particular/Name": true,
      Amount: true,
      "": true,
    });
  };

  const handleClosePopup = () => {
    setShowPopup(false); // Close the popup
  };

  const handleConfirmDownload = () => {
    handleDownloadAsPDF(selectedColumns);
    setShowPopup(false); // Close the popup
  };
  const handleDownloadAsPDF = async (selectedColumns) => {
    // const doc = new jsPDF();
    const doc = new jsPDF({
      orientation: "portrait", // 'portrait' or 'landscape'
      unit: "mm",
      format: "a4",
    });

    const logoUrl =
      "https://img.freepik.com/free-photo/colorful-design-with-spiral-design_188544-9588.jpg"; // Your logo URL

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();
    const formattedTime = currentDate.toLocaleTimeString();

    // Convert Image URL to Base64
    const convertToBase64 = async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    };

    try {
      // Convert logo to Base64
      const logoBase64 = await convertToBase64(logoUrl);
      const logoX = 15;
      const logoY = 10;
      const logoWidth = 25;
      const logoHeight = 25;
      doc.addImage(logoBase64, "JPEG", logoX, logoY, logoWidth, logoHeight);
    } catch (error) {
      console.error("Error loading logo:", error);
      doc.text("Logo Error", 10, 30);
    }
    const center = doc.internal.pageSize.width / 2;
    const rightPosition = doc.internal.pageSize.width - 15; // Adjust as needed

    // Add Company Details and Report Name
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);
    doc.text("SRS Softwares", center, 15, { align: "center" });
    doc.setFont("helvetica", "semibold");
    doc.setFontSize(10);
    doc.text("Address Line 1", center, 20, { align: "center" });
    doc.text("Address Line 2", center, 25, { align: "center" });
    doc.text("GSTIN No: XXXXXXXXXXX", center, 29, { align: "center" });
    doc.setFontSize(12);
    doc.text("Debtors List", center, 34, { align: "center" });
    // Add Date on the Top Right
    doc.setFontSize(10);
    doc.text(`Date: ${dateFormatter(endDate)}`, rightPosition, 20, {
      align: "right",
    });

    // Define table columns based on selectedColumns
    const tableColumns = Object.keys(selectedColumns).filter(
      (column) => selectedColumns[column]
    );

    // Filter rows based on selected columns
    const tableRows = rows.map((row, index) => {
      console.log(row);
      return tableColumns.map((column) => {
        if (column === "S No") return row.isTotalRow === true ? "" : index + 1;
        if (column === "Particular/Name") return row.Particulars;
        if (column === "Amount") return row.Amount?.toFixed(2);
        if (column === "") return row.extra;
        return "";
      });
    });

    // Add table to PDF
    doc.autoTable({
      head: [tableColumns],
      body: tableRows,
      startY: 40,
      margin: { left: 10, right: 10 },
      theme: "plain",
      styles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.5,
      },
      headStyles: {
        fillColor: [211, 211, 211],
        textColor: [0, 0, 0],
        fontStyle: "bold",
        lineColor: [0, 0, 0],
        lineWidth: 0.5,
        halign: "center",
      },
      bodyStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
      },
      columnStyles: {
        0: { cellWidth: 15, halign: "center" },
        2: { halign: "right" },
      },
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.5,
    });

    // Add Footer with Time and Date of Printing
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;
    doc.text(
      `Printed On: ${formattedDate}, ${formattedTime}`,
      pageWidth - 10,
      pageHeight - 10,
      { align: "right" }
    );

    // Save the PDF
    doc.save("Debtors-List.pdf");
  };
  const current = useLocation();
  const currentPage = current.pathname.split("/")[1];
  const targetPage = getCurrentPageData(currentPage);

  const [rows, setRows] = useState([]);

  // Handle the show button click
  const handleShowData = async (e) => {
    e.preventDefault();

    const Username = user[0]?.UserID;
    const Depot = user[0]?.DpCode;
    const FormKey = targetPage?.FormKey;
    const BranchCode = branch[0]?.BranchCode;

    const requestBody = {
      Depot,
      BranchCode,
      Username,
      FormKey,
      PartyName: "", // Assuming party name is not needed as we are filtering by agent
      AgentName: "",
      ItemGroup: "",
      ItemName: "",
      Date1: formatDate(startDate),
      Date2: formatDate(endDate),
      RptCode: selectedFilter,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      },
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/dashboard/web-reports`,
        requestBody,
        config
      );
      console.log(response.data.data);
      const AllDebtors = response.data.data.map((debtor) => ({
        ...debtor,
        Amount: Math.abs(parseFloat(debtor.Amount)),
        extra: parseFloat(debtor.Amount) > 0 ? "Dr" : "Cr",
      }));
      setRows([...AllDebtors, calculateTotalRow(AllDebtors)]);
    } catch (error) {
      alert("Something went wrong!!!", error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleFilterChange = (event) => {
    const value = event.target.value;
    setSelectedFilter(value);
    setRows([]);
  };

  const calculateTotalRow = (rows) => {
    const totalAmount = rows.reduce((sum, row) => {
      return (
        sum +
        (row.extra === "Dr" ? parseFloat(row.Amount) : -parseFloat(row.Amount))
      );
    }, 0);
    return {
      Particulars: "Total",
      Amount: Math.abs(totalAmount.toFixed(2)),
      extra: totalAmount > 0 ? "Dr" : "Cr",
      isTotalRow: true, // Add a flag to identify the total row
    };
  };

  const columns = [
    {
      Header: "S No",
      Cell: ({ row }) => (
        <div style={{ textAlign: "center" }}>
          {row.original.isTotalRow ? "" : row.index + 1}
        </div>
      ),
      accessor: "SNo",
      isInitiallyVisible: true,
    },
    {
      Header: "Particular/Name",
      accessor: "Particulars",
      isInitiallyVisible: true,
    },
    {
      accessor: "Amount",
      Header: "Amount",
      Cell: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          {typeof value === "number" ? value.toFixed(2) : value}
        </div>
      ),
      isInitiallyVisible: true,
    },
    {
      Header: "",
      accessor: "extra",
      Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
      isInitiallyVisible: true,
    },
  ];

  const filterOptions = [
    { CtCode: "0", CtName: "Agentwise" },
    { CtCode: "1", CtName: "Partywise" },
    { CtCode: "2", CtName: "Citywise" },
    { CtCode: "3", CtName: "Statewise" },
  ];
  console.log(rows);
  return (
    <div className='p-5'>
      {isLoading && <Loader />} {/* Show loader when loading */}
      <h2 className='text-sm font-semibold mb-4'>{targetPage?.FormName}</h2>
      <form className='grid grid-cols-3 gap-2'>
        <div>
          <label className='block font-medium'>Filter</label>
          <select
            value={selectedFilter}
            onChange={handleFilterChange}
            className='w-full p-1 border rounded text-xs'
          >
            {filterOptions.map((category, index) => (
              <option key={index} value={category.CtCode}>
                {category.CtName}
              </option>
            ))}
          </select>
        </div>

        <div className='flex flex-col'>
          <label className='block font-medium text-sm'>Date</label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            className='w-full p-1 border rounded text-xs'
            dateFormat='dd/MM/yyyy'
            maxDate={new Date()}
          />
        </div>

        {/* Show Button */}
        <div className='w-full'>
          <button
            type='button'
            onClick={handleShowData}
            className='px-4 py-2 text-xs bg-blue-600 text-white rounded w-full mt-4'
          >
            Show
          </button>
        </div>
      </form>
      {/* Conditionally render the Stock Summary Table */}
      <div className='mt-5'>
        <Table columns={columns} data={rows} />
      </div>
      {showPopup && (
        <div className='fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50'>
          <div className='bg-white p-3 rounded shadow-lg w-80'>
            <h2 className='text-xl font-semibold mb-2 text-center'>
              Download PDF
            </h2>
            <div className='flex flex-col mb-4'>
              <label className='block text-sm mb-3 font-semibold'>
                Select Columns
              </label>
              <div className='flex flex-col'>
                {columns.map((column) => (
                  <label
                    key={column.Header}
                    className='flex items-center text-sm'
                  >
                    <input
                      type='checkbox'
                      name={column.Header}
                      checked={selectedColumns[column.Header]}
                      onChange={handleCheckboxChange}
                      className='mr-2'
                    />
                    {column.Header}
                  </label>
                ))}
              </div>
            </div>
            <div className='flex justify-end mt-4 space-x-4'>
              <button
                onClick={handleClosePopup}
                className='px-4 py-2 bg-red-500 text-white rounded'
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDownload}
                className='px-4 py-2 bg-blue-500 text-white rounded'
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      {rows.length > 0 && (
        <div className='mt-4'>
          <button
            onClick={handlePopupOpen}
            className='px-4 py-1 text-sm bg-green-600 text-white rounded w-full'
          >
            Download PDF
          </button>
        </div>
      )}
    </div>
  );
};

export default DebtorsList;
