import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FaHome,
  FaFileAlt,
  FaInfoCircle,
  FaChartBar,
  FaExchangeAlt,
  FaDatabase,
} from "react-icons/fa";
import axios from "axios";
import Cookies from "js-cookie";
import { FiChevronDown, FiChevronUp, FiLogOut } from "react-icons/fi";
import { MdAccountBalanceWallet } from "react-icons/md";
import { TbReportSearch } from "react-icons/tb";
import { BASE_URL } from "../Pages/Common/apiURL";
import { RiAdminFill } from "react-icons/ri";
import LogoURL from "../assets/PTACLogo.jpg";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openedSubmenu, setOpenedSubmenu] = useState(null);
  const [openedSubSubmenu, setOpenedSubSubmenu] = useState(null);
  const [menuData, setMenuData] = useState([]);
  const [userInfo, setUserInfo] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve menu data and user info from localStorage
    const storedMenuData = localStorage.getItem("Menu");
    const storedUserInfo = localStorage.getItem("userInfo");

    // Parse and set menu data
    if (storedMenuData && storedMenuData !== "undefined") {
      try {
        setMenuData(JSON.parse(storedMenuData));
      } catch (error) {
        console.error("Error parsing menu data:", error);
        setMenuData([]); // Set empty array if parsing fails
      }
    }

    // Parse and set user info
    if (storedUserInfo && storedUserInfo !== "undefined") {
      try {
        setUserInfo(JSON.parse(storedUserInfo));
      } catch (error) {
        console.error("Error parsing user info:", error);
        setUserInfo(null); // Set null if parsing fails
      }
    }
  }, []);

  // const logo = userInfo ? userInfo[0].Logo;
  const logo = LogoURL;

  const groupedMenu = menuData.reduce((acc, curr) => {
    if (!acc[curr.FGroup]) {
      acc[curr.FGroup] = [];
    }
    acc[curr.FGroup].push(curr);
    return acc;
  }, {});

  const handleSubmenuToggle = (fGroup) => {
    setOpenedSubmenu((prevState) => (prevState === fGroup ? null : fGroup)); // Toggle submenu state
  };

  const getRandomColor = () => {
    const colors = [
      "bg-blue-100",
      "bg-green-100",
      "bg-yellow-100",
      "bg-red-100",
      "bg-purple-100",
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const handleSidebarOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`hidden lg:block fixed overflow-y-auto  bg-[#ffffff]  text-white transition-width duration-700 mb-2 cursor-pointer sidebar ${
        isOpen
          ? "w-[15%] shadow-2xl my-[2.9%] p-2 rounded-lg-r h-[97vh]"
          : "w-[4%] shadow-2xl h-screen"
      }`}
      style={{ zIndex: 50, position: "fixed" }} // Ensure the navbar is on top
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => {
        // Add a timeout to ensure the submenu is only closed after the cursor leaves the entire navbar
        setTimeout(() => {
          setIsOpen(false);
          setOpenedSubmenu(null); // Optionally close all open submenus
        }, 200); // Adjust the delay as needed
      }}
    >
      <div className='flex items-center justify-center p-1'>
        <Link to='/dashboard'>
          <img
            src={logo}
            alt='Logo'
            className={`p-1 ${
              !isOpen ? "mt-5 mb-10" : "w-[150px] "
            } rounded-xl`}
          />
        </Link>
      </div>

      <div className='p-2 mt-5 cursor-pointer overflow-y-auto '>
        {Object.entries(groupedMenu).map(([fGroup, menuItems]) => (
          <ul key={fGroup}>
            <li
              className={`text-black  text-md relative p-1 bg-white mb-5 rounded-md overflow-y-auto shadow-2xl hover:${getRandomColor()} `}
              // onClick={handleSidebarOpen}
              onClick={() => handleSubmenuToggle(fGroup)}
              // onMouseEnter={() => handleSubmenuToggle(fGroup)}
              // onMouseLeave={() => {
              //   // Optionally handle submenu closing here if needed
              //   setOpenedSubmenu(null);
              // }}
            >
              {isOpen && (
                <button className='text-black ml-2 mt-4 absolute top-0 right-0 transform -translate-y-1/2'>
                  {openedSubmenu === null || openedSubmenu !== fGroup ? (
                    <FiChevronDown />
                  ) : (
                    <FiChevronUp />
                  )}
                </button>
              )}
              {!isOpen && (
                <div className='flex items-center justify-center gap-2'>
                  {fGroup === "1" && (
                    <FaDatabase size={20} style={{ color: "#4CAF50" }} />
                  )}
                  {fGroup === "2" && (
                    <FaExchangeAlt size={20} style={{ color: "#FF9800" }} />
                  )}
                  {fGroup === "3" && (
                    <FaChartBar size={20} style={{ color: "#2196F3" }} />
                  )}
                  {fGroup === "4" && (
                    <TbReportSearch size={20} style={{ color: "#9C27B0" }} />
                  )}
                  {fGroup === "6" && (
                    <MdAccountBalanceWallet
                      size={20}
                      style={{ color: "#000" }}
                    />
                  )}
                  {fGroup === "11" && (
                    <RiAdminFill size={20} style={{ color: "#F44336" }} />
                  )}
                </div>
              )}
              <div
                className={`flex items-center transition-opacity duration-300 ${
                  isOpen ? "opacity-100" : "opacity-0"
                }`}
              >
                {isOpen && (
                  <div className='flex items-center gap-2'>
                    {fGroup === "1" && (
                      <FaDatabase size={20} style={{ color: "#4CAF50" }} />
                    )}
                    {fGroup === "2" && (
                      <FaExchangeAlt size={20} style={{ color: "#FF9800" }} />
                    )}
                    {fGroup === "3" && (
                      <FaChartBar size={20} style={{ color: "#2196F3" }} />
                    )}
                    {fGroup === "4" && (
                      <TbReportSearch size={20} style={{ color: "#9C27B0" }} />
                    )}
                    {fGroup === "6" && (
                      <MdAccountBalanceWallet
                        size={20}
                        style={{ color: "#000" }}
                      />
                    )}
                    {fGroup === "11" && (
                      <RiAdminFill size={20} style={{ color: "#F44336" }} />
                    )}
                  </div>
                )}

                <span
                  className={`ml-1 text-sm font-semibold ${
                    isOpen ? "inline" : "hidden"
                  }`}
                >
                  {fGroup === "1" && "Master"}
                  {fGroup === "2" && "Transaction"}
                  {fGroup === "3" && "Display"}
                  {fGroup === "4" && "Reports"}
                  {fGroup === "6" && "Accounts"}
                  {fGroup === "11" && "Administration"}
                </span>
              </div>
              {openedSubmenu === fGroup && (
                <ul className='mt-1 ml-6 font-semibold '>
                  {menuItems.map((menuItem) => (
                    <li
                      key={menuItem.FormKey}
                      className='text-gray-500 text-xs mb-2 '
                    >
                      {menuItem.SubCategory === 1 ? (
                        <div>
                          <span>{menuItem.FormName}</span>
                          {openedSubSubmenu === menuItem.FormKey && (
                            <ul className='mb-[5px] ml-5'>
                              {menuData
                                .filter(
                                  (item) =>
                                    item.SubCategory === 0 &&
                                    item.FormKey.toString().startsWith(
                                      menuItem.FormKey.toString()
                                    )
                                )
                                .map((subMenuItem) => (
                                  <li
                                    key={subMenuItem.FormKey}
                                    className='bg-white text-sm p-1 rounded-xl font-semibold ml-5'
                                  >
                                    <Link
                                      to={`/dashboard/${subMenuItem.FormName.replace(
                                        /\s+/g,
                                        "-"
                                      ).toLowerCase()}`}
                                    >
                                      {subMenuItem.FormName}
                                    </Link>
                                  </li>
                                ))}
                            </ul>
                          )}
                        </div>
                      ) : (
                        <ul className='ml-4 mt-[-2px]  text-black bg-[#fff] rounded-md font-thin'>
                          <Link
                            to={`/${menuItem.FormName.replace(
                              /\s+/g,
                              "-"
                            ).toLowerCase()}`}
                          >
                            <li
                              key={menuItem.FormKey}
                              className='list-disc mb-[1px]'
                            >
                              {menuItem.FormName}
                            </li>
                          </Link>
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          </ul>
        ))}
      </div>
    </div>
  );
};

export default Navbar;
