import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { taxMasterFormFields as initialFields } from "../data"; // Ensure correct path
import { useLocation } from "react-router-dom";
import { BASE_URL } from "../../Common/apiURL";
import axios from "axios";
import {
  getCurrentPageData,
  user,
  selectedBranch,
  SerialKey,
  config,
} from "../../../utils/capitalize";

const TaxMasterForm = ({ onSubmit, formData, setActiveTab }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue, // To programmatically set form values
    formState: { errors },
  } = useForm();

  const [formFields, setFormFields] = useState(initialFields);
  const [formValues, setFormValues] = useState(formData);
  const [executionCount, setExecutionCount] = useState(0);
  const [groupOptions, setGroupOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isEditing, setIsEditing] = useState(false); // Added isEditing state

  const current = useLocation();
  const currentPage = current.pathname.split("/")[1];
  const targetPage = getCurrentPageData(currentPage);
  const formKey = targetPage?.FormKey;
  const branchCode = selectedBranch?.BranchCode;
  const UserID = user[0]?.UserID;

  useEffect(() => {
    setFormValues(formData);
    // Set initial values for editing (if formData exists)
    if (formData && Object.keys(formData).length > 0) {
      setIsEditing(true); // Set form as editing mode
      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]); // Pre-fill form fields
      });
    } else {
      setIsEditing(false); // If no formData, it's in add mode
    }
  }, [formData, setValue]);

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const KeyCode = executionCount === 0 ? "1" : "2";
        const response = await axios.post(
          `${BASE_URL}/dashboard/master-data-list`,
          {
            FormKey: String(formKey),
            BranchCode: branchCode,
            UserID,
            SerialKey,
            KeyCode,
          },
          config
        );

        const data = response.data.data;
        const mgNames = data.map((item) => item.MGName);
        const categories = data?.map((item) => item.Category);

        if (KeyCode === "1") {
          setGroupOptions(mgNames);
        } else if (KeyCode === "2") {
          setCategoryOptions(categories);
        }
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      }
    };

    if (executionCount < 2) {
      fetchDropdownOptions();
      setExecutionCount((prev) => prev + 1);
    }
  }, [executionCount, formKey, branchCode, UserID, SerialKey]);

  useEffect(() => {
    const updatedFields = formFields.map((field) => {
      if (field.type === "dropdown") {
        if (field.name === "group") {
          return { ...field, options: groupOptions };
        }
        if (field.name === "category") {
          return { ...field, options: categoryOptions };
        }
      }
      return field;
    });
    setFormFields(updatedFields);
  }, [groupOptions, categoryOptions]);

  const handleFormSubmit = async (data) => {
    console.log("Form data submitted:", data);
    console.log("Form data updated:", formData);

    const defaultValues = {
      SerialKey: SerialKey || "",
      BranchCode: branchCode || "",
      UserID: UserID || "",
      TaxCode: data.TaxCode || "",
      GroupName: data.group || "",
      TaxName: data.taxDescription || "",
      Tax: data.tax || 0.0,
      TaxRemark: data.taxRemark || "",
      Remark: data.remark || "",
      OpBal: data.openingBalance || 0.0,
      DrCr: data.crDr || "",
      Category:
        categoryOptions.indexOf(data.category) !== -1
          ? categoryOptions.indexOf(data.category)
          : -1,
      TaxStr: data.taxDescription
        ? data.taxDescription.replace(/[^a-zA-Z0-9]/g, "").trim()
        : "",
      RefCnt: !isEditing ? "1" : "2",
    };

    try {
      if (onSubmit) {
        const response = await axios.post(
          `${BASE_URL}/dashboard/tax-master-form-data`,
          defaultValues,
          config
        );
        alert(response.data.message);
        setActiveTab("Lookup");
      }
      reset();
    } catch (error) {
      console.error("Error while submitting the form:", error);
      alert("An error occurred: ", error);
    }
  };

  const renderField = (field) => {
    if (!field) return null;

    const { dtName, name, label, type, placeholder, options, validation } =
      field;

    // Adjust validation dynamically based on isEditing
    const adjustedValidation = isEditing ? {} : validation;

    if (type === "text" || type === "email" || type === "number") {
      return (
        <div key={name} className='flex flex-col'>
          <label className='block font-medium text-md'>{label}</label>
          <input
            type={type}
            placeholder={placeholder}
            {...register(name, adjustedValidation)}
            className='w-full p-1 text-sm border rounded'
            defaultValue={formValues[dtName]}
          />
          {!isEditing && errors[name] && (
            <span className='text-red-500 text-sm'>{errors[name].message}</span>
          )}
        </div>
      );
    } else if (type === "dropdown") {
      const selectedValue =
        typeof formValues[dtName] === "number"
          ? options[formValues[dtName]] // Map index to the corresponding option
          : formValues[dtName]; // Use the string value directly
      return (
        <div key={name} className='flex flex-col'>
          <label className='block font-medium text-md'>{label}</label>
          <select
            {...register(name, adjustedValidation)}
            className='w-full p-1 border rounded text-sm'
            value={selectedValue || ""} // Use the selected value
            onChange={(e) =>
              setFormValues((prev) => ({
                ...prev,
                [dtName]: e.target.value,
              }))
            }
          >
            <option value=''>Select {label}</option>
            {options?.map((option, idx) => (
              <option key={idx} value={option}>
                {option}
              </option>
            ))}
          </select>
          {!isEditing && errors[name] && (
            <span className='text-red-500 text-xs'>{errors[name].message}</span>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div className='bg-gray-200 p-3 rounded-xl shadow-lg'>
      <h2 className='text-lg text-center font-semibold mb-4 uppercase'>
        {targetPage?.FormName}
      </h2>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className='grid grid-cols-1 gap-4'
      >
        {formFields.map((field) => renderField(field))}
        <div className='col-span-full'>
          <button
            type='submit'
            className='px-4 py-2 text-sm bg-black text-white rounded w-full'
          >
            {isEditing ? "Update" : "Submit"}{" "}
            {/* Button text changes based on formData */}
          </button>
        </div>
      </form>
    </div>
  );
};

export default TaxMasterForm;
