import React, { useEffect, useState } from "react";
import {
  FaWallet,
  FaArrowUp,
  FaArrowDown,
  FaClipboardCheck,
  FaMoneyBillAlt,
  FaFileInvoice,
} from "react-icons/fa";
import QuickLinks from "./QuickLinks";
import Loader from "./Common/Loader";
import DoughnutChartComponent from "../charts/PieChartComponent";
import LineChartComponent from "../charts/LineChartComponent";
import BarChartComponent from "../charts/BarChartComponent";
import { getVisitedPages } from "../utils/visitedPages";
import useTrackPageVisits from "../utils/useTrackPageVisits";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { dateFormatter } from "../utils/capitalize";

const Home = ({ data, properties }) => {
  useTrackPageVisits(); // Track route changes
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    console.log(data, properties); // Check if the props are being passed

    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  const navigate = useNavigate();

  const visitedPages = JSON.parse(localStorage.getItem("visitedPages")) || [];

  const handleNavigate = (path) => {
    navigate(path);
  };

  const formatRouteName = (name) => {
    if (!name) {
      return "Testing"; // Return empty string if path is undefined or null
    }

    // Remove leading slashes and split by '/'
    const parts = name.replace(/^\//, "").split("/");

    // Capitalize the first letter of each word
    const formattedParts = parts.map(
      (part) =>
        part
          .replace(/-/g, " ") // Replace hyphens with spaces
          .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize each word
    );

    // Join the parts with a space and return the formatted name
    return formattedParts.join(" ");
  };

  const statsData = [
    {
      value: `₹ ${data?.PurchaseData?.[0]?.TaxableValue || 0}`,
      label: "Total Purchase",
      totalRecord: data?.PurchaseData?.[0]?.TotRecord || 0,
      icon: FaArrowUp,
      bgColor: "bg-blue-300",
      iconColor: "text-blue-600",
      textColor: "text-blue-800",
    },
    {
      value: `₹ ${data?.SalesData?.[0]?.TaxableValue || 0}`,
      totalRecord: data?.SalesData?.[0]?.TotRecord || 0,
      label: "Total Sales",
      icon: FaArrowUp,
      bgColor: "bg-green-300",
      iconColor: "text-green-600",
      textColor: "text-green-800",
    },
    {
      value: `₹ ${data?.PaymentData?.[0]?.Amount || 0}`,
      totalRecord: data?.PaymentData?.[0]?.TotRecord || 0,
      label: "Total Payment",
      icon: FaMoneyBillAlt,
      bgColor: "bg-red-300",
      iconColor: "text-red-600",
      textColor: "text-red-800",
    },
    {
      value: `₹ ${data?.ReceiptData?.[0]?.Amount || 0}`,
      totalRecord: data?.ReceiptData?.[0]?.TotRecord || 0,
      label: "Total Receipt",
      icon: FaWallet,
      bgColor: "bg-yellow-300",
      iconColor: "text-yellow-600",
      textColor: "text-yellow-800",
    },
  ];

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <>
      {showLoader && <Loader />}
      <div className='block md:flex p-2 gap-2 cursor-pointer '>
        <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-1 w-full'>
          <div className='grid grid-cols-2 md:grid-cols-1 lg:grid-cols-2 col-span-1 gap-2 p-2'>
            {statsData.map((stat, index) => {
              const {
                value,
                label,
                totalRecord,
                icon: Icon,
                bgColor,
                iconColor,
              } = stat;
              return (
                <div
                  key={index}
                  className={` p-1 rounded-lg shadow-lg flex items-center ${bgColor} hover:scale-95  duration-700`}
                >
                  <div
                    className={`w-12 h-12 ₹{bgColor} flex items-center justify-center rounded-full`}
                  >
                    <Icon className={`${iconColor} text-2xl`} />
                  </div>
                  <div>
                    <h5 className={`text-sm font-semibold ml-2 ₹{textColor}`}>
                      {value} ({totalRecord})
                    </h5>
                    <h6 className={`text-gray-700 text-xs ml-2`}>{label}</h6>
                  </div>
                </div>
              );
            })}
          </div>

          <div className='bg-white p-4 m-4 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 '>
            <h3 className='text-sm font-semibold mb-2'>Sales / Payments</h3>
            <BarChartComponent />
          </div>
        </div>
      </div>
      <div className='grid grid-cols-1 lg:grid-cols-2 w-full gap-2 p-2 mb-2 min-h-[400px]'>
        <div className=' p-2 rounded-xl mt-1 overflow-y-auto shadow-2xl'>
          <h3 className='text-sm font-semibold mb-2'>Recently Visited Pages</h3>
          <ul className='divide-y divide-gray-200 text-xs '>
            {visitedPages
              // .filter((page) => page.name !== "/dashboard" || "/" || "/login") // Exclude dashboard route
              .filter(
                (page) => !["/", "/login", "/dashboard"].includes(page.name) // Exclude specific routes
              )
              .map((page, index) => {
                // Format the route name
                // const displayName = formatRouteName(page.name);
                const displayName =
                  page.name === "/dashboard"
                    ? "Homepage"
                    : formatRouteName(page.name);
                return (
                  <li
                    key={index}
                    className='flex p-1 justify-between py-2 text-xs hover:bg-black hover:text-white transition-colors duration-300 cursor-pointer rounded-lg shadow-2xl mb-1 bg-white'
                    onClick={() => handleNavigate(page.name)}
                  >
                    <span>{displayName}</span>
                    <span className='text-gray-400 text-xs'>
                      {new Date(page.timestamp).toLocaleString()}
                    </span>
                  </li>
                );
              })}
          </ul>
        </div>

        <div className='ml-1 mt-1 shadow-2xl  overflow-y-auto'>
          <QuickLinks />
        </div>
      </div>
      <footer className='text-xs text-center '>
        Due Date: {dateFormatter(properties[0]?.DueDate)}
      </footer>
      <footer className='text-xs text-center p-2'>
        Last Updated: {dateFormatter(formattedDate)} (06:44:58 PM)
      </footer>
    </>
  );
};

export default Home;
