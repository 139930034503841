import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { hsnMasterFormFields } from "../data"; // Ensure correct path
import { useLocation } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../Common/apiURL";
import {
  getCurrentPageData,
  user,
  selectedBranch,
  SerialKey,
  config,
} from "../../../utils/capitalize";

const HSNMasterForm = ({ onSubmit, setActiveTab, formData }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [formFields, setFormFields] = useState(hsnMasterFormFields);
  const [formValues, setFormValues] = useState(formData);
  const [executionCount, setExecutionCount] = useState(0);
  const [rateOptions, setRateOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [uqcOptions, setUqcOptions] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const current = useLocation();
  const currentPage = current.pathname.split("/")[1];
  const convertToCustomTitleCase = (str) => {
    const formattedString = str
      .replace(/-/g, " ") // Replace hyphens with spaces
      .toLowerCase(); // Convert the entire string to lowercase first

    return formattedString
      .split(" ")
      .map((word, index) => {
        if (index === 0) {
          return word.slice(0, 3).toUpperCase() + word.slice(3); // First three letters in uppercase
        }
        return word.charAt(0).toUpperCase() + word.slice(1); // Capitalize first letter of subsequent words
      })
      .join(" ");
  };
  const pageTitle = convertToCustomTitleCase(currentPage);
  const targetPage = getCurrentPageData(pageTitle);
  const formKey = targetPage?.FormKey;
  const branchCode = selectedBranch?.BranchCode;
  const UserID = user[0]?.UserID;

  useEffect(() => {
    setFormValues(formData);
    if (formData && Object.keys(formData).length > 0) {
      setIsEditing(true);
      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]);
      });
    } else {
      setIsEditing(false);
    }
  }, [formData, setValue]);

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const KeyCode = String(executionCount + 1); // Increment KeyCode with each execution

        const response = await axios.post(
          `${BASE_URL}/dashboard/master-data-list`,
          {
            FormKey: String(formKey),
            BranchCode: branchCode,
            UserID,
            SerialKey,
            KeyCode,
          },
          config
        );
        const data = response.data.data;

        if (KeyCode === "1") {
          const rate = data.map((item) => item.GSTName);
          setRateOptions(rate);
        } else if (KeyCode === "2") {
          const categories = data.map((item) => item.Category);
          setCategoryOptions(categories);
        } else if (KeyCode === "3") {
          const units = data.map((item) => item.UName);
          setUnitOptions(units);
        } else if (KeyCode === "4") {
          const uqc = data.map((item) => item.UQC);
          setUqcOptions(uqc);
        } else {
          console.log(`Additional KeyCode: ${KeyCode}`);
        }
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      }
    };

    if (executionCount < 4) {
      fetchDropdownOptions();
      setExecutionCount((prev) => prev + 1);
    }
  }, [executionCount, formKey, branchCode, UserID, SerialKey]);

  // Render input fields dynamically
  const renderField = (field) => {
    if (!field) return null; // Handle the case where field is undefined

    const { dtName, name, label, type, placeholder, validation } = field;
    const adjustedValidation = isEditing ? {} : validation;

    if (type === "text" || type === "email") {
      return (
        <div key={name} className='flex flex-col'>
          <label className='block font-medium'>{label}</label>
          <input
            type={type}
            placeholder={placeholder}
            {...register(name, validation)}
            className='w-full p-1 border rounded'
            defaultValue={formValues[dtName]}
          />
          {errors[name] && (
            <span className='text-red-500 text-sm'>{errors[name].message}</span>
          )}
        </div>
      );
    } else if (type === "dropdown") {
      let options = [];
      if (name === "rate") {
        options = rateOptions;
      } else if (name === "category") {
        options = categoryOptions;
      } else if (name === "unit") {
        options = unitOptions;
      } else if (name === "uqc") {
        options = uqcOptions;
      }
      const selectedValue =
        typeof formValues[dtName] === "number"
          ? options[formValues[dtName]] // Map index to the corresponding option
          : formValues[dtName]; // Use the string value directly
      return (
        <div key={name} className='flex flex-col'>
          <label className='block font-medium'>{label}</label>
          <select
            {...register(name, adjustedValidation)}
            className='w-full p-1 border rounded'
            value={selectedValue || ""} // Use the selected value
            onChange={(e) =>
              setFormValues((prev) => ({
                ...prev,
                [dtName]: e.target.value,
              }))
            }
          >
            <option value=''>Select {label}</option>
            {options.map((option, idx) => (
              <option key={idx} value={option}>
                {option}
              </option>
            ))}
          </select>
          {errors[name] && (
            <span className='text-red-500 text-sm'>{errors[name].message}</span>
          )}
        </div>
      );
    } else if (type === "checkbox") {
      return (
        <div key={name} className='flex items-center'>
          <input
            type='checkbox'
            {...register(name, validation)}
            className='mr-2'
          />
          <label className='block font-medium'>{label}</label>
        </div>
      );
    }
    return null;
  };

  // Handle form submission
  const handleFormSubmit = async (data) => {
    console.log("Form Data:", data);
    if (onSubmit) {
      onSubmit(data); // Call the parent onSubmit function if provided
    }
    const defaultValues = {
      SerialKey: SerialKey || "",
      BranchCode: branchCode || "",
      UserID: UserID || "",
      HCode: data.HCode || "", // Optional parameter
      HSNCode: data.hsnCode || formData.hsnCode, // Optional parameter
      HSNName: data.description || formData.description, // Optional parameter
      GSTName: data.rate || formData.rate, // Optional parameter
      UName: data.unit || formData.unit, // Optional parameter
      GSType: data.category || formData.category, // Optional parameter
      UQC: data.uqc || formData.uqc, // Optional parameter
      RefCnt: !isEditing ? "1" : "2",
    };
    console.log("Default Values:", defaultValues);

    try {
      if (onSubmit) {
        const response = await axios.post(
          `${BASE_URL}/dashboard/hsn-master-form-data`,
          defaultValues,
          config
        );
        alert(response.data.message);
        setActiveTab("Lookup");
      }
      reset();
    } catch (error) {
      console.error("Error while submitting the form:", error);
      alert("An error occurred: ", error);
    }
    // Reset the form after submission
    // reset();
  };

  return (
    <div className='bg-gray-200 p-3 rounded-xl shadow-lg'>
      <h2 className='text-lg text-center font-semibold mb-4 uppercase'>
        {targetPage?.FormName}
      </h2>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4'
      >
        {hsnMasterFormFields.map((field) => renderField(field))}
        <div className='col-span-full'>
          <button
            type='submit'
            className='px-4 py-2 text-sm bg-black text-white rounded w-full'
          >
            {isEditing ? "Update" : "Submit"}{" "}
          </button>
        </div>
      </form>
    </div>
  );
};

export default HSNMasterForm;
