import React, { useState, useEffect } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import {
  getCurrentPageData,
  user,
  selectedBranch,
  SerialKey,
  config,
} from "../../../utils/capitalize";
import { BASE_URL } from "../../Common/apiURL";
import axios from "axios";
import { useLocation } from "react-router-dom";
import DataTableComponent from "../../../Components/DataTable";

const TaxMasterLookUp = ({ setActiveTab, setFormData }) => {
  const [data, setData] = useState([]);

  const current = useLocation();
  const currentPage = current.pathname.split("/")[1];
  const targetPage = getCurrentPageData(currentPage);
  const formKey = targetPage?.FormKey;
  const branchCode = selectedBranch?.BranchCode;
  const UserID = user[0]?.UserID;

  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/dashboard/master-lookup-data`,
        {
          FormKey: String(formKey),
          BranchCode: branchCode,
          UserID,
          SerialKey,
        },
        config
      );

      const data = response.data.data;
      console.log(data);
      setData(data);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Edit handler
  const handleEdit = (row) => {
    setFormData({ ...row }); // Set current row data in form
    setActiveTab("Create"); // Switch to the "Create" tab
  };

  // Delete handler
  const handleDelete = async (row) => {
    // Show confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this record?"
    );

    if (!isConfirmed) {
      return;
    }

    try {
      const defaultValues = {
        SerialKey: SerialKey || "",
        BranchCode: branchCode || "",
        UserID: UserID || "",
        TaxCode: row.TaxCode || "",
        GroupName: row.group || "",
        TaxName: row.TaxName || "",
        Tax: row.Tax || 0.0,
        TaxRemark: row.TaxRemark || "",
        Remark: row.remark || "",
        OpBal: row.openingBalance || 0.0,
        DrCr: row.crDr || "",
        Category: row.category,
        TaxStr: row.taxDescription
          ? row.taxDescription.replace(/[^a-zA-Z0-9]/g, "").trim()
          : "",
        RefCnt: "3",
      };
      // Call the API to delete the record from the server
      const response = await axios.post(
        `${BASE_URL}/dashboard/tax-master-form-data`,
        defaultValues,
        config
      );

      if (response.data.success) {
        // If delete is successful, update the local data state
        const updatedData = data.filter((item) => item.TaxCode !== row.TaxCode); // Match TaxCode for deletion
        setData(updatedData);
        alert("Record deleted successfully");
      } else {
        alert("Failed to delete the record");
      }
    } catch (error) {
      console.error("Error deleting record:", error);
      alert("An error occurred while deleting the record");
    }
  };

  // Dynamically create columns based on form fields and visibility
  const columns = [
    {
      name: "S No",
      selector: (row, index) => index + 1, // Generate serial number dynamically
      sortable: true,
      width: "80px",
      cell: (row, index) => <div className='text-center py-2'>{index + 1}</div>,
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <FaEdit
            className='text-blue-500 mx-2'
            onClick={() => handleEdit(row)}
          />
          <FaTrash className='text-red-500' onClick={() => handleDelete(row)} />
        </>
      ),
      ignoreRowClick: true,
    },
    {
      name: "MG Name",
      selector: (row) => row.MGName, // Display MGName directly
      sortable: true,
    },

    {
      name: "Tax Name",
      selector: (row) => row.TaxName, // Display MGName directly
      sortable: true,
    },
    {
      name: "Tax Remark",
      selector: (row) => row.TaxRemark, // Display MGName directly
      sortable: true,
    },
    {
      name: "Tax",
      selector: (row) => row.Tax, // Display MGName directly
      sortable: true,
    },
  ];

  return (
    <DataTableComponent
      title={targetPage?.FormName}
      data={data}
      columns={columns}
      onEdit={handleEdit}
      onDelete={handleDelete}
      fetchData={fetchData}
    />
  );
};

export default TaxMasterLookUp;
