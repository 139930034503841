import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { brandMasterFormFields } from "../data"; // Ensure correct path
import { useLocation } from "react-router-dom";
import { BASE_URL } from "../../Common/apiURL";
import axios from "axios";
import {
  getCurrentPageData,
  user,
  selectedBranch,
  SerialKey,
  config,
} from "../../../utils/capitalize";

const BrandForm = ({ onSubmit, setActiveTab, formData }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue, // To programmatically set form values
    formState: { errors },
  } = useForm();
  const [formFields, setFormFields] = useState(brandMasterFormFields);
  const [formValues, setFormValues] = useState(formData);
  const [executionCount, setExecutionCount] = useState(0);
  const [groupOptions, setGroupOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isEditing, setIsEditing] = useState(false); // Added isEditing state

  const current = useLocation();
  const currentPage = current.pathname.split("/")[1];
  const targetPage = getCurrentPageData(currentPage);
  const branchCode = selectedBranch?.BranchCode;
  const UserID = user[0]?.UserID;

  useEffect(() => {
    console.log(formData);
    setFormValues(formData);
    // Set initial values for editing (if formData exists)
    if (formData && Object.keys(formData).length > 0) {
      setIsEditing(true); // Set form as editing mode
      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]); // Pre-fill form fields
      });
    } else {
      setIsEditing(false); // If no formData, it's in add mode
    }
  }, [formData, setValue]);

  // Render input fields dynamically
  const renderField = (field) => {
    if (!field) return null; // Handle the case where field is undefined

    const { name, dtName, label, type, placeholder, validation } = field;

    if (type === "text" || type === "email") {
      return (
        <div key={name} className='flex flex-col'>
          <label className='block font-medium'>{label}</label>
          <input
            type={type}
            placeholder={placeholder}
            {...register(name, validation)}
            className='w-full p-1 text-sm border rounded'
            defaultValue={formValues[dtName]}
          />
          {errors[name] && (
            <span className='text-red-500 text-sm'>{errors[name].message}</span>
          )}
        </div>
      );
    }
    return null;
  };

  const handleFormSubmit = async (data) => {
    const defaultValues = {
      SerialKey: SerialKey || "",
      BranchCode: branchCode || "",
      UserID: UserID || "",
      BCode: formData.BCode || "",
      BName: data.brandName || "",
      BAbbr: data.brandAbbr || "",
      BrandCaption: "",
      RefCnt: !isEditing ? "1" : "2",
    };

    try {
      if (onSubmit) {
        const response = await axios.post(
          `${BASE_URL}/dashboard/brand-master-form-data`,
          defaultValues,
          config
        );
        alert(response.data.message);
        setActiveTab("Lookup");
      }
      reset();
    } catch (error) {
      console.error("Error while submitting the form:", error);
      alert("An error occurred: ", error);
    }
  };

  return (
    <div className='bg-gray-200 p-2 rounded-xl shadow-lg'>
      <h2 className='text-lg text-center font-semibold mb-4 uppercase'>
        {targetPage?.FormName}
      </h2>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4'
      >
        {brandMasterFormFields.map((field) => renderField(field))}
        <div className='col-span-full'>
          <button
            type='submit'
            className='px-4 py-2 text-sm bg-black text-white rounded w-full'
          >
            {isEditing ? "Update" : "Create"}{" "}
            {/* Button text changes based on formData */}
          </button>
        </div>
      </form>
    </div>
  );
};

export default BrandForm;
