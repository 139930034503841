import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { partyMasterFormFields } from "../data"; // Ensure correct path
import { useLocation } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../Common/apiURL";
import {
  getCurrentPageData,
  user,
  selectedBranch,
  SerialKey,
  config,
} from "../../../utils/capitalize";

const PartyForm = ({ onSubmit, setActiveTab, formData }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [formFields, setFormFields] = useState(partyMasterFormFields);
  const [formValues, setFormValues] = useState(formData);
  const [executionCount, setExecutionCount] = useState(0);
  const [rateOptions, setRateOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [agentOptions, setAgentOptions] = useState([]);
  const [transportOptions, setTransportOptions] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const current = useLocation();
  const currentPage = current.pathname.split("/")[1];
  const targetPage = getCurrentPageData(currentPage);
  const formKey = targetPage?.FormKey;
  const branchCode = selectedBranch?.BranchCode;
  const UserID = user[0]?.UserID;

  useEffect(() => {
    setFormValues(formData);
    if (formData && Object.keys(formData).length > 0) {
      setIsEditing(true);
      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]);
      });
    } else {
      setIsEditing(false);
    }
  }, [formData, setValue]);

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const KeyCode = String(executionCount + 1); // Increment KeyCode with each execution

        const response = await axios.post(
          `${BASE_URL}/dashboard/master-data-list`,
          {
            FormKey: String(formKey),
            BranchCode: branchCode,
            UserID,
            SerialKey,
            KeyCode,
          },
          config
        );
        const data = response.data.data;

        if (KeyCode === "1") {
          const rate = data.map((item) => item.MGName);
          setRateOptions(rate);
        } else if (KeyCode === "2") {
          const categories = data.map((item) => item.StName);
          setCategoryOptions(categories);
        } else if (KeyCode === "3") {
          const categories = data.map((item) => item.AName);
          setAgentOptions(categories);
        } else if (KeyCode === "4") {
          const categories = data.map((item) => item.TName);
          setTransportOptions(categories);
        } else {
          console.log(`Additional KeyCode: ${KeyCode}`);
        }
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      }
    };

    if (executionCount < 4) {
      fetchDropdownOptions();
      setExecutionCount((prev) => prev + 1);
    }
  }, [executionCount, formKey, branchCode, UserID, SerialKey]);

  useEffect(() => {
    const updatedFields = formFields.map((field) => {
      if (field.type === "dropdown") {
        if (field.name === "group") {
          return { ...field, options: rateOptions };
        }
        if (field.name === "state") {
          return { ...field, options: categoryOptions };
        }
        if (field.name === "agent") {
          return { ...field, options: agentOptions };
        }
        if (field.name === "transport") {
          return { ...field, options: transportOptions };
        }
      }
      return field;
    });
    setFormFields(updatedFields);
  }, [rateOptions, categoryOptions, transportOptions, agentOptions]);

  // Render input fields dynamically
  const renderField = (field) => {
    if (!field) return null;

    const { dtName, name, label, type, placeholder, validation } = field;
    const adjustedValidation = isEditing ? {} : validation;

    if (type === "text" || type === "email") {
      return (
        <div key={name} className='flex flex-col'>
          <label className='block font-medium'>{label}</label>
          <input
            type={type}
            placeholder={placeholder}
            {...register(name, validation)}
            className='w-full p-1 border rounded'
            defaultValue={formValues[dtName]}
          />
          {errors[name] && (
            <span className='text-red-500 text-sm'>{errors[name].message}</span>
          )}
        </div>
      );
    } else if (type === "dropdown") {
      const options =
        name === "group"
          ? rateOptions
          : name === "state"
          ? categoryOptions
          : name === "agent"
          ? agentOptions
          : name === "transport"
          ? transportOptions
          : field.options || [];

      const selectedValue = formValues[dtName] || ""; // Ensure default is an empty string if no value

      return (
        <div key={name} className='flex flex-col'>
          <label className='block font-medium'>{label}</label>
          <select
            {...register(name, adjustedValidation)}
            className='w-full p-1 border rounded'
            value={selectedValue}
            onChange={(e) => {
              const updatedValue = e.target.value;
              setFormValues((prev) => ({
                ...prev,
                [dtName]: updatedValue,
              }));
              setValue(name, updatedValue); // Update react-hook-form state
            }}
          >
            <option value=''>Select {label}</option>
            {options.length > 0 ? (
              options.map((option, idx) => (
                <option key={idx} value={option}>
                  {option}
                </option>
              ))
            ) : (
              <option value=''>No options available</option>
            )}
          </select>
          {errors[name] && (
            <span className='text-red-500 text-sm'>{errors[name].message}</span>
          )}
        </div>
      );
    }

    return null;
  };

  // Handle form submission
  console.log("Form Data:", formData);
  const handleFormSubmit = async (data) => {
    console.log("Form Data:", data);

    const defaultValues = partyMasterFormFields.reduce((acc, field) => {
      const fieldKey = field.dtName;
      acc[fieldKey] = data[field.name] || formData?.[fieldKey] || "";
      return acc;
    }, {});

    defaultValues.SerialKey = SerialKey || "";
    defaultValues.BranchCode = branchCode || "";
    defaultValues.UserID = UserID || "";
    defaultValues.Pcode = !isEditing ? "" : formData.Pcode || "";
    defaultValues.RefCnt = !isEditing ? "1" : "2";

    console.log("Default Values:", defaultValues);

    try {
      if (onSubmit) {
        const response = await axios.post(
          `${BASE_URL}/dashboard/party-master-form-data`,
          defaultValues,
          config
        );
        alert(response.data.message);
        setActiveTab("Lookup");
      }
      reset();
    } catch (error) {
      console.error("Error while submitting the form:", error);
      alert("An error occurred: ", error);
    }
  };

  return (
    <div className='bg-gray-100 p-2 rounded-xl shadow-lg'>
      <h2 className='text-lg text-center font-semibold mb-4 uppercase'>
        {targetPage?.FormName}
      </h2>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4'
      >
        {partyMasterFormFields.map((field) => renderField(field))}
        <div className='col-span-full'>
          <button
            type='submit'
            className='px-4 py-2 text-sm bg-black text-white rounded w-full'
          >
            {isEditing ? "Update" : "Submit"}{" "}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PartyForm;
