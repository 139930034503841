import React from "react";
import { useForm } from "react-hook-form";
import { itemMasterFormFields } from "../data"; // Ensure correct path

const ItemMasterForm = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Render input fields dynamically
  const renderField = (field) => {
    if (!field) return null; // Handle the case where field is undefined

    const { name, label, type, placeholder, options, validation } = field;

    if (type === "text" || type === "email") {
      return (
        <div key={name} className='flex flex-col'>
          <label className='block font-medium'>{label}</label>
          <input
            type={type}
            placeholder={placeholder}
            {...register(name, validation)}
            className='w-full p-1 border rounded'
          />
          {errors[name] && (
            <span className='text-red-500 text-sm'>{errors[name].message}</span>
          )}
        </div>
      );
    } else if (type === "dropdown") {
      return (
        <div key={name} className='flex flex-col'>
          <label className='block font-medium'>{label}</label>
          <select
            {...register(name, validation)}
            className='w-full p-1 border rounded'
          >
            <option value=''>Select {label}</option>
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          {errors[name] && (
            <span className='text-red-500 text-sm'>{errors[name].message}</span>
          )}
        </div>
      );
    } else if (type === "checkbox") {
      return (
        <div key={name} className='flex items-center'>
          <input
            type='checkbox'
            {...register(name, validation)}
            className='mr-2'
          />
          <label className='block font-medium'>{label}</label>
        </div>
      );
    }
    return null;
  };

  // Handle form submission
  const handleFormSubmit = (data) => {
    console.log("Form Data:", data);
    if (onSubmit) {
      onSubmit(data); // Call the parent onSubmit function if provided
    }
    // Reset the form after submission
    reset();
  };

  return (
    <div className='bg-gray-100 p-3 rounded-xl shadow-lg'>
      <h2 className='text-lg text-center font-semibold mb-4'>
        Create Item Form
      </h2>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4'
      >
        {itemMasterFormFields.map((field) => renderField(field))}
        <div className='col-span-full'>
          <button
            type='submit'
            className='px-4 py-2 text-sm bg-black text-white rounded w-full'
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ItemMasterForm;
