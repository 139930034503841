import React, { useEffect, useState } from "react";
import { FaChartBar, FaDatabase, FaExchangeAlt, FaUser } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { AiOutlineClose, AiOutlineMenu, AiOutlineStar } from "react-icons/ai";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { TbReportSearch } from "react-icons/tb";
import { RiAdminFill } from "react-icons/ri";
import LogoURL from "../assets/PTACLogo.jpg";
import { MdAccountBalanceWallet } from "react-icons/md";

const Header = ({ data }) => {
  // const [searchOpen, setSearchOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [userName, setUserName] = useState(
    localStorage.getItem("UserName") || ""
  );

  const [panelOpen, setPanelOpen] = useState(false);
  const [openedSubmenu, setOpenedSubmenu] = useState(null);
  const [profilePanelOpen, setProfilePanelOpen] = useState(false);
  const [openedSubSubmenu, setOpenedSubSubmenu] = useState(null);

  const menu = JSON.parse(localStorage.getItem("Menu")) || [];
  const navigate = useNavigate();
  const path = useLocation().pathname.slice(1) || "Home";

  const groupedMenu = menu.reduce((acc, curr) => {
    acc[curr.FGroup] = acc[curr.FGroup] || [];
    acc[curr.FGroup].push(curr);
    return acc;
  }, {});

  const togglePanel = () => {
    setPanelOpen(!panelOpen);
    setProfilePanelOpen(false);
  };
  const toggleProfilePanel = () => {
    setPanelOpen(false);
    setProfilePanelOpen(!profilePanelOpen);
  };
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const handleLogout = () => {
    Cookies.remove("token");
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    setUserName("Loading");
    const timeoutId = setTimeout(() => {
      const storedUserName =
        JSON.parse(localStorage.getItem("userInfo"))?.[0]?.UserID || "User";
      setUserName(storedUserName);
    }, 100);
    return () => clearTimeout(timeoutId);
  }, []);

  const favorites = data?.FavoritesData || [];
  let sideBarMenu = menu;
  const handleSidebarToggle = () => {
    setPanelOpen(!panelOpen);
  };

  const handleSubmenuToggle = (fGroup) => {
    setOpenedSubmenu((prevState) => (prevState === fGroup ? null : fGroup));
  };

  return (
    <header
      className='relative  flex items-center justify-between p-4   w-full lg:w-[96%] xl:w-[96%]  lg:left-[4%]  rounded-xl shadow-2xl '
      style={{ zIndex: 50 }} // Ensure the navbar is on top
    >
      <div className='flex items-center space-x-2'>
        <button onClick={togglePanel}>
          <AiOutlineMenu size={25} style={{ fontWeight: "bold" }} />
        </button>

        {path && path !== "dashboard" && (
          <Link
            to='/dashboard'
            className='text-xs font-bold hover:text-black hidden lg:inline-block'
          >
            Dashboard
          </Link>
        )}
        {path && (
          <span className='hidden lg:inline-block text-black text-xs ml-1'>
            {path !== "dashboard" && (
              <span>&gt; {path.charAt(0).toUpperCase() + path.slice(1)}</span>
            )}
          </span>
        )}
      </div>

      {/* Sidebar Panel */}
      <div
        className={`absolute h-screen inset-0 left-0 z-100 w-1/2 md:w-1/3 bg-white shadow-2xl transform transition-transform ease-in-out duration-700 ${
          panelOpen ? "translate-x-0 overflow-y-auto" : "-translate-x-full"
        }`}
        style={{
          opacity: panelOpen ? 1 : 0,
          pointerEvents: panelOpen ? "auto" : "none",
        }}
      >
        {/* Panel content here */}
        <div className='flex justify-between items-center p-4 text-white '>
          <Link
            to='/dashboard'
            className='block'
            onClick={() => setPanelOpen(false)}
          >
            <img src={LogoURL} alt='Logo' className=' rounded-xl shadow-2xl' />
          </Link>
        </div>
        {/* Add more content here if needed */}

        <div className='p-2 mt-5 cursor-pointer  '>
          {Object.entries(groupedMenu).map(([fGroup, menuItems]) => (
            <ul key={fGroup}>
              <li
                className='text-black text-xs font-semibold relative bg-white p-1 rounded-lg shadow-2xl mb-3'
                onClick={() => handleSubmenuToggle(fGroup)}
              >
                <button className='text-black ml-2 mt-4 absolute top-0 right-0 transform -translate-y-1/2'>
                  {openedSubmenu === null || openedSubmenu !== fGroup ? (
                    <FiChevronDown />
                  ) : (
                    <FiChevronUp />
                  )}
                </button>
                <span className='text-md'>
                  {fGroup === "1" && (
                    <div className='flex gap-2 items-center text-md font-semibold'>
                      <FaDatabase color='#000' size={20} />
                      Master
                    </div>
                  )}
                  {fGroup === "2" && (
                    <div className='flex gap-2 items-center  text-md font-semibold'>
                      <FaExchangeAlt color='#000' size={20} />
                      Transaction
                    </div>
                  )}
                  {fGroup === "3" && (
                    <div className='flex gap-2 items-center text-md font-semibold'>
                      <FaChartBar color='#000' size={20} /> Display
                    </div>
                  )}
                  {fGroup === "4" && (
                    <div className='flex gap-2 items-center text-md font-semibold'>
                      <TbReportSearch color='#000' size={20} /> Reports
                    </div>
                  )}
                  {fGroup === "6" && (
                    <div className='flex gap-2 items-center text-md font-semibold'>
                      <MdAccountBalanceWallet
                        size={20}
                        style={{ color: "#000" }}
                      />{" "}
                      Accounts
                    </div>
                  )}
                  {fGroup === "11" && (
                    <div className='flex gap-2 items-center text-md font-semibold'>
                      <RiAdminFill color='#000' size={20} /> Administration
                    </div>
                  )}
                </span>
                {openedSubmenu === fGroup && (
                  <ul className='mt-1 ml-3'>
                    {menuItems.map((menuItem) => (
                      <li key={menuItem.FormKey} className=' text-[#4FA9B7]'>
                        {menuItem.SubCategory === 1 ? (
                          <div>
                            <li className='list-roman'>{menuItem.FormName}</li>
                            {openedSubSubmenu === menuItem.FormKey && (
                              <ul>
                                {sideBarMenu
                                  .filter(
                                    (item) =>
                                      item.SubCategory === 0 &&
                                      item.FormKey.toString().startsWith(
                                        menuItem.FormKey.toString()
                                      )
                                  )
                                  .map((subMenuItem) => (
                                    <li
                                      key={subMenuItem.FormKey}
                                      onClick={handleSidebarToggle}
                                      className='bg-white text-md p-1 rounded-xl '
                                    >
                                      <Link
                                        to={`/dashboard/${subMenuItem.FormName.replace(
                                          /\s+/g,
                                          "-"
                                        ).toLowerCase()}`}
                                      >
                                        {subMenuItem.FormName}
                                      </Link>
                                    </li>
                                  ))}
                              </ul>
                            )}
                          </div>
                        ) : (
                          <ul
                            className='ml-4 text-black bg-[#fff] rounded-md text-xs font-thin uppercase'
                            onClick={handleSidebarToggle}
                          >
                            <Link
                              to={`/${menuItem.FormName.replace(
                                /\s+/g,
                                "-"
                              ).toLowerCase()}`}
                            >
                              <li key={menuItem.FormKey} className='list-disc'>
                                {menuItem.FormName}
                              </li>
                            </Link>
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            </ul>
          ))}
        </div>
      </div>

      {/* User Dropdown */}
      <div className='relative'>
        <button onClick={toggleProfilePanel} className='flex items-center'>
          <FaUser size={25} />
        </button>
        {dropdownOpen && (
          <div className='absolute right-0 mt-2 bg-white text-black rounded shadow-lg'>
            <button
              className='w-full text-left px-4 py-2 hover:bg-gray-200'
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
        )}
      </div>

      {/* Profile Right Panel */}
      <div
        className={`fixed inset-y-0 right-0 z-100 w-1/7 bg-white text-black transform transition-transform duration-700 shadow-2xl ${
          profilePanelOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className='flex justify-between items-center p-4'>
          <h2 className='font-bold'></h2>
          <button onClick={toggleProfilePanel}>
            <AiOutlineClose size={25} />
          </button>
        </div>
        <div className='p-2'>
          <p className='text-sm mb-1 text-center font-semibold'>
            Welcome, {userName.toUpperCase()}
          </p>

          <div className='mt-4 space-y-1'>
            <p className='text-sm font-semibold'>Your Favorites</p>
            {favorites && favorites.length > 0 ? (
              favorites.map((favorite, index) => (
                <Link
                  key={favorite.FormKey}
                  to={`/${favorite.FormName.replace(
                    /\s+/g,
                    "-"
                  ).toLowerCase()}`}
                >
                  <div
                    key={index}
                    className='flex items-center text-xs px-2 py-1 bg-gray-200 rounded mb-2'
                    onClick={toggleProfilePanel}
                  >
                    <span className='mr-2'>
                      {favorite.icon ? (
                        <favorite.icon size={16} />
                      ) : (
                        <AiOutlineStar size={16} />
                      )}
                    </span>
                    <p className='flex-grow'>{favorite.FormName}</p>
                  </div>
                </Link>
              ))
            ) : (
              <p className='text-sm text-center text-gray-500'>
                No favorites yet
              </p>
            )}
          </div>

          <button
            className='w-full text-center px-4 py-2 text-xs bg-red-600 text-white rounded hover:bg-gray-600'
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
