import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { agentMasterFormFields } from "../data"; // Ensure correct path
import { useLocation } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../Common/apiURL";
import {
  getCurrentPageData,
  user,
  selectedBranch,
  SerialKey,
  config,
} from "../../../utils/capitalize";

const AgentForm = ({ onSubmit, setActiveTab, formData }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [formFields, setFormFields] = useState(agentMasterFormFields);
  const [formValues, setFormValues] = useState(formData);
  const [executionCount, setExecutionCount] = useState(0);
  const [rateOptions, setRateOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const current = useLocation();
  const currentPage = current.pathname.split("/")[1];
  const targetPage = getCurrentPageData(currentPage);
  const formKey = targetPage?.FormKey;
  const branchCode = selectedBranch?.BranchCode;
  const UserID = user[0]?.UserID;

  useEffect(() => {
    setFormValues(formData);
    if (formData && Object.keys(formData).length > 0) {
      setIsEditing(true);
      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]);
      });
    } else {
      setIsEditing(false);
    }
  }, [formData, setValue]);

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const KeyCode = String(executionCount + 1); // Increment KeyCode with each execution

        const response = await axios.post(
          `${BASE_URL}/dashboard/master-data-list`,
          {
            FormKey: String(formKey),
            BranchCode: branchCode,
            UserID,
            SerialKey,
            KeyCode,
          },
          config
        );
        const data = response.data.data;

        if (KeyCode === "1") {
          const rate = data.map((item) => item.MGName);
          setRateOptions(rate);
        } else if (KeyCode === "2") {
          const categories = data.map((item) => item.StName);
          setCategoryOptions(categories);
        } else {
          console.log(`Additional KeyCode: ${KeyCode}`);
        }
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      }
    };

    if (executionCount < 2) {
      fetchDropdownOptions();
      setExecutionCount((prev) => prev + 1);
    }
  }, [executionCount, formKey, branchCode, UserID, SerialKey]);

  useEffect(() => {
    const updatedFields = formFields.map((field) => {
      if (field.type === "dropdown") {
        if (field.name === "group") {
          return { ...field, options: rateOptions };
        }
        if (field.name === "state") {
          return { ...field, options: categoryOptions };
        }
      }
      return field;
    });
    setFormFields(updatedFields);
  }, [rateOptions, categoryOptions]);

  // Render input fields dynamically
  const renderField = (field) => {
    if (!field) return null;

    const { dtName, name, label, type, placeholder, validation } = field;
    const adjustedValidation = isEditing ? {} : validation;

    if (type === "text" || type === "email") {
      return (
        <div key={name} className='flex flex-col'>
          <label className='block font-medium'>{label}</label>
          <input
            type={type}
            placeholder={placeholder}
            {...register(name, validation)}
            className='w-full p-1 border rounded'
            defaultValue={formValues[dtName]}
          />
          {errors[name] && (
            <span className='text-red-500 text-sm'>{errors[name].message}</span>
          )}
        </div>
      );
    } else if (type === "dropdown") {
      const options =
        name === "group"
          ? rateOptions
          : name === "state"
          ? categoryOptions
          : field.options || [];
      const selectedValue =
        typeof formValues[dtName] === "number"
          ? options[formValues[dtName]] // Map index to the corresponding option
          : formValues[dtName];

      return (
        <div key={name} className='flex flex-col'>
          <label className='block font-medium'>{label}</label>
          <select
            {...register(name, adjustedValidation)}
            className='w-full p-1 border rounded'
            value={selectedValue || ""} // Use the selected value
            onChange={(e) =>
              setFormValues((prev) => ({
                ...prev,
                [dtName]: e.target.value,
              }))
            }
          >
            <option value=''>Select {label}</option>
            {options.map((option, idx) => (
              <option key={idx} value={option}>
                {option}
              </option>
            ))}
          </select>
          {errors[name] && (
            <span className='text-red-500 text-sm'>{errors[name].message}</span>
          )}
        </div>
      );
    }
    return null;
  };

  // Handle form submission
  const handleFormSubmit = async (data) => {
    console.log("Form Data:", data);
    if (onSubmit) {
      onSubmit(data); // Call the parent onSubmit function if provided
    }
    const defaultValues = {
      SerialKey: SerialKey || "", // Mandatory: Serial Key of the request
      BranchCode: branchCode || "", // Mandatory: Branch Code
      UserID: UserID || "", // Mandatory: User ID initiating the request
      ACode: data?.Acode || "", // Optional: Agent Code
      AName: data?.agentName || "", // Mandatory: Agent Name
      GroupName: data?.group || "", // Optional: Group (Dropdown)
      Address1: data?.address1 || formData?.Address1 || "", // Mandatory: Address line 1
      Address2: data?.address2 || formData?.Address2 || "", // Optional: Address line 2
      City: data?.city || formData?.City || "", // Mandatory: City
      Mobile: data?.mobile || formData?.Mobile || "", // Mandatory: Mobile number
      PAN: data?.pan || formData?.PAN || "", // Optional: PAN
      GSTIN: data?.gstin || formData?.GSTIN || "", // Mandatory: GSTIN
      EmailID: data?.email || formData?.EmailID || "", // Mandatory: Email
      State: data?.state || formData?.State || "", // Mandatory: State
      RegType: data?.regType || formData?.RegType || "", // Mandatory: Registration Type
      Abbr: data?.abbr || formData?.Abbr || "", // Optional: Abbreviation
      WhatsappNo: data?.whatsapp || formData?.WhatsappNo || "", // Optional: Whatsapp No
      Commission: data?.commission || formData?.Commission || "", // Mandatory: Commission rate
      Inactive: data?.inactive || formData?.Inactive || "", // Optional: Inactive status (can be added if needed)
      AgentStr: data?.agentStr || formData?.AgentStr || "", // Optional: Agent String (can be added if needed)
      RefCnt: !isEditing ? "1" : "2", // Optional: Reference Count, "1" for new, "2" for updates
    };

    try {
      if (onSubmit) {
        const response = await axios.post(
          `${BASE_URL}/dashboard/agent-master-form-data`,
          defaultValues,
          config
        );
        alert(response.data.message);
        setActiveTab("Lookup");
      }
      reset();
    } catch (error) {
      console.error("Error while submitting the form:", error);
      alert("An error occurred: ", error);
    }
    // Reset the form after submission
    // reset();
  };

  return (
    <div className='bg-gray-100 p-2 rounded-xl shadow-lg'>
      <h2 className='text-lg text-center font-semibold mb-4 uppercase'>
        {targetPage?.FormName}
      </h2>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4'
      >
        {agentMasterFormFields.map((field) => renderField(field))}
        <div className='col-span-full'>
          <button
            type='submit'
            className='px-4 py-2 bg-blue-600 text-white rounded w-full'
          >
            {isEditing ? "Update" : "Submit"}{" "}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AgentForm;
