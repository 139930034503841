import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DynamicTable from "../../Common/NewDataTable";
import Loader from "../../Common/Loader";
import axios from "axios";
import { BASE_URL } from "../../Common/apiURL";
import { useLocation } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  branch,
  dateFormatter,
  formatDate,
  getCurrentPageData,
  user,
} from "../../../utils/capitalize";
import { MdGetApp } from "react-icons/md";
import Table from "../../Common/SearchFilterTable";

const PurchaseRegister = () => {
  // State variables
  const [dynamicFilterValue, setDynamicFilterValue] = useState(""); // Default value for dynamic filter
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [salesData, setSalesData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filterTypes, setFilterTypes] = useState([]);
  const [dynamicValues, setDynamicValues] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(""); // Track selected category
  const [selectedFilterType, setSelectedFilterType] = useState("0"); // Track selected filter type, initial value "0" for All
  const [showLoader, setShowLoader] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState({
    "S No": true,
    "Doc Date": true,
    "Doc No": true,
    "Party Name": true,
    Username: true,
    "Taxable Value": true,
    "Grand Total": true,
  });
  let visibleColumns = [];
  useEffect(() => {
    visibleColumns = Object.keys(selectedColumns).filter(
      (key) => selectedColumns[key]
    );
    console.log("Visible Columns: ", visibleColumns);
  }, [selectedColumns]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSelectedColumns((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handlePopupOpen = (event) => {
    setShowPopup(true); // Show the popup
    setSelectedColumns({
      "S No": true,
      "Doc Date": true,
      "Doc No": true,
      "Party Name": true,
      Username: true,
      "Taxable Value": true,
      "Grand Total": true,
    });
  };

  const handleClosePopup = () => {
    setShowPopup(false); // Close the popup
  };

  const handleConfirmDownload = () => {
    handleDownloadAsPDF(selectedColumns);
    setShowPopup(false); // Close the popup
  };
  const handleDownloadAsPDF = async (selectedColumns) => {
    // const doc = new jsPDF();
    const doc = new jsPDF({
      orientation: "portrait", // 'portrait' or 'landscape'
      unit: "mm",
      format: "a4",
    });

    const logoUrl =
      "https://img.freepik.com/free-photo/colorful-design-with-spiral-design_188544-9588.jpg"; // Your logo URL

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();
    const formattedTime = currentDate.toLocaleTimeString();

    // Convert Image URL to Base64
    const convertToBase64 = async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    };

    try {
      // Convert logo to Base64
      const logoBase64 = await convertToBase64(logoUrl);
      const logoX = 15;
      const logoY = 10;
      const logoWidth = 25;
      const logoHeight = 25;
      doc.addImage(logoBase64, "JPEG", logoX, logoY, logoWidth, logoHeight);
    } catch (error) {
      console.error("Error loading logo:", error);
      doc.text("Logo Error", 10, 30);
    }
    const center = doc.internal.pageSize.width / 2;
    const rightPosition = doc.internal.pageSize.width - 15; // Adjust as needed

    // Add Company Details and Report Name
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);
    doc.text("SRS Softwares", center, 15, { align: "center" });
    doc.setFont("helvetica", "semibold");
    doc.setFontSize(10);
    doc.text("Address Line 1", center, 20, { align: "center" });
    doc.text("Address Line 2", center, 25, { align: "center" });
    doc.text("GSTIN No: XXXXXXXXXXX", center, 29, { align: "center" });
    doc.setFontSize(12);
    doc.text("Purchase Register", center, 34, { align: "center" });

    doc.setFont("helvetica", "bold");
    doc.setFontSize(11);
    dynamicFilterValue
      ? doc.text(dynamicFilterValue, center, 38, { align: "center" })
      : doc.text("All", center, 38, { align: "center" });
    doc.setFontSize(10);
    doc.text(
      `${dateFormatter(startDate)} to ${dateFormatter(endDate)}`,
      center,
      42,
      {
        align: "center",
      }
    );
    // Add Date on the Top Right
    doc.setFontSize(10);
    doc.text(`Date: ${dateFormatter(endDate)}`, rightPosition, 20, {
      align: "right",
    });

    // Define table columns based on selectedColumns
    const tableColumns = Object.keys(selectedColumns).filter(
      (column) => selectedColumns[column]
    );

    // Filter rows based on selected columns
    const tableRows = salesData.map((row, index) => {
      return tableColumns.map((column) => {
        if (column === "S No") return index + 1;
        if (column === "Doc Date") return dateFormatter(row.DocDate);
        if (column === "Doc No") return row.DocNo;
        if (column === "Party Name") return row.LName;
        if (column === "Username") return row.Username;
        if (column === "Taxable Value") return row.TaxableValue?.toFixed(2);
        if (column === "Grand Total") return row.GrandTotal?.toFixed(2);
        return "";
      });
    });

    // Add table to PDF
    doc.autoTable({
      head: [tableColumns],
      body: tableRows,
      startY: 45,
      margin: { left: 10, right: 10 },
      theme: "plain",
      styles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.5,
      },
      headStyles: {
        fillColor: [211, 211, 211],
        textColor: [0, 0, 0],
        fontStyle: "bold",
        lineColor: [0, 0, 0],
        lineWidth: 0.5,
        halign: "center",
      },
      bodyStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
      },
      columnStyles: {
        0: { cellWidth: 15, halign: "center" },
        1: { halign: "center" },
        2: { halign: "center" },
        5: { halign: "right" },
        6: { halign: "right" },
        8: { halign: "center" },
      },
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.5,
    });

    // Add Footer with Time and Date of Printing
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;
    doc.text(
      `Printed On: ${formattedDate}, ${formattedTime}`,
      pageWidth - 10,
      pageHeight - 10,
      { align: "right" }
    );

    // Save the PDF
    doc.save("Purchase-Register.pdf");
  };
  const current = useLocation();
  const currentPage = current.pathname.split("/")[1];
  const targetPage = getCurrentPageData(currentPage);

  const fetchFilterData = async () => {
    try {
      const Username = user[0]?.UserID;
      const Depot = user[0]?.DpCode;
      const FormKey = targetPage?.FormKey;
      const BranchCode = branch[0]?.BranchCode;
      const requestBody = { Username, Depot, FormKey, BranchCode };

      const config = {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      };

      const response = await axios.post(
        `${BASE_URL}/dashboard/sale-register-category`,
        requestBody,
        config
      );

      if (response.data.success) {
        setCategories(response.data.category);
        const filterWithAll = [{ Value: "All" }, ...response.data.filter];
        setFilterTypes(filterWithAll);

        // Set the selectedCategory to the first category by default
        if (response.data.category.length > 0) {
          setSelectedCategory(response.data.category[0].CtCode);
        }
      } else {
        console.log("Failed to fetch sale register data");
      }
    } catch (err) {
      console.error("Error fetching sale register data:", err);
    }
  };

  useEffect(() => {
    document.title = "CLOUD ERP || Sale Register";
    fetchFilterData();

    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (selectedFilterType !== "0" && selectedCategory) {
      // Trigger API call when selected category or filter type changes
      fetchSalesData();
    }
  }, [selectedFilterType, selectedCategory]);

  const fetchSalesData = async () => {
    try {
      const Username = user[0]?.UserID;
      const Depot = user[0]?.DpCode;
      const FormKey = targetPage?.FormKey;
      const BranchCode = branch[0]?.BranchCode;
      const SelectedCategory = selectedCategory;
      const SelectedFilter = selectedFilterType;
      const requestBody = {
        Username,
        Depot,
        FormKey,
        BranchCode,
        SelectedCategory,
        SelectedFilter,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      };

      const response = await axios.post(
        `${BASE_URL}/dashboard/sale-register-category-data`,
        requestBody,
        config
      );
      console.log(response.data.data);
      if (response.data.data) {
        let filterWithAll;
        switch (selectedFilterType) {
          case "1":
            filterWithAll = [{ AName: "All" }, ...response.data.data];
            break;
          case "2":
            filterWithAll = [{ PartyName: "All" }, ...response.data.data];
            break;
          case "3":
            filterWithAll = [{ City: "All" }, ...response.data.data];
            break;
          case "4":
            filterWithAll = [{ State: "All" }, ...response.data.data];
            break;
          case "5":
            filterWithAll = [{ GSTRName: "All" }, ...response.data.data];
            break;
          case "6":
            filterWithAll = [{ LName: "All" }, ...response.data.data];
            break;
          default:
            filterWithAll = [{ name: "All" }];
            break;
        }
        setDynamicValues(filterWithAll);
      } else {
        console.log("Failed to fetch sale register data");
      }
    } catch (err) {
      console.error("Error fetching sale register data:", err);
    }
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);

    setSalesData([]);
  };

  const handleFilterTypeChange = (event) => {
    const value = event.target.value;
    setSelectedFilterType(value);
    setDynamicFilterValue(""); // Reset dynamic filter value
    setSalesData([]);
  };

  const handleDynamicFilterValueChange = (event) => {
    setDynamicFilterValue(event.target.value);
    setSalesData([]);
  };

  const handleFindSalesData = async () => {
    console.log("Selected Category:", selectedCategory);
    console.log("Selected Filter Type:", selectedFilterType);
    console.log("Selected Filter Value:", dynamicFilterValue);
    console.log("Start Date:", startDate);
    console.log("End Date:", endDate);
    setShowLoader(true);

    try {
      const Username = user[0]?.UserID;
      const Depot = user[0]?.DpCode;
      const FormKey = targetPage?.FormKey;
      const BranchCode = branch[0]?.BranchCode;
      const SelectedCategory = selectedCategory;
      const SelectedFilter = selectedFilterType;
      const StartDate = formatDate(startDate);
      const EndDate = formatDate(endDate);
      const Value = dynamicFilterValue;
      const requestBody = {
        Username,
        Depot,
        FormKey,
        BranchCode,
        SelectedCategory,
        SelectedFilter,
        StartDate,
        EndDate,
        Value,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      };

      const response = await axios.post(
        `${BASE_URL}/dashboard/sale-register`,
        requestBody,
        config
      );
      if (response.data.data) {
        setSalesData(response.data.data);
      } else {
        alert("Failed to fetch sale register data");
      }
    } catch (err) {
      console.error("Error fetching sale register data:", err);
    } finally {
      setShowLoader(false);
    }
  };

  // Columns configuration for DynamicTable
  const columns = [
    {
      Header: "S No",
      accessor: "SNo",
      Cell: ({ row }) => (
        <div style={{ textAlign: "center" }}>
          {row.original.isTotalRow ? "" : row.index + 1}
        </div>
      ),
      isInitiallyVisible: true,
    },
    {
      Header: "Doc Date",
      accessor: "DocDate",
      Cell: ({ value }) => (
        <div style={{ textAlign: "center" }}>{dateFormatter(value)}</div>
      ),
      isInitiallyVisible: true,
    },
    {
      Header: "Doc No",
      accessor: "DocNo",
      width: 100,
      align: "center",
      headerAlign: "center",
      Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
    },
    {
      Header: "Party Name",
      accessor: "LName",
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
      isInitiallyVisible: true,
    },
    {
      Header: "Username",
      accessor: "UserName",
      width: 100,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
      isInitiallyVisible: true,
    },
    {
      Header: "Taxable Value",
      accessor: "TaxableValue",
      width: 250,
      Cell: ({ value }) => (
        <div style={{ textAlign: "right" }}>{parseFloat(value).toFixed(2)}</div>
      ),
      isInitiallyVisible: true,
    },

    {
      Header: "Grand Total",
      accessor: "GrandTotal",
      width: 100,
      Cell: ({ value }) => (
        <div style={{ textAlign: "right" }}>{parseFloat(value).toFixed(2)}</div>
      ),
      isInitiallyVisible: true,
    },
  ];
  return (
    <div className='p-3'>
      {showLoader && <Loader />}

      <h2 className='text-sm font-semibold mb-4'>{targetPage?.FormName}</h2>
      <form>
        <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-2 text-sm'>
          {/* Category Dropdown */}
          <div>
            <label className='block font-medium'>Category</label>
            <select
              value={selectedCategory}
              onChange={handleCategoryChange}
              className='w-full p-1 border rounded text-xs'
            >
              {categories.map((category, index) => (
                <option key={index} value={category.CtCode}>
                  {category.CtName}
                </option>
              ))}
            </select>
          </div>

          {/* Filter Type Dropdown */}
          <div>
            <label className='block font-medium'>Filter</label>
            <select
              value={selectedFilterType}
              onChange={handleFilterTypeChange}
              className='w-full p-1 border rounded text-xs'
            >
              {filterTypes.map((type, index) => (
                <option key={index} value={index}>
                  {type.Value}
                </option>
              ))}
            </select>
          </div>

          {/* Dynamic Filter Value Dropdown */}
          <div>
            <label className='block font-medium'>
              {selectedFilterType === "0"
                ? "Select All"
                : `${filterTypes[selectedFilterType]?.Value}`}
            </label>
            <select
              value={dynamicFilterValue}
              onChange={handleDynamicFilterValueChange}
              className='w-full p-1 border rounded text-xs'
              disabled={selectedFilterType === "0"} // Disable if "All" is selected
            >
              {dynamicValues.map((category, index) => {
                // Handle multiple conditions based on dynamicValues fields
                if (category.AName) {
                  return (
                    <option key={index} value={category.AName}>
                      {category.AName}
                    </option>
                  );
                } else if (category.PartyName) {
                  return (
                    <option key={index} value={category.PartyName}>
                      {category.PartyName}
                    </option>
                  );
                } else if (category.City) {
                  return (
                    <option key={index} value={category.City}>
                      {category.City}
                    </option>
                  );
                } else if (category.State) {
                  return (
                    <option key={index} value={category.State}>
                      {category.State}
                    </option>
                  );
                } else if (category.GSTRName) {
                  return (
                    <option key={index} value={category.GSTRName}>
                      {category.GSTRName}
                    </option>
                  );
                } else if (category.LName) {
                  return (
                    <option key={index} value={category.LName}>
                      {category.LName}
                    </option>
                  );
                } else {
                  return null; // No option if none of the conditions match
                }
              })}
            </select>
          </div>

          {/* Date Pickers */}
          <div>
            <label className='block font-medium'>Start Date</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat='dd/MM/yyyy'
              maxDate={new Date()}
              className='w-full p-1 border rounded text-xs'
            />
          </div>

          <div>
            <label className='block font-medium'>End Date</label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat='dd/MM/yyyy'
              minDate={startDate}
              maxDate={new Date()}
              className='w-full p-1 border rounded text-xs'
            />
          </div>

          {/* Submit Button */}
          <div className='w-full flex justify-end'>
            <button
              type='button'
              onClick={handleFindSalesData}
              className='px-4 py-1 bg-green-600 text-white rounded'
            >
              Find
            </button>
          </div>
        </div>
      </form>

      <div className='mt-6'>
        <Table
          columns={columns}
          data={salesData.length > 0 ? salesData : []} // Use filtered data
        />
      </div>
      {salesData.length > 0 && (
        <div className='mt-4'>
          <button
            onClick={handlePopupOpen}
            className='px-4 py-1 text-sm bg-green-600 text-white rounded w-full'
          >
            Download PDF
          </button>
        </div>
      )}
      {showPopup && (
        <div className='fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50'>
          <div className='bg-white p-3 rounded shadow-lg w-80'>
            <h2 className='text-xl font-semibold mb-2 text-center'>
              Download PDF
            </h2>
            <div className='flex flex-col mb-4'>
              <label className='block text-sm mb-3 font-semibold'>
                Select Columns
              </label>
              <div className='flex flex-col'>
                {columns.map((column) => (
                  <label
                    key={column.Header}
                    className='flex items-center text-sm'
                  >
                    <input
                      type='checkbox'
                      name={column.Header}
                      checked={selectedColumns[column.Header]}
                      onChange={handleCheckboxChange}
                      className='mr-2'
                    />
                    {column.Header}
                  </label>
                ))}
              </div>
            </div>
            <div className='flex justify-end mt-4 space-x-4'>
              <button
                onClick={handleClosePopup}
                className='px-4 py-2 bg-red-500 text-white rounded'
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDownload}
                className='px-4 py-2 bg-blue-500 text-white rounded'
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PurchaseRegister;
